import throttledQueue from 'throttled-queue'

import { DEFAULT_LOCALE, locales, localesWithFallbackForCoalesceQuery, SupportedLocale } from '../domain/constants'
import { sanityAdminClient, sanityBuildClient, sanityCdnClient } from './clients/sanityClient'

const sanityThrottleQueue = throttledQueue(25, 1000)

const getSanityClient = (admin = false, useCdn = true) => {
  if (admin) {
    return sanityAdminClient
  }

  return useCdn ? sanityCdnClient : sanityBuildClient
}

interface SanityFetchParams {
  query: string
  params?: Record<string, unknown>
  draftMode?: boolean
}

export const sanityFetch = async ({ query, params, draftMode = false }: SanityFetchParams) => {
  const useCdn = !(process?.env?.NEXT_PHASE === 'phase-production-build' && process?.env?.NEXT_PUBLIC_VERCEL_ENV !== 'preview')

  const client = getSanityClient(draftMode, useCdn)

  // Prevent hitting Sanity rate limiter; global max 500 req/s;
  // @see https://www.sanity.io/docs/technical-limits
  if (!useCdn) {
    return await sanityThrottleQueue(() => client.fetch(query, params))
  }

  return client.fetch(query, params)
}

export const coalesceQuery = (name: string, currentLocale: SupportedLocale = DEFAULT_LOCALE, rename?: string, additionalQuery = '') => {
  const locales = localesWithFallbackForCoalesceQuery?.[currentLocale] || [currentLocale]

  return `'${rename || name}': coalesce(${locales.map((locale) => `${name}${name === '' ? locale : `['${locale}']`}${additionalQuery}`).join(', ')})`
}

export const coalesceFilter = (name: string, currentLocale: SupportedLocale = DEFAULT_LOCALE) => {
  const locales = localesWithFallbackForCoalesceQuery?.[currentLocale] || [currentLocale]

  return `coalesce(${locales.map((locale) => `${name}${name === '' ? locale : `['${locale}']`}`).join(', ')})`
}

export const forceFallbackForAll = (name: string) => {
  return `${name} {
    ${Object.entries(localesWithFallbackForCoalesceQuery)
      .map(([locale, fallbacks]) => {
        return `"${locale}": coalesce(${fallbacks.map((locale) => locale).join(', ')})`
      })
      .join(', ')}
  }`
}

export const requireAllToBeTrue = (name: string, fields: string[]) => `'${name}': {
    ${locales.map((locale) => `'${locale}': !coalesce(${fields.map((field) => `!${field}['${locale}']`).join(', ')})`)}
  }`

export const coalesceLocalizedValue = (name: string, currentLocale: SupportedLocale = DEFAULT_LOCALE, rename?: string, additionalQuery = '') => {
  const locales = localesWithFallbackForCoalesceQuery?.[currentLocale] || [currentLocale]

  return `'${rename || name}': coalesce(${locales.map((locale) => `${`${name}[name == '${locale}'][0].value`}${additionalQuery}`).join(', ')})`
}

export const coalesceLocalizedObject = (name: string, currentLocale = DEFAULT_LOCALE, rename?: string, additionalQuery = '') => {
  const locales = localesWithFallbackForCoalesceQuery?.[currentLocale] || [currentLocale]
  return `'${rename || name}': coalesce(${locales.map((locale) => `${`${name}[name == '${locale}'][0]`}${additionalQuery}`).join(', ')})`
}

/**
 * Convert Sanity rich text ('portable text') to plain text.
 *
 * @see https://www.sanity.io/docs/presenting-block-text#ac67a867dd69
 */
export const richTextToPlainText = (blocks = []) => {
  return blocks
    .map((block: any) => {
      if (block._type !== 'block' || !block.children) {
        return ''
      }

      return block.children.map((child: any) => child.text).join('')
    })
    .join('\n\n')
}
