import { FunctionComponent } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'

const MisterHead: FunctionComponent = () => {
  const { asPath, locale } = useRouter()
  const cleanPath = asPath === '/' ? '' : asPath?.split('#')?.[0]?.split('?')?.[0] || ''
  const canonicalUrl = `https://www.mrmarvis.com/${locale}` + cleanPath

  return (
    <Head>
      <title>MR MARVIS</title>
      <meta name='theme-color' content='#071933' />
      <meta name='format-detection' content='telephone=no' />
      <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover' />
      <meta name='msapplication-TileColor' content='#FFFFFF' />
      <meta name='msapplication-TileImage' content='/logo.png' />
      <meta name='copyright' content='MR MARVIS Global Holding B.V.' />
      <meta name='prerender-status-code' content='200' />
      <meta name='prerender' content='true' />
      <link rel='manifest' href='/manifest.json' />
      <link rel='icon' key='favicon-svg' href='/logo.svg' />
      <link rel='icon' key='favicon-32' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
      <link rel='icon' key='favicon-16' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
      <link rel='apple-touch-icon' key='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
      <link rel='mask-icon' key='mask-icon' href='/safari-pinned-tab.svg' color='#071933' />
      <link rel='preconnect' key='preconnect-shopify.com' href='https://shopify.com' />
      <link rel='preconnect' key='preconnect-cdn.shopify.com' href='https://cdn.shopify.com' />
      <link rel='preconnect' key='preconnect-cdn.mrmarvis.com' href='https://cdn.mrmarvis.com' />
      <link rel='preconnect' key='preconnect-yb9xf4jc.apicdn.mrmarvis.com' href='https://yb9xf4jc.apicdn.mrmarvis.com' />
      <link rel='preconnect' key='preconnect-shopify-gtm-suite.getelevar.com' href='https://shopify-gtm-suite.getelevar.com' />
      <link rel='dns-prefetch' key='dns-prefetch-googletagmanager.com' href='https://www.googletagmanager.com' />
      <link rel='dns-prefetch' key='dns-prefetch-api.eu.posthog.com' href={process.env.NEXT_PUBLIC_POSTHOG_HOST} />
      <link rel='canonical' href={canonicalUrl} />
    </Head>
  )
}

export default MisterHead
