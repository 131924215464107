import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { HOTSPOT_IMAGE_FRAGMENT, HotspotImageSchema } from '../components/hotspotImage.fragment'

export const SectionTaggedImageSchema = z.object({
  _id: z.string(),
  ...HotspotImageSchema.shape,
  _type: z.enum(['shopTheLookImage', 'taggedImage']),
})

export type SectionTaggedImageData = z.infer<typeof SectionTaggedImageSchema>

export const SECTION_TAGGED_IMAGE_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == "shopTheLookImage" || _type == "taggedImage" => {
    _id,
    _type,
    ${HOTSPOT_IMAGE_FRAGMENT(locale)}
  }
`)
