import { FunctionComponent } from 'react'
import { GetStaticPropsContext, InferGetStaticPropsType } from 'next'
import { NextSeo } from 'next-seo'

import { SupportedLocale } from 'data-access/domain/constants'

import MisterDiscountDialog from 'src/components/shared/MisterDiscountDialog'
import { getHomepageProps } from 'src/domain/homepageProps.domain'
import { seoData } from 'src/utils/seo.util'
import MisterLayout from '../components/layout/MisterLayout'
import MisterSectionLoop from '../components/shared/sections/MisterSectionLoop'
import { GlobalsContext } from '../contexts/Globals.context'

const IndexPage: FunctionComponent<InferGetStaticPropsType<typeof getStaticProps>> = ({ sections, layoutProps, globals, discountMessages, reviews, seo }) => (
  <GlobalsContext.Provider value={globals}>
    <NextSeo {...seoData(seo)} />
    <MisterLayout {...layoutProps}>
      <MisterDiscountDialog {...discountMessages} />
      <MisterSectionLoop initialReviews={reviews} averageRating={layoutProps.averageRating} sections={sections} as='article' />
    </MisterLayout>
  </GlobalsContext.Provider>
)

export default IndexPage

export const getStaticProps = async ({ locale, draftMode = false }: GetStaticPropsContext) => ({ props: await getHomepageProps({ locale: locale as SupportedLocale, draftMode }) })
