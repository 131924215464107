import { FunctionComponent } from 'react'
import { useRouter } from 'next/router'

import { Link } from 'data-access/sanity/fragments/components/link.fragment'
import { Media } from 'data-access/sanity/fragments/components/media.fragment'

import MisterMedia from 'src/components/shared/media/MisterMedia'
import MisterIcon from 'src/components/shared/MisterIcon'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'

interface Props {
  findStoreHeader: string
  findStoreText: string
  findStoreLink: Link
  findStoreImage: Media
}

const MisterFooterFindStore: FunctionComponent<Props> = ({ findStoreHeader, findStoreText, findStoreLink, findStoreImage }) => {
  const { asPath } = useRouter()

  if (asPath.includes('/stores')) {
    return null
  }

  return (
    <MisterSiteLink className='grid grid-cols-2 items-center bg-brand-blue-pale text-brand-blue md:grid-cols-6' link={findStoreLink}>
      <div className='col-span-2 flex flex-col gap-6 py-10 pl-4 pr-20 xl:px-12'>
        <div className='flex flex-col gap-2'>
          <h3 className='text-heading-5'>{findStoreHeader}</h3>
          <p className='text-body-md'>{findStoreText}</p>
        </div>
        <div className='group flex items-center gap-1 text-body-md-bold'>
          {findStoreLink.linkText}
          <MisterIcon className='w-4 transition group-hover:translate-x-1' type='submit-arrow' />
        </div>
      </div>
      <MisterMedia
        className='col-span-2 max-h-[15rem] object-cover md:col-span-4 md:max-h-[58rem]'
        media={findStoreImage}
        priority={false}
        mobileSizes='100vw'
        desktopSizes='67vw'
      />
    </MisterSiteLink>
  )
}

export default MisterFooterFindStore
