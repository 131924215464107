import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { CollectionSeasonSchema, ProductTypeSchema, SlugSchema } from '../../types'
import { coalesceFilter, coalesceLocalizedObject, coalesceQuery } from '../../utils'
import { COLLECTION_BREADCRUMB_FRAGMENT, CollectionBreadcrumbSchema } from '../components/breadcrumb.fragment'
import { PRODUCT_CARD_PRODUCT_FRAGMENT, ProductCardProductSchema } from '../components/productCardProduct.fragment'
import { SECTION_FILTER_CONFIGURATION_FRAGMENT, SectionFilterConfigurationSchema } from './sectionFilterConfiguration.fragment'
import { SECTION_GRID_CALLOUT_FRAGMENT, SectionGridCalloutsSchema } from './sectionGridCallouts.fragment'

export const SectionSearchFiltersGridSchema = z.object({
  _id: z.string(),
  _type: z.literal('sectionSearchFiltersGrid'),
  title: z.string(),
  slug: SlugSchema,
  collectionSeason: CollectionSeasonSchema.optional(),
  productType: ProductTypeSchema,
  longDescription: z.string(),
  gridCallouts: z.array(SectionGridCalloutsSchema),
  filterConfiguration: SectionFilterConfigurationSchema,
  additionalProducts: z.array(ProductCardProductSchema).optional(),
  isPrimaryCollectionPage: z.boolean(),
  breadcrumb: CollectionBreadcrumbSchema,
})

export type SectionSearchFiltersGridData = z.infer<typeof SectionSearchFiltersGridSchema>

const coalesceGridCallouts = (locale = DEFAULT_LOCALE, slug?: string) =>
  defineQuery(`
  coalesce(
    *[_type  == 'pageCollection' && length(connectedCollection[${coalesceFilter('@->slug', locale)}.current == '${slug}']) > 0][0] {
      ${coalesceLocalizedObject('gridCalloutsList', locale, 'gridCallouts', `.value[]-> ${SECTION_GRID_CALLOUT_FRAGMENT(locale)}`)}
    },
    *[_type == 'defaultCollectionTemplate'][0] {
      ${coalesceLocalizedObject('gridCalloutsList', locale, 'gridCallouts', `.value[]-> ${SECTION_GRID_CALLOUT_FRAGMENT(locale)}`)}
    }
  ).gridCallouts
`)

// This fragment collects the data needed to display the full search filters grid including the dynamic grid callouts and the filter configuration
export const SECTION_SEARCH_FILTERS_GRID_FRAGMENT = (locale = DEFAULT_LOCALE, slug?: string) =>
  defineQuery(`
  _type == 'sectionSearchFiltersGrid' => {
    _id,
    _type,
    ...coalesce(
      connectedCollection[${coalesceFilter('@->slug', locale)}.current == '${slug}'][0]->,
      *[_type == 'collection' && ${coalesceFilter('slug', locale)}.current == '${slug}'][0]
    ) {
      ${coalesceQuery('title', locale)},
      ${coalesceQuery('slug', locale)},
      defined(collectionSeason) => {
        collectionSeason
      },
      productType,
      "breadcrumb": {
        ${COLLECTION_BREADCRUMB_FRAGMENT(locale)}
      },
      'isPrimaryCollectionPage': isPrimary,
      "longDescription": coalesce(
        ${coalesceFilter('longDescription', locale)},
        ${coalesceFilter('collectionDescription', locale)}
      ),
    },
    "gridCallouts": ${coalesceGridCallouts(locale, slug)},
    filterConfiguration-> {
      ${SECTION_FILTER_CONFIGURATION_FRAGMENT(locale)}
    },
    defined(additionalProducts) => {
      additionalProducts[]-> {
        ${PRODUCT_CARD_PRODUCT_FRAGMENT(locale)}
      },
    }
  }
`)
