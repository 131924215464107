import { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react'
import { useRouter } from 'next/router'
import { createGlobalState } from 'react-use'

import { useBreakpoint } from 'src/hooks/utils/useBreakpoint'

interface HeaderMargins {
  showAnnouncementBar: boolean
  scrollTopMargin: number
  topPosition: number
}

export const useGlobalTopMargin = createGlobalState<HeaderMargins>({
  showAnnouncementBar: true,
  scrollTopMargin: 0,
  topPosition: 0,
})

interface ElementHeights {
  announcementBar: number
  header: number
  stickyElements: number
}

const MisterScrollMargin: FunctionComponent = () => {
  const breakpoint = useBreakpoint()
  const router = useRouter()
  const [_, setTopMargins] = useGlobalTopMargin()

  const lastScrollYRef = useRef(0)
  const scrollOffsetRef = useRef(0)

  const isMobile = useMemo(() => ['xs', 'sm', 'md'].includes(breakpoint), [breakpoint])

  const getElementHeights = useCallback((): ElementHeights => {
    const announcementBar = document.querySelector('[data-announcement-bar]')
    const header = document.querySelector('[data-header]')
    const stickyElements = Array.from(document.querySelectorAll('[data-is-sticky-top]'))

    return {
      announcementBar: announcementBar?.clientHeight || 0,
      header: header?.clientHeight || 0,
      stickyElements: stickyElements.reduce((sum, el) => sum + (el?.clientHeight || 0), 0),
    }
  }, [])

  const handleScroll = useCallback(() => {
    const { announcementBar, header, stickyElements } = getElementHeights()
    const currentScrollY = window.scrollY
    const scrolledUp = currentScrollY < lastScrollYRef.current

    if (scrolledUp && scrollOffsetRef.current - currentScrollY > 150) {
      setTopMargins({
        scrollTopMargin: header + stickyElements,
        topPosition: header + announcementBar,
        showAnnouncementBar: true,
      })
      scrollOffsetRef.current = 0
    } else if (!scrolledUp) {
      setTopMargins({
        scrollTopMargin: header + stickyElements,
        topPosition: header,
        showAnnouncementBar: false,
      })
      scrollOffsetRef.current = currentScrollY
    }

    lastScrollYRef.current = currentScrollY
  }, [getElementHeights, setTopMargins])

  useEffect(() => {
    if (!isMobile) {
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [isMobile, handleScroll])

  useEffect(() => {
    const { announcementBar, header, stickyElements } = getElementHeights()
    setTopMargins({
      showAnnouncementBar: !isMobile,
      scrollTopMargin: header + stickyElements,
      topPosition: header + (isMobile ? 0 : announcementBar),
    })
  }, [isMobile, getElementHeights, setTopMargins, router.asPath])

  return null
}

export default MisterScrollMargin
