import { PostHog } from 'posthog-js'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'

import { capturedPostHogFeatureViews } from '../../utils/telemetry.util'

export const useExperimentFeatureFlag = (flag: string, overrideKey?: string, trackFeatureView = true) => {
  const posthog = usePostHog()

  // If you need to override a feature flag for a specific visitor, e.g. to test a variant on localhost
  if (overrideKey) {
    posthog.featureFlags.override({ [flag]: overrideKey })
  }

  if (trackFeatureView && !capturedPostHogFeatureViews.includes(flag)) {
    captureFeatureView(flag, posthog)
    capturedPostHogFeatureViews.push(flag)
  }

  return useFeatureFlagVariantKey(flag)
}

/**
 * Dispatch event to PostHog a visitor 'viewed' a feature flag.
 *
 * @see https://posthog.com/docs/libraries/js#enriched-analytics
 */
export const captureFeatureView = (flag: string, posthog: PostHog) => {
  if (process.env.NEXT_PUBLIC_POSTHOG_DEBUG === 'true') {
    console.debug(`PostHog track feature view.`, { flag })
  }

  posthog.capture('$feature_view', { feature_flag: flag })
}

/**
 * Dispatch event to PostHog a visitor 'interacted' with an experiment;
 * send an event to PostHog, and $set takes care of setting this interaction
 * on user level. Call this function only where there is a 'real' interaction
 * with an experiment variant, i.e. click/open/submit.
 *
 * @see https://posthog.com/docs/libraries/js#enriched-analytics
 */
export const captureFeatureInteraction = (flag: string, posthog: PostHog) => {
  if (process.env.NEXT_PUBLIC_POSTHOG_DEBUG === 'true') {
    console.debug(`PostHog track feature interaction.`, { flag })
  }

  posthog.capture('$feature_interaction', { feature_flag: flag, $set: { [`$feature_interaction/${flag}`]: true } })
}
