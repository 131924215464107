import { getCollectionSizingInformation } from 'data-access'
import { DEFAULT_LOCALE } from 'data-access/domain/constants'

export type GetSizeGuideData = Awaited<ReturnType<typeof getSizeGuide>>

export type SizeChart = GetSizeGuideData['sizeCharts'][0]

export type Measurement = GetSizeGuideData['measurements'][0]

export type ConversionTable = GetSizeGuideData['conversionTables'][0]

export const getSizeGuide = async (collectionId: string, locale = DEFAULT_LOCALE) => {
  const data = await getCollectionSizingInformation(collectionId, locale)

  const { sizeDefinitions, sizes, ...rest } = data

  // const filteredSizeConversions = sizeDefinitions
  //   .flatMap(({ conversionTables }) => {
  //     if (!conversionTables) return
  //     return conversionTables.map(({ headings, sizeConversions }) => {
  //       const indexOfOurSize = headings.findIndex(({ isOurSize }) => isOurSize)
  //       const relevantSizeConversions = sizeConversions.filter((row) => sizes.find((size) => size.title === row[indexOfOurSize]))
  //       return {
  //         headings: headings.map(({ heading }) => heading),
  //         sizeConversions: relevantSizeConversions,
  //       }
  //     })
  //   })
  //   .filter(Boolean)

  return {
    ...rest,
    measurements: sizeDefinitions.flatMap(({ measurements }) => measurements),
    // conversionTables: filteredSizeConversions,
    conversionTables: [],
    sizeCharts: sizeDefinitions.map(({ _id, measurements }) => ({
      measurements,
      data: sizes.filter((size) => size.sizeDefinition === _id),
    })),
  }
}
