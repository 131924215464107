import { FunctionComponent } from 'react'
import Image from 'next/image'

import { Button } from '@ui/components/ui/button'
import { cn } from '@ui/lib/utils'

import { useTranslations } from 'src/contexts/Globals.context'
import { useUI } from 'src/contexts/UI.context'
import { useBreakpoint } from 'src/hooks/utils/useBreakpoint'
import { imageQuality } from 'src/utils/image.util'

export const SearchButton: FunctionComponent = () => {
  const breakpoint = useBreakpoint()
  const { displaySearchUI, closeMobileMenu, displayMobileMenu, showSearchUI, hideSearchUI } = useUI()

  const translate = useTranslations()

  const handleSearchButtonClick = () => {
    if (displayMobileMenu) {
      return closeMobileMenu()
    } else if (displaySearchUI) {
      return hideSearchUI()
    } else {
      return showSearchUI()
    }
  }

  const showIcon = displayMobileMenu || displaySearchUI
  const isDesktop = ['lg', 'xl', '2xl', '3xl'].includes(breakpoint)

  return (
    <Button
      asChild
      variant='ghost'
      size='icon'
      aria-label={translate('search', 'Search')}
      className={cn(
        'flex cursor-pointer items-center gap-1 transition-[transform,opacity] duration-500 ease-out lg:translate-x-0 lg:translate-y-0 lg:opacity-100',
        showIcon && '-translate-y-5 translate-x-10 opacity-0',
      )}
      onClick={handleSearchButtonClick}
    >
      <div className='relative'>
        <Image
          src={isDesktop && displaySearchUI ? '/img/close.svg' : '/img/search.svg'}
          alt={isDesktop && displaySearchUI ? translate('close', 'Close') : translate('search', 'Search')}
          width={24}
          height={24}
          quality={imageQuality}
        />
      </div>
    </Button>
  )
}
