import { FunctionComponent } from 'react'

import { MenuCard as MenuCardType } from 'data-access/sanity/fragments/components/menuCard.fragment'
import { NavigationMenuLink } from '@ui/components/ui/navigation-menu'
import { cn } from '@ui/lib/utils'

import { makeHref } from 'src/components/shared/site-link/MisterSiteLink'
import { NAVIGATION_LINK_VARIANTS, TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import { ActiveVariant } from '../Header'
import MenuCard from '../MenuCard'

interface MegaMenuLevelOneProps {
  mainMenuCards: MenuCardType[]
  activeVariant: ActiveVariant
}
const MegaMenuLevelOne: FunctionComponent<MegaMenuLevelOneProps> = ({ mainMenuCards, activeVariant }) => {
  const isVariant1Or2 = activeVariant && typeof activeVariant === 'string' && ['variant-1', 'variant-2'].includes(activeVariant)

  const cardsToShow = isVariant1Or2 ? mainMenuCards?.slice(0, 1) : mainMenuCards

  return (
    <div className={cn('grid grid-cols-2 gap-3', isVariant1Or2 ? 'grid-cols-1' : '')}>
      {cardsToShow?.map(({ image, pageLink }, i) => (
        <NavigationMenuLink key={i} asChild>
          <MenuCard
            onClick={() =>
              trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, {
                clickDestination: makeHref(pageLink),
                navigationLevel: isVariant1Or2 ? 2 : 4,
                variant: NAVIGATION_LINK_VARIANTS.CARD,
              })
            }
            image={image}
            link={pageLink}
            text={pageLink.linkText}
            className='aspect-[35:48] max-h-[27rem]'
          />
        </NavigationMenuLink>
      ))}
    </div>
  )
}

export default MegaMenuLevelOne
