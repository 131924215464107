import { createContext, ReactNode, useContext, useMemo } from 'react'

import { Globals } from '@/types/globals'

export const GlobalsContext = createContext<Globals>({
  global: {},
  collection: {},
  product: {},
  colorOrder: [],
  localizedSettings: { showCurrencySymbol: true },
  discountCampaigns: [],
  searchSettingsTerms: {},
  searchSettings: {
    discoverCollectionsItems: [],
    searchHelpLinks: [],
    popularSearchTerms: [],
  },
  stylesOrder: {},
})

export type Translate = (name: string, fallback?: string | boolean) => any

export const useTranslations = (): Translate => {
  const globalTerms = useContext(GlobalsContext)

  if (globalTerms === undefined) {
    throw new Error('useTranslations must be used within a GlobalsContextProvider.')
  }

  const allTerms = useMemo(
    () => ({ ...globalTerms?.global, ...globalTerms?.product, ...globalTerms?.collection, ...globalTerms?.searchSettings, ...globalTerms?.searchSettingsTerms }),
    [globalTerms],
  )

  return (name: string, fallback: string | boolean = false) => {
    // @ts-ignore
    const translation = allTerms[name]
    const fallbackTranslation = fallback && typeof fallback === 'string' ? fallback : undefined

    return translation || fallbackTranslation
  }
}

/**
 * Should be used, only if needed in page level components, as follows :
 *
 * @example
 *
 * <TranslationProvider>
 *   {(translate: Translate) => (
 *     <>
 *       ...children components
 *     </>
 *   )}
 *  </TranslationProvider>
 */
export const TranslationProvider = ({ children }: any): ReactNode => {
  const translate = useTranslations()

  return children(translate)
}
