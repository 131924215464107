import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceLocalizedValue } from '../../utils'
import { GET_IMAGE_FRAGMENT, ImageSchema } from '../common/getImage.fragment'
import { LINK_FRAGMENT, LinkSchema } from './link.fragment'

export const MenuCardSchema = z.object({
  image: ImageSchema,
  pageLink: LinkSchema,
})

export type MenuCard = z.infer<typeof MenuCardSchema>

export const MENU_CARD_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
    ${coalesceLocalizedValue(
      'localizedMenuCard',
      locale,
      'image',
      `.image {
      ${GET_IMAGE_FRAGMENT()}
    }`,
    )},
    ${coalesceLocalizedValue(
      'localizedMenuCard',
      locale,
      'pageLink',
      `.pageLink-> {
      ${LINK_FRAGMENT(locale)}
    }`,
    )}
`)
