import { FunctionComponent, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { useUI } from 'src/contexts/UI.context'
import useCart from 'src/hooks/shop/useCart'
import MisterDialog from './dialog/MisterDialog'
import MisterSpinner from './loader/MisterSpinner'
import MisterIcon from './MisterIcon'

interface MisterDiscountDialogProps {
  discountSuccessMessage: string
  discountFailureMessage: string
}

const MisterDiscountDialog: FunctionComponent<MisterDiscountDialogProps> = ({ discountFailureMessage, discountSuccessMessage }) => {
  const { query, push } = useRouter()
  const [couponState, setCouponState] = useState('')
  const { openCart } = useUI()
  const { isLoading, error, addDiscountCode } = useCart()

  useEffect(() => {
    if (query?.cart === 'show') {
      openCart()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    const addDiscountCodeToShopify = async (coupon: string) => await addDiscountCode([coupon])

    if (couponState === '' && query.coupon && !isLoading && !error) {
      if (typeof query.coupon !== 'string') {
        return setCouponState('failed')
      }

      setCouponState('loading')

      addDiscountCodeToShopify(query.coupon).then((res) => {
        if (!res) {
          return setCouponState('done')
        }

        setCouponState('failed')
      })
    }
  }, [query, isLoading, error, addDiscountCode, couponState])

  return (
    <>
      {couponState === 'loading' && (
        <div className='fixed inset-0 z-[100] bg-black/30'>
          <div className='absolute right-1/2 top-[50vh] translate-x-1/2'>
            <MisterSpinner show size='large' />
          </div>
        </div>
      )}
      <MisterDialog
        isOpen={couponState === 'done' || couponState === 'failed'}
        onClose={() => {
          setCouponState('complete')
          push('/', undefined, { shallow: true })
        }}
        size='small'
        // @ts-expect-error - Somehow types are not correct here
        closeVariant='cross'
      >
        <div className='relative flex flex-col items-center justify-center gap-4 pb-6'>
          {couponState === 'done' && <MisterIcon type='check-3' className='-mt-[0.1em] h-[1.538em] shrink-0 text-brand-positive' />}
          {couponState === 'failed' && <MisterIcon type='info' className='-mt-[0.1em] h-[1.538em] shrink-0 text-brand-orange' />}
          <h5 className='text-heading-6 font-semibold leading-5'>{couponState === 'done' ? discountSuccessMessage : discountFailureMessage}</h5>
        </div>
      </MisterDialog>
    </>
  )
}

export default MisterDiscountDialog
