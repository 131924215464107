import { FunctionComponent, useEffect, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useToggle } from 'react-use'

import useCustomer from 'src/hooks/customer/useCustomer'
import { IronSessionCustomerLoggedIn } from 'src/utils/auth.util'
import { kustomerBrandId } from 'src/utils/kustomer.util'
import { post } from 'src/utils/request.util'
import { useCookieConsentContext } from '../../hooks/context/useCookieConsentContext'
import { useReadyStateEffect } from '../../hooks/utils/useReadyStateEffect'
import { gtmId, gtmTag } from '../../utils/gtm.util'
import { TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from '../../utils/telemetry.util'

const MisterScripts: FunctionComponent = () => {
  const { customer } = useCustomer()
  const { locale, isReady, pathname, asPath, basePath } = useRouter()
  const [gtmInitialized, setGtmInitialized] = useToggle(false)
  const [kustomerLoaded, setKustomerLoaded] = useToggle(false)
  const [shopifyCustomerPrivacyApiLoaded, setShopifyCustomerPrivacyApiLoaded] = useToggle(false)
  const { isLoggedIn, email } = customer as IronSessionCustomerLoggedIn

  const { data, isLoading, error } = useQuery({
    queryKey: ['kustomer', isLoggedIn, kustomerLoaded, email],
    queryFn: () => post<{ token: string }>('/api/kustomer', { email: isLoggedIn ? email : null }),
    enabled: isLoggedIn && kustomerLoaded,
  })

  useEffect(() => {
    if (!data?.token || error || isLoading || !kustomerLoaded || !window?.Kustomer) {
      return
    }

    try {
      window?.Kustomer?.login({
        jwtToken: data?.token,
      })
    } catch (error) {
      console.error('Kustomer login failed.', error)
    }
  }, [data, error, isLoading, kustomerLoaded])

  useEffect(() => {
    if (kustomerLoaded && window?.Kustomer) {
      try {
        window?.Kustomer?.start({
          brandId: kustomerBrandId(locale),
        })
      } catch (error) {
        console.error('Kustomer start failed.', error)
      }
    }
  }, [kustomerLoaded, locale])

  const shopifyCustomerPrivacyApiBaseConfig = useMemo(() => {
    return {
      storefrontRootDomain: process.env.NODE_ENV === 'development' ? 'localhost' : 'mrmarvis.com',
      checkoutRootDomain: process.env[`NEXT_PUBLIC_CUSTOM_STORE_DOMAIN_${locale?.toUpperCase()}`] || 'checkout.mrmarvis.com',
      storefrontAccessToken: process.env[`NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN_${locale?.toUpperCase()}`] || '',
    }
  }, [locale])

  const { consent, hasConsentedToCookie, showCookieBanner } = useCookieConsentContext()

  // Consent changes
  useEffect(() => {
    if (!consent || !gtmInitialized) {
      return
    }

    const consentToMarketing = hasConsentedToCookie('marketing')
    const consentToStatistics = hasConsentedToCookie('statistics')
    const consentToPreferences = hasConsentedToCookie('preferences')

    // @see https://support.google.com/tagmanager/answer/13802165?sjid=5867101708257594672-EU
    const consentValues: { [key: string]: 'granted' | 'denied' } = {
      ad_storage: consentToMarketing ? 'granted' : 'denied',
      ad_user_data: consentToMarketing ? 'granted' : 'denied',
      ad_personalization: consentToMarketing ? 'granted' : 'denied',
      analytics_storage: consentToStatistics ? 'granted' : 'denied',
      functionality_storage: consentToPreferences ? 'granted' : 'denied',
      personalization_storage: consentToPreferences ? 'granted' : 'denied',
      security_storage: consentToPreferences ? 'granted' : 'denied',
    }

    // Inform GTM about consent
    // @see https://developers.google.com/tag-platform/devguides/consent
    if (gtmId) {
      window?.gtag('consent', 'update', consentValues)
      trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CONSENT_SET, { consent: consentValues })
    }

    // Inform Shopify about consent
    // @see https://shopify.dev/docs/api/customer-privacy#setting-consent
    if (shopifyCustomerPrivacyApiLoaded && window?.Shopify?.customerPrivacy) {
      if (process.env.NODE_ENV === 'development') {
        console.debug('Inform Shopify about consent.', shopifyCustomerPrivacyApiBaseConfig)
      }

      window?.Shopify?.customerPrivacy.setTrackingConsent(
        {
          ...shopifyCustomerPrivacyApiBaseConfig,
          headlessStorefront: true,
          marketing: consentToMarketing,
          analytics: consentToStatistics,
          preferences: consentToPreferences,
        },
        () => {
          if (process.env.NODE_ENV === 'development') {
            console.debug('Informed Shopify about consent.')
          }
        },
      )
    }
  }, [consent, hasConsentedToCookie, gtmInitialized, shopifyCustomerPrivacyApiLoaded, shopifyCustomerPrivacyApiBaseConfig])

  useEffect(() => {
    if (isReady) {
      trackEvent(TELEMETRY_DATA_LAYER_EVENTS.PAGE_VIEW, { page: { pathname, asPath, basePath, locale } })
    }
  }, [asPath, basePath, isReady, locale, pathname])

  // Ensure this event comes after the generic dl_user_data
  // by means of the useReadyStateEffect hook (fires after page
  // load is done + additional delay)
  useReadyStateEffect(() => {
    if (isReady) {
      setTimeout(() => {
        // This page view trigger can be used by GTM tags with less priority
        trackEvent(TELEMETRY_DATA_LAYER_EVENTS.PAGE_VIEW_DELAYED)
      }, 1500)
    }
  }, [isReady, asPath])

  const shopifyCustomerPrivacyApiReady = () => {
    setShopifyCustomerPrivacyApiLoaded(true)
  }

  return (
    <>
      <Script
        id='shopify-consent'
        strategy='lazyOnload'
        onReady={shopifyCustomerPrivacyApiReady}
        src='https://cdn.shopify.com/shopifycloud/consent-tracking-api/v0.1/consent-tracking-api.js'
      />
      {gtmId && !gtmInitialized && (
        <Script
          id='gtm'
          strategy='lazyOnload'
          onReady={() => {
            setGtmInitialized(true)
          }}
          dangerouslySetInnerHTML={{ __html: gtmTag('granted') }}
        />
      )}
      {!showCookieBanner && (
        <Script
          id='kustomer'
          strategy='lazyOnload'
          onReady={() => {
            setKustomerLoaded(true)
          }}
          src='https://cdn.kustomerapp.com/chat-web/widget.js'
          data-kustomer-api-key={process.env.NEXT_PUBLIC_KUSTOMER_CHAT_API_KEY}
        />
      )}
    </>
  )
}

export default MisterScripts
