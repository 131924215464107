const dynamicIconImports = {
  'a-arrow-down': () => import('./icons/a-arrow-down'),
  'a-arrow-up': () => import('./icons/a-arrow-up'),
  'a-large-small': () => import('./icons/a-large-small'),
  'accessibility': () => import('./icons/accessibility'),
  'activity': () => import('./icons/activity'),
  'air-vent': () => import('./icons/air-vent'),
  'airplay': () => import('./icons/airplay'),
  'alarm-clock-check': () => import('./icons/alarm-clock-check'),
  'alarm-check': () => import('./icons/alarm-clock-check'),
  'alarm-clock-minus': () => import('./icons/alarm-clock-minus'),
  'alarm-minus': () => import('./icons/alarm-clock-minus'),
  'alarm-clock-off': () => import('./icons/alarm-clock-off'),
  'alarm-clock-plus': () => import('./icons/alarm-clock-plus'),
  'alarm-plus': () => import('./icons/alarm-clock-plus'),
  'alarm-clock': () => import('./icons/alarm-clock'),
  'alarm-smoke': () => import('./icons/alarm-smoke'),
  'album': () => import('./icons/album'),
  'align-center-horizontal': () => import('./icons/align-center-horizontal'),
  'align-center-vertical': () => import('./icons/align-center-vertical'),
  'align-center': () => import('./icons/align-center'),
  'align-end-horizontal': () => import('./icons/align-end-horizontal'),
  'align-end-vertical': () => import('./icons/align-end-vertical'),
  'align-horizontal-distribute-center': () => import('./icons/align-horizontal-distribute-center'),
  'align-horizontal-distribute-end': () => import('./icons/align-horizontal-distribute-end'),
  'align-horizontal-distribute-start': () => import('./icons/align-horizontal-distribute-start'),
  'align-horizontal-justify-center': () => import('./icons/align-horizontal-justify-center'),
  'align-horizontal-justify-end': () => import('./icons/align-horizontal-justify-end'),
  'align-horizontal-justify-start': () => import('./icons/align-horizontal-justify-start'),
  'align-horizontal-space-around': () => import('./icons/align-horizontal-space-around'),
  'align-horizontal-space-between': () => import('./icons/align-horizontal-space-between'),
  'align-justify': () => import('./icons/align-justify'),
  'align-left': () => import('./icons/align-left'),
  'align-right': () => import('./icons/align-right'),
  'align-start-horizontal': () => import('./icons/align-start-horizontal'),
  'align-start-vertical': () => import('./icons/align-start-vertical'),
  'align-vertical-distribute-center': () => import('./icons/align-vertical-distribute-center'),
  'align-vertical-distribute-end': () => import('./icons/align-vertical-distribute-end'),
  'align-vertical-distribute-start': () => import('./icons/align-vertical-distribute-start'),
  'align-vertical-justify-center': () => import('./icons/align-vertical-justify-center'),
  'align-vertical-justify-end': () => import('./icons/align-vertical-justify-end'),
  'align-vertical-justify-start': () => import('./icons/align-vertical-justify-start'),
  'align-vertical-space-around': () => import('./icons/align-vertical-space-around'),
  'align-vertical-space-between': () => import('./icons/align-vertical-space-between'),
  'ambulance': () => import('./icons/ambulance'),
  'ampersand': () => import('./icons/ampersand'),
  'ampersands': () => import('./icons/ampersands'),
  'amphora': () => import('./icons/amphora'),
  'anchor': () => import('./icons/anchor'),
  'angry': () => import('./icons/angry'),
  'annoyed': () => import('./icons/annoyed'),
  'antenna': () => import('./icons/antenna'),
  'anvil': () => import('./icons/anvil'),
  'aperture': () => import('./icons/aperture'),
  'app-window-mac': () => import('./icons/app-window-mac'),
  'app-window': () => import('./icons/app-window'),
  'apple': () => import('./icons/apple'),
  'archive-restore': () => import('./icons/archive-restore'),
  'archive-x': () => import('./icons/archive-x'),
  'archive': () => import('./icons/archive'),
  'armchair': () => import('./icons/armchair'),
  'arrow-big-down-dash': () => import('./icons/arrow-big-down-dash'),
  'arrow-big-down': () => import('./icons/arrow-big-down'),
  'arrow-big-left-dash': () => import('./icons/arrow-big-left-dash'),
  'arrow-big-left': () => import('./icons/arrow-big-left'),
  'arrow-big-right-dash': () => import('./icons/arrow-big-right-dash'),
  'arrow-big-right': () => import('./icons/arrow-big-right'),
  'arrow-big-up-dash': () => import('./icons/arrow-big-up-dash'),
  'arrow-big-up': () => import('./icons/arrow-big-up'),
  'arrow-down-0-1': () => import('./icons/arrow-down-0-1'),
  'arrow-down-01': () => import('./icons/arrow-down-0-1'),
  'arrow-down-1-0': () => import('./icons/arrow-down-1-0'),
  'arrow-down-10': () => import('./icons/arrow-down-1-0'),
  'arrow-down-a-z': () => import('./icons/arrow-down-a-z'),
  'arrow-down-az': () => import('./icons/arrow-down-a-z'),
  'arrow-down-from-line': () => import('./icons/arrow-down-from-line'),
  'arrow-down-left': () => import('./icons/arrow-down-left'),
  'arrow-down-narrow-wide': () => import('./icons/arrow-down-narrow-wide'),
  'arrow-down-right': () => import('./icons/arrow-down-right'),
  'arrow-down-to-dot': () => import('./icons/arrow-down-to-dot'),
  'arrow-down-to-line': () => import('./icons/arrow-down-to-line'),
  'arrow-down-up': () => import('./icons/arrow-down-up'),
  'arrow-down-wide-narrow': () => import('./icons/arrow-down-wide-narrow'),
  'sort-desc': () => import('./icons/arrow-down-wide-narrow'),
  'arrow-down-z-a': () => import('./icons/arrow-down-z-a'),
  'arrow-down-za': () => import('./icons/arrow-down-z-a'),
  'arrow-down': () => import('./icons/arrow-down'),
  'arrow-left-from-line': () => import('./icons/arrow-left-from-line'),
  'arrow-left-right': () => import('./icons/arrow-left-right'),
  'arrow-left-to-line': () => import('./icons/arrow-left-to-line'),
  'arrow-left': () => import('./icons/arrow-left'),
  'arrow-right-from-line': () => import('./icons/arrow-right-from-line'),
  'arrow-right-left': () => import('./icons/arrow-right-left'),
  'arrow-right-to-line': () => import('./icons/arrow-right-to-line'),
  'arrow-right': () => import('./icons/arrow-right'),
  'arrow-up-0-1': () => import('./icons/arrow-up-0-1'),
  'arrow-up-01': () => import('./icons/arrow-up-0-1'),
  'arrow-up-1-0': () => import('./icons/arrow-up-1-0'),
  'arrow-up-10': () => import('./icons/arrow-up-1-0'),
  'arrow-up-a-z': () => import('./icons/arrow-up-a-z'),
  'arrow-up-az': () => import('./icons/arrow-up-a-z'),
  'arrow-up-down': () => import('./icons/arrow-up-down'),
  'arrow-up-from-dot': () => import('./icons/arrow-up-from-dot'),
  'arrow-up-from-line': () => import('./icons/arrow-up-from-line'),
  'arrow-up-left': () => import('./icons/arrow-up-left'),
  'arrow-up-narrow-wide': () => import('./icons/arrow-up-narrow-wide'),
  'sort-asc': () => import('./icons/arrow-up-narrow-wide'),
  'arrow-up-right': () => import('./icons/arrow-up-right'),
  'arrow-up-to-line': () => import('./icons/arrow-up-to-line'),
  'arrow-up-wide-narrow': () => import('./icons/arrow-up-wide-narrow'),
  'arrow-up-z-a': () => import('./icons/arrow-up-z-a'),
  'arrow-up-za': () => import('./icons/arrow-up-z-a'),
  'arrow-up': () => import('./icons/arrow-up'),
  'arrows-up-from-line': () => import('./icons/arrows-up-from-line'),
  'asterisk': () => import('./icons/asterisk'),
  'at-sign': () => import('./icons/at-sign'),
  'atom': () => import('./icons/atom'),
  'audio-lines': () => import('./icons/audio-lines'),
  'audio-waveform': () => import('./icons/audio-waveform'),
  'award': () => import('./icons/award'),
  'axe': () => import('./icons/axe'),
  'axis-3d': () => import('./icons/axis-3d'),
  'axis-3-d': () => import('./icons/axis-3d'),
  'baby': () => import('./icons/baby'),
  'backpack': () => import('./icons/backpack'),
  'badge-alert': () => import('./icons/badge-alert'),
  'badge-cent': () => import('./icons/badge-cent'),
  'badge-check': () => import('./icons/badge-check'),
  'verified': () => import('./icons/badge-check'),
  'badge-dollar-sign': () => import('./icons/badge-dollar-sign'),
  'badge-euro': () => import('./icons/badge-euro'),
  'badge-help': () => import('./icons/badge-help'),
  'badge-indian-rupee': () => import('./icons/badge-indian-rupee'),
  'badge-info': () => import('./icons/badge-info'),
  'badge-japanese-yen': () => import('./icons/badge-japanese-yen'),
  'badge-minus': () => import('./icons/badge-minus'),
  'badge-percent': () => import('./icons/badge-percent'),
  'badge-plus': () => import('./icons/badge-plus'),
  'badge-pound-sterling': () => import('./icons/badge-pound-sterling'),
  'badge-russian-ruble': () => import('./icons/badge-russian-ruble'),
  'badge-swiss-franc': () => import('./icons/badge-swiss-franc'),
  'badge-x': () => import('./icons/badge-x'),
  'badge': () => import('./icons/badge'),
  'baggage-claim': () => import('./icons/baggage-claim'),
  'ban': () => import('./icons/ban'),
  'banana': () => import('./icons/banana'),
  'bandage': () => import('./icons/bandage'),
  'banknote': () => import('./icons/banknote'),
  'barcode': () => import('./icons/barcode'),
  'baseline': () => import('./icons/baseline'),
  'bath': () => import('./icons/bath'),
  'battery-charging': () => import('./icons/battery-charging'),
  'battery-full': () => import('./icons/battery-full'),
  'battery-low': () => import('./icons/battery-low'),
  'battery-medium': () => import('./icons/battery-medium'),
  'battery-plus': () => import('./icons/battery-plus'),
  'battery-warning': () => import('./icons/battery-warning'),
  'battery': () => import('./icons/battery'),
  'beaker': () => import('./icons/beaker'),
  'bean-off': () => import('./icons/bean-off'),
  'bean': () => import('./icons/bean'),
  'bed-double': () => import('./icons/bed-double'),
  'bed-single': () => import('./icons/bed-single'),
  'bed': () => import('./icons/bed'),
  'beef': () => import('./icons/beef'),
  'beer-off': () => import('./icons/beer-off'),
  'beer': () => import('./icons/beer'),
  'bell-dot': () => import('./icons/bell-dot'),
  'bell-electric': () => import('./icons/bell-electric'),
  'bell-minus': () => import('./icons/bell-minus'),
  'bell-off': () => import('./icons/bell-off'),
  'bell-plus': () => import('./icons/bell-plus'),
  'bell-ring': () => import('./icons/bell-ring'),
  'bell': () => import('./icons/bell'),
  'between-horizontal-end': () => import('./icons/between-horizontal-end'),
  'between-horizonal-end': () => import('./icons/between-horizontal-end'),
  'between-horizontal-start': () => import('./icons/between-horizontal-start'),
  'between-horizonal-start': () => import('./icons/between-horizontal-start'),
  'between-vertical-end': () => import('./icons/between-vertical-end'),
  'between-vertical-start': () => import('./icons/between-vertical-start'),
  'biceps-flexed': () => import('./icons/biceps-flexed'),
  'bike': () => import('./icons/bike'),
  'binary': () => import('./icons/binary'),
  'binoculars': () => import('./icons/binoculars'),
  'biohazard': () => import('./icons/biohazard'),
  'bird': () => import('./icons/bird'),
  'bitcoin': () => import('./icons/bitcoin'),
  'blend': () => import('./icons/blend'),
  'blinds': () => import('./icons/blinds'),
  'blocks': () => import('./icons/blocks'),
  'bluetooth-connected': () => import('./icons/bluetooth-connected'),
  'bluetooth-off': () => import('./icons/bluetooth-off'),
  'bluetooth-searching': () => import('./icons/bluetooth-searching'),
  'bluetooth': () => import('./icons/bluetooth'),
  'bold': () => import('./icons/bold'),
  'bolt': () => import('./icons/bolt'),
  'bomb': () => import('./icons/bomb'),
  'bone': () => import('./icons/bone'),
  'book-a': () => import('./icons/book-a'),
  'book-audio': () => import('./icons/book-audio'),
  'book-check': () => import('./icons/book-check'),
  'book-copy': () => import('./icons/book-copy'),
  'book-dashed': () => import('./icons/book-dashed'),
  'book-template': () => import('./icons/book-dashed'),
  'book-down': () => import('./icons/book-down'),
  'book-headphones': () => import('./icons/book-headphones'),
  'book-heart': () => import('./icons/book-heart'),
  'book-image': () => import('./icons/book-image'),
  'book-key': () => import('./icons/book-key'),
  'book-lock': () => import('./icons/book-lock'),
  'book-marked': () => import('./icons/book-marked'),
  'book-minus': () => import('./icons/book-minus'),
  'book-open-check': () => import('./icons/book-open-check'),
  'book-open-text': () => import('./icons/book-open-text'),
  'book-open': () => import('./icons/book-open'),
  'book-plus': () => import('./icons/book-plus'),
  'book-text': () => import('./icons/book-text'),
  'book-type': () => import('./icons/book-type'),
  'book-up-2': () => import('./icons/book-up-2'),
  'book-up': () => import('./icons/book-up'),
  'book-user': () => import('./icons/book-user'),
  'book-x': () => import('./icons/book-x'),
  'book': () => import('./icons/book'),
  'bookmark-check': () => import('./icons/bookmark-check'),
  'bookmark-minus': () => import('./icons/bookmark-minus'),
  'bookmark-plus': () => import('./icons/bookmark-plus'),
  'bookmark-x': () => import('./icons/bookmark-x'),
  'bookmark': () => import('./icons/bookmark'),
  'boom-box': () => import('./icons/boom-box'),
  'bot-message-square': () => import('./icons/bot-message-square'),
  'bot-off': () => import('./icons/bot-off'),
  'bot': () => import('./icons/bot'),
  'box': () => import('./icons/box'),
  'boxes': () => import('./icons/boxes'),
  'braces': () => import('./icons/braces'),
  'curly-braces': () => import('./icons/braces'),
  'brackets': () => import('./icons/brackets'),
  'brain-circuit': () => import('./icons/brain-circuit'),
  'brain-cog': () => import('./icons/brain-cog'),
  'brain': () => import('./icons/brain'),
  'brick-wall': () => import('./icons/brick-wall'),
  'briefcase-business': () => import('./icons/briefcase-business'),
  'briefcase-conveyor-belt': () => import('./icons/briefcase-conveyor-belt'),
  'briefcase-medical': () => import('./icons/briefcase-medical'),
  'briefcase': () => import('./icons/briefcase'),
  'bring-to-front': () => import('./icons/bring-to-front'),
  'brush': () => import('./icons/brush'),
  'bug-off': () => import('./icons/bug-off'),
  'bug-play': () => import('./icons/bug-play'),
  'bug': () => import('./icons/bug'),
  'building-2': () => import('./icons/building-2'),
  'building': () => import('./icons/building'),
  'bus-front': () => import('./icons/bus-front'),
  'bus': () => import('./icons/bus'),
  'cable-car': () => import('./icons/cable-car'),
  'cable': () => import('./icons/cable'),
  'cake-slice': () => import('./icons/cake-slice'),
  'cake': () => import('./icons/cake'),
  'calculator': () => import('./icons/calculator'),
  'calendar-1': () => import('./icons/calendar-1'),
  'calendar-arrow-down': () => import('./icons/calendar-arrow-down'),
  'calendar-arrow-up': () => import('./icons/calendar-arrow-up'),
  'calendar-check-2': () => import('./icons/calendar-check-2'),
  'calendar-check': () => import('./icons/calendar-check'),
  'calendar-clock': () => import('./icons/calendar-clock'),
  'calendar-cog': () => import('./icons/calendar-cog'),
  'calendar-days': () => import('./icons/calendar-days'),
  'calendar-fold': () => import('./icons/calendar-fold'),
  'calendar-heart': () => import('./icons/calendar-heart'),
  'calendar-minus-2': () => import('./icons/calendar-minus-2'),
  'calendar-minus': () => import('./icons/calendar-minus'),
  'calendar-off': () => import('./icons/calendar-off'),
  'calendar-plus-2': () => import('./icons/calendar-plus-2'),
  'calendar-plus': () => import('./icons/calendar-plus'),
  'calendar-range': () => import('./icons/calendar-range'),
  'calendar-search': () => import('./icons/calendar-search'),
  'calendar-sync': () => import('./icons/calendar-sync'),
  'calendar-x-2': () => import('./icons/calendar-x-2'),
  'calendar-x': () => import('./icons/calendar-x'),
  'calendar': () => import('./icons/calendar'),
  'camera-off': () => import('./icons/camera-off'),
  'camera': () => import('./icons/camera'),
  'candy-cane': () => import('./icons/candy-cane'),
  'candy-off': () => import('./icons/candy-off'),
  'candy': () => import('./icons/candy'),
  'cannabis': () => import('./icons/cannabis'),
  'captions-off': () => import('./icons/captions-off'),
  'captions': () => import('./icons/captions'),
  'subtitles': () => import('./icons/captions'),
  'car-front': () => import('./icons/car-front'),
  'car-taxi-front': () => import('./icons/car-taxi-front'),
  'car': () => import('./icons/car'),
  'caravan': () => import('./icons/caravan'),
  'carrot': () => import('./icons/carrot'),
  'case-lower': () => import('./icons/case-lower'),
  'case-sensitive': () => import('./icons/case-sensitive'),
  'case-upper': () => import('./icons/case-upper'),
  'cassette-tape': () => import('./icons/cassette-tape'),
  'cast': () => import('./icons/cast'),
  'castle': () => import('./icons/castle'),
  'cat': () => import('./icons/cat'),
  'cctv': () => import('./icons/cctv'),
  'chart-area': () => import('./icons/chart-area'),
  'area-chart': () => import('./icons/chart-area'),
  'chart-bar-big': () => import('./icons/chart-bar-big'),
  'bar-chart-horizontal-big': () => import('./icons/chart-bar-big'),
  'chart-bar-decreasing': () => import('./icons/chart-bar-decreasing'),
  'chart-bar-increasing': () => import('./icons/chart-bar-increasing'),
  'chart-bar-stacked': () => import('./icons/chart-bar-stacked'),
  'chart-bar': () => import('./icons/chart-bar'),
  'bar-chart-horizontal': () => import('./icons/chart-bar'),
  'chart-candlestick': () => import('./icons/chart-candlestick'),
  'candlestick-chart': () => import('./icons/chart-candlestick'),
  'chart-column-big': () => import('./icons/chart-column-big'),
  'bar-chart-big': () => import('./icons/chart-column-big'),
  'chart-column-decreasing': () => import('./icons/chart-column-decreasing'),
  'chart-column-increasing': () => import('./icons/chart-column-increasing'),
  'bar-chart-4': () => import('./icons/chart-column-increasing'),
  'chart-column-stacked': () => import('./icons/chart-column-stacked'),
  'chart-column': () => import('./icons/chart-column'),
  'bar-chart-3': () => import('./icons/chart-column'),
  'chart-gantt': () => import('./icons/chart-gantt'),
  'chart-line': () => import('./icons/chart-line'),
  'line-chart': () => import('./icons/chart-line'),
  'chart-network': () => import('./icons/chart-network'),
  'chart-no-axes-column-decreasing': () => import('./icons/chart-no-axes-column-decreasing'),
  'chart-no-axes-column-increasing': () => import('./icons/chart-no-axes-column-increasing'),
  'bar-chart': () => import('./icons/chart-no-axes-column-increasing'),
  'chart-no-axes-column': () => import('./icons/chart-no-axes-column'),
  'bar-chart-2': () => import('./icons/chart-no-axes-column'),
  'chart-no-axes-combined': () => import('./icons/chart-no-axes-combined'),
  'chart-no-axes-gantt': () => import('./icons/chart-no-axes-gantt'),
  'gantt-chart': () => import('./icons/chart-no-axes-gantt'),
  'chart-pie': () => import('./icons/chart-pie'),
  'pie-chart': () => import('./icons/chart-pie'),
  'chart-scatter': () => import('./icons/chart-scatter'),
  'scatter-chart': () => import('./icons/chart-scatter'),
  'chart-spline': () => import('./icons/chart-spline'),
  'check-check': () => import('./icons/check-check'),
  'check': () => import('./icons/check'),
  'chef-hat': () => import('./icons/chef-hat'),
  'cherry': () => import('./icons/cherry'),
  'chevron-down': () => import('./icons/chevron-down'),
  'chevron-first': () => import('./icons/chevron-first'),
  'chevron-last': () => import('./icons/chevron-last'),
  'chevron-left': () => import('./icons/chevron-left'),
  'chevron-right': () => import('./icons/chevron-right'),
  'chevron-up': () => import('./icons/chevron-up'),
  'chevrons-down-up': () => import('./icons/chevrons-down-up'),
  'chevrons-down': () => import('./icons/chevrons-down'),
  'chevrons-left-right-ellipsis': () => import('./icons/chevrons-left-right-ellipsis'),
  'chevrons-left-right': () => import('./icons/chevrons-left-right'),
  'chevrons-left': () => import('./icons/chevrons-left'),
  'chevrons-right-left': () => import('./icons/chevrons-right-left'),
  'chevrons-right': () => import('./icons/chevrons-right'),
  'chevrons-up-down': () => import('./icons/chevrons-up-down'),
  'chevrons-up': () => import('./icons/chevrons-up'),
  'chrome': () => import('./icons/chrome'),
  'church': () => import('./icons/church'),
  'cigarette-off': () => import('./icons/cigarette-off'),
  'cigarette': () => import('./icons/cigarette'),
  'circle-alert': () => import('./icons/circle-alert'),
  'alert-circle': () => import('./icons/circle-alert'),
  'circle-arrow-down': () => import('./icons/circle-arrow-down'),
  'arrow-down-circle': () => import('./icons/circle-arrow-down'),
  'circle-arrow-left': () => import('./icons/circle-arrow-left'),
  'arrow-left-circle': () => import('./icons/circle-arrow-left'),
  'circle-arrow-out-down-left': () => import('./icons/circle-arrow-out-down-left'),
  'arrow-down-left-from-circle': () => import('./icons/circle-arrow-out-down-left'),
  'circle-arrow-out-down-right': () => import('./icons/circle-arrow-out-down-right'),
  'arrow-down-right-from-circle': () => import('./icons/circle-arrow-out-down-right'),
  'circle-arrow-out-up-left': () => import('./icons/circle-arrow-out-up-left'),
  'arrow-up-left-from-circle': () => import('./icons/circle-arrow-out-up-left'),
  'circle-arrow-out-up-right': () => import('./icons/circle-arrow-out-up-right'),
  'arrow-up-right-from-circle': () => import('./icons/circle-arrow-out-up-right'),
  'circle-arrow-right': () => import('./icons/circle-arrow-right'),
  'arrow-right-circle': () => import('./icons/circle-arrow-right'),
  'circle-arrow-up': () => import('./icons/circle-arrow-up'),
  'arrow-up-circle': () => import('./icons/circle-arrow-up'),
  'circle-check-big': () => import('./icons/circle-check-big'),
  'check-circle': () => import('./icons/circle-check-big'),
  'circle-check': () => import('./icons/circle-check'),
  'check-circle-2': () => import('./icons/circle-check'),
  'circle-chevron-down': () => import('./icons/circle-chevron-down'),
  'chevron-down-circle': () => import('./icons/circle-chevron-down'),
  'circle-chevron-left': () => import('./icons/circle-chevron-left'),
  'chevron-left-circle': () => import('./icons/circle-chevron-left'),
  'circle-chevron-right': () => import('./icons/circle-chevron-right'),
  'chevron-right-circle': () => import('./icons/circle-chevron-right'),
  'circle-chevron-up': () => import('./icons/circle-chevron-up'),
  'chevron-up-circle': () => import('./icons/circle-chevron-up'),
  'circle-dashed': () => import('./icons/circle-dashed'),
  'circle-divide': () => import('./icons/circle-divide'),
  'divide-circle': () => import('./icons/circle-divide'),
  'circle-dollar-sign': () => import('./icons/circle-dollar-sign'),
  'circle-dot-dashed': () => import('./icons/circle-dot-dashed'),
  'circle-dot': () => import('./icons/circle-dot'),
  'circle-ellipsis': () => import('./icons/circle-ellipsis'),
  'circle-equal': () => import('./icons/circle-equal'),
  'circle-fading-arrow-up': () => import('./icons/circle-fading-arrow-up'),
  'circle-fading-plus': () => import('./icons/circle-fading-plus'),
  'circle-gauge': () => import('./icons/circle-gauge'),
  'gauge-circle': () => import('./icons/circle-gauge'),
  'circle-help': () => import('./icons/circle-help'),
  'help-circle': () => import('./icons/circle-help'),
  'circle-minus': () => import('./icons/circle-minus'),
  'minus-circle': () => import('./icons/circle-minus'),
  'circle-off': () => import('./icons/circle-off'),
  'circle-parking-off': () => import('./icons/circle-parking-off'),
  'parking-circle-off': () => import('./icons/circle-parking-off'),
  'circle-parking': () => import('./icons/circle-parking'),
  'parking-circle': () => import('./icons/circle-parking'),
  'circle-pause': () => import('./icons/circle-pause'),
  'pause-circle': () => import('./icons/circle-pause'),
  'circle-percent': () => import('./icons/circle-percent'),
  'percent-circle': () => import('./icons/circle-percent'),
  'circle-play': () => import('./icons/circle-play'),
  'play-circle': () => import('./icons/circle-play'),
  'circle-plus': () => import('./icons/circle-plus'),
  'plus-circle': () => import('./icons/circle-plus'),
  'circle-power': () => import('./icons/circle-power'),
  'power-circle': () => import('./icons/circle-power'),
  'circle-slash-2': () => import('./icons/circle-slash-2'),
  'circle-slashed': () => import('./icons/circle-slash-2'),
  'circle-slash': () => import('./icons/circle-slash'),
  'circle-stop': () => import('./icons/circle-stop'),
  'stop-circle': () => import('./icons/circle-stop'),
  'circle-user-round': () => import('./icons/circle-user-round'),
  'user-circle-2': () => import('./icons/circle-user-round'),
  'circle-user': () => import('./icons/circle-user'),
  'user-circle': () => import('./icons/circle-user'),
  'circle-x': () => import('./icons/circle-x'),
  'x-circle': () => import('./icons/circle-x'),
  'circle': () => import('./icons/circle'),
  'circuit-board': () => import('./icons/circuit-board'),
  'citrus': () => import('./icons/citrus'),
  'clapperboard': () => import('./icons/clapperboard'),
  'clipboard-check': () => import('./icons/clipboard-check'),
  'clipboard-copy': () => import('./icons/clipboard-copy'),
  'clipboard-list': () => import('./icons/clipboard-list'),
  'clipboard-minus': () => import('./icons/clipboard-minus'),
  'clipboard-paste': () => import('./icons/clipboard-paste'),
  'clipboard-pen-line': () => import('./icons/clipboard-pen-line'),
  'clipboard-signature': () => import('./icons/clipboard-pen-line'),
  'clipboard-pen': () => import('./icons/clipboard-pen'),
  'clipboard-edit': () => import('./icons/clipboard-pen'),
  'clipboard-plus': () => import('./icons/clipboard-plus'),
  'clipboard-type': () => import('./icons/clipboard-type'),
  'clipboard-x': () => import('./icons/clipboard-x'),
  'clipboard': () => import('./icons/clipboard'),
  'clock-1': () => import('./icons/clock-1'),
  'clock-10': () => import('./icons/clock-10'),
  'clock-11': () => import('./icons/clock-11'),
  'clock-12': () => import('./icons/clock-12'),
  'clock-2': () => import('./icons/clock-2'),
  'clock-3': () => import('./icons/clock-3'),
  'clock-4': () => import('./icons/clock-4'),
  'clock-5': () => import('./icons/clock-5'),
  'clock-6': () => import('./icons/clock-6'),
  'clock-7': () => import('./icons/clock-7'),
  'clock-8': () => import('./icons/clock-8'),
  'clock-9': () => import('./icons/clock-9'),
  'clock-alert': () => import('./icons/clock-alert'),
  'clock-arrow-down': () => import('./icons/clock-arrow-down'),
  'clock-arrow-up': () => import('./icons/clock-arrow-up'),
  'clock': () => import('./icons/clock'),
  'cloud-alert': () => import('./icons/cloud-alert'),
  'cloud-cog': () => import('./icons/cloud-cog'),
  'cloud-download': () => import('./icons/cloud-download'),
  'download-cloud': () => import('./icons/cloud-download'),
  'cloud-drizzle': () => import('./icons/cloud-drizzle'),
  'cloud-fog': () => import('./icons/cloud-fog'),
  'cloud-hail': () => import('./icons/cloud-hail'),
  'cloud-lightning': () => import('./icons/cloud-lightning'),
  'cloud-moon-rain': () => import('./icons/cloud-moon-rain'),
  'cloud-moon': () => import('./icons/cloud-moon'),
  'cloud-off': () => import('./icons/cloud-off'),
  'cloud-rain-wind': () => import('./icons/cloud-rain-wind'),
  'cloud-rain': () => import('./icons/cloud-rain'),
  'cloud-snow': () => import('./icons/cloud-snow'),
  'cloud-sun-rain': () => import('./icons/cloud-sun-rain'),
  'cloud-sun': () => import('./icons/cloud-sun'),
  'cloud-upload': () => import('./icons/cloud-upload'),
  'upload-cloud': () => import('./icons/cloud-upload'),
  'cloud': () => import('./icons/cloud'),
  'cloudy': () => import('./icons/cloudy'),
  'clover': () => import('./icons/clover'),
  'club': () => import('./icons/club'),
  'code-xml': () => import('./icons/code-xml'),
  'code-2': () => import('./icons/code-xml'),
  'code': () => import('./icons/code'),
  'codepen': () => import('./icons/codepen'),
  'codesandbox': () => import('./icons/codesandbox'),
  'coffee': () => import('./icons/coffee'),
  'cog': () => import('./icons/cog'),
  'coins': () => import('./icons/coins'),
  'columns-2': () => import('./icons/columns-2'),
  'columns': () => import('./icons/columns-2'),
  'columns-3': () => import('./icons/columns-3'),
  'panels-left-right': () => import('./icons/columns-3'),
  'columns-4': () => import('./icons/columns-4'),
  'combine': () => import('./icons/combine'),
  'command': () => import('./icons/command'),
  'compass': () => import('./icons/compass'),
  'component': () => import('./icons/component'),
  'computer': () => import('./icons/computer'),
  'concierge-bell': () => import('./icons/concierge-bell'),
  'cone': () => import('./icons/cone'),
  'construction': () => import('./icons/construction'),
  'contact-round': () => import('./icons/contact-round'),
  'contact-2': () => import('./icons/contact-round'),
  'contact': () => import('./icons/contact'),
  'container': () => import('./icons/container'),
  'contrast': () => import('./icons/contrast'),
  'cookie': () => import('./icons/cookie'),
  'cooking-pot': () => import('./icons/cooking-pot'),
  'copy-check': () => import('./icons/copy-check'),
  'copy-minus': () => import('./icons/copy-minus'),
  'copy-plus': () => import('./icons/copy-plus'),
  'copy-slash': () => import('./icons/copy-slash'),
  'copy-x': () => import('./icons/copy-x'),
  'copy': () => import('./icons/copy'),
  'copyleft': () => import('./icons/copyleft'),
  'copyright': () => import('./icons/copyright'),
  'corner-down-left': () => import('./icons/corner-down-left'),
  'corner-down-right': () => import('./icons/corner-down-right'),
  'corner-left-down': () => import('./icons/corner-left-down'),
  'corner-left-up': () => import('./icons/corner-left-up'),
  'corner-right-down': () => import('./icons/corner-right-down'),
  'corner-right-up': () => import('./icons/corner-right-up'),
  'corner-up-left': () => import('./icons/corner-up-left'),
  'corner-up-right': () => import('./icons/corner-up-right'),
  'cpu': () => import('./icons/cpu'),
  'creative-commons': () => import('./icons/creative-commons'),
  'credit-card': () => import('./icons/credit-card'),
  'croissant': () => import('./icons/croissant'),
  'crop': () => import('./icons/crop'),
  'cross': () => import('./icons/cross'),
  'crosshair': () => import('./icons/crosshair'),
  'crown': () => import('./icons/crown'),
  'cuboid': () => import('./icons/cuboid'),
  'cup-soda': () => import('./icons/cup-soda'),
  'currency': () => import('./icons/currency'),
  'cylinder': () => import('./icons/cylinder'),
  'dam': () => import('./icons/dam'),
  'database-backup': () => import('./icons/database-backup'),
  'database-zap': () => import('./icons/database-zap'),
  'database': () => import('./icons/database'),
  'delete': () => import('./icons/delete'),
  'dessert': () => import('./icons/dessert'),
  'diameter': () => import('./icons/diameter'),
  'diamond-minus': () => import('./icons/diamond-minus'),
  'diamond-percent': () => import('./icons/diamond-percent'),
  'percent-diamond': () => import('./icons/diamond-percent'),
  'diamond-plus': () => import('./icons/diamond-plus'),
  'diamond': () => import('./icons/diamond'),
  'dice-1': () => import('./icons/dice-1'),
  'dice-2': () => import('./icons/dice-2'),
  'dice-3': () => import('./icons/dice-3'),
  'dice-4': () => import('./icons/dice-4'),
  'dice-5': () => import('./icons/dice-5'),
  'dice-6': () => import('./icons/dice-6'),
  'dices': () => import('./icons/dices'),
  'diff': () => import('./icons/diff'),
  'disc-2': () => import('./icons/disc-2'),
  'disc-3': () => import('./icons/disc-3'),
  'disc-album': () => import('./icons/disc-album'),
  'disc': () => import('./icons/disc'),
  'divide': () => import('./icons/divide'),
  'dna-off': () => import('./icons/dna-off'),
  'dna': () => import('./icons/dna'),
  'dock': () => import('./icons/dock'),
  'dog': () => import('./icons/dog'),
  'dollar-sign': () => import('./icons/dollar-sign'),
  'donut': () => import('./icons/donut'),
  'door-closed': () => import('./icons/door-closed'),
  'door-open': () => import('./icons/door-open'),
  'dot': () => import('./icons/dot'),
  'download': () => import('./icons/download'),
  'drafting-compass': () => import('./icons/drafting-compass'),
  'drama': () => import('./icons/drama'),
  'dribbble': () => import('./icons/dribbble'),
  'drill': () => import('./icons/drill'),
  'droplet-off': () => import('./icons/droplet-off'),
  'droplet': () => import('./icons/droplet'),
  'droplets': () => import('./icons/droplets'),
  'drum': () => import('./icons/drum'),
  'drumstick': () => import('./icons/drumstick'),
  'dumbbell': () => import('./icons/dumbbell'),
  'ear-off': () => import('./icons/ear-off'),
  'ear': () => import('./icons/ear'),
  'earth-lock': () => import('./icons/earth-lock'),
  'earth': () => import('./icons/earth'),
  'globe-2': () => import('./icons/earth'),
  'eclipse': () => import('./icons/eclipse'),
  'egg-fried': () => import('./icons/egg-fried'),
  'egg-off': () => import('./icons/egg-off'),
  'egg': () => import('./icons/egg'),
  'ellipsis-vertical': () => import('./icons/ellipsis-vertical'),
  'more-vertical': () => import('./icons/ellipsis-vertical'),
  'ellipsis': () => import('./icons/ellipsis'),
  'more-horizontal': () => import('./icons/ellipsis'),
  'equal-approximately': () => import('./icons/equal-approximately'),
  'equal-not': () => import('./icons/equal-not'),
  'equal': () => import('./icons/equal'),
  'eraser': () => import('./icons/eraser'),
  'ethernet-port': () => import('./icons/ethernet-port'),
  'euro': () => import('./icons/euro'),
  'expand': () => import('./icons/expand'),
  'external-link': () => import('./icons/external-link'),
  'eye-closed': () => import('./icons/eye-closed'),
  'eye-off': () => import('./icons/eye-off'),
  'eye': () => import('./icons/eye'),
  'facebook': () => import('./icons/facebook'),
  'factory': () => import('./icons/factory'),
  'fan': () => import('./icons/fan'),
  'fast-forward': () => import('./icons/fast-forward'),
  'feather': () => import('./icons/feather'),
  'fence': () => import('./icons/fence'),
  'ferris-wheel': () => import('./icons/ferris-wheel'),
  'figma': () => import('./icons/figma'),
  'file-archive': () => import('./icons/file-archive'),
  'file-audio-2': () => import('./icons/file-audio-2'),
  'file-audio': () => import('./icons/file-audio'),
  'file-axis-3d': () => import('./icons/file-axis-3d'),
  'file-axis-3-d': () => import('./icons/file-axis-3d'),
  'file-badge-2': () => import('./icons/file-badge-2'),
  'file-badge': () => import('./icons/file-badge'),
  'file-box': () => import('./icons/file-box'),
  'file-chart-column-increasing': () => import('./icons/file-chart-column-increasing'),
  'file-bar-chart': () => import('./icons/file-chart-column-increasing'),
  'file-chart-column': () => import('./icons/file-chart-column'),
  'file-bar-chart-2': () => import('./icons/file-chart-column'),
  'file-chart-line': () => import('./icons/file-chart-line'),
  'file-line-chart': () => import('./icons/file-chart-line'),
  'file-chart-pie': () => import('./icons/file-chart-pie'),
  'file-pie-chart': () => import('./icons/file-chart-pie'),
  'file-check-2': () => import('./icons/file-check-2'),
  'file-check': () => import('./icons/file-check'),
  'file-clock': () => import('./icons/file-clock'),
  'file-code-2': () => import('./icons/file-code-2'),
  'file-code': () => import('./icons/file-code'),
  'file-cog': () => import('./icons/file-cog'),
  'file-cog-2': () => import('./icons/file-cog'),
  'file-diff': () => import('./icons/file-diff'),
  'file-digit': () => import('./icons/file-digit'),
  'file-down': () => import('./icons/file-down'),
  'file-heart': () => import('./icons/file-heart'),
  'file-image': () => import('./icons/file-image'),
  'file-input': () => import('./icons/file-input'),
  'file-json-2': () => import('./icons/file-json-2'),
  'file-json': () => import('./icons/file-json'),
  'file-key-2': () => import('./icons/file-key-2'),
  'file-key': () => import('./icons/file-key'),
  'file-lock-2': () => import('./icons/file-lock-2'),
  'file-lock': () => import('./icons/file-lock'),
  'file-minus-2': () => import('./icons/file-minus-2'),
  'file-minus': () => import('./icons/file-minus'),
  'file-music': () => import('./icons/file-music'),
  'file-output': () => import('./icons/file-output'),
  'file-pen-line': () => import('./icons/file-pen-line'),
  'file-signature': () => import('./icons/file-pen-line'),
  'file-pen': () => import('./icons/file-pen'),
  'file-edit': () => import('./icons/file-pen'),
  'file-plus-2': () => import('./icons/file-plus-2'),
  'file-plus': () => import('./icons/file-plus'),
  'file-question': () => import('./icons/file-question'),
  'file-scan': () => import('./icons/file-scan'),
  'file-search-2': () => import('./icons/file-search-2'),
  'file-search': () => import('./icons/file-search'),
  'file-sliders': () => import('./icons/file-sliders'),
  'file-spreadsheet': () => import('./icons/file-spreadsheet'),
  'file-stack': () => import('./icons/file-stack'),
  'file-symlink': () => import('./icons/file-symlink'),
  'file-terminal': () => import('./icons/file-terminal'),
  'file-text': () => import('./icons/file-text'),
  'file-type-2': () => import('./icons/file-type-2'),
  'file-type': () => import('./icons/file-type'),
  'file-up': () => import('./icons/file-up'),
  'file-user': () => import('./icons/file-user'),
  'file-video-2': () => import('./icons/file-video-2'),
  'file-video': () => import('./icons/file-video'),
  'file-volume-2': () => import('./icons/file-volume-2'),
  'file-volume': () => import('./icons/file-volume'),
  'file-warning': () => import('./icons/file-warning'),
  'file-x-2': () => import('./icons/file-x-2'),
  'file-x': () => import('./icons/file-x'),
  'file': () => import('./icons/file'),
  'files': () => import('./icons/files'),
  'film': () => import('./icons/film'),
  'filter-x': () => import('./icons/filter-x'),
  'filter': () => import('./icons/filter'),
  'fingerprint': () => import('./icons/fingerprint'),
  'fire-extinguisher': () => import('./icons/fire-extinguisher'),
  'fish-off': () => import('./icons/fish-off'),
  'fish-symbol': () => import('./icons/fish-symbol'),
  'fish': () => import('./icons/fish'),
  'flag-off': () => import('./icons/flag-off'),
  'flag-triangle-left': () => import('./icons/flag-triangle-left'),
  'flag-triangle-right': () => import('./icons/flag-triangle-right'),
  'flag': () => import('./icons/flag'),
  'flame-kindling': () => import('./icons/flame-kindling'),
  'flame': () => import('./icons/flame'),
  'flashlight-off': () => import('./icons/flashlight-off'),
  'flashlight': () => import('./icons/flashlight'),
  'flask-conical-off': () => import('./icons/flask-conical-off'),
  'flask-conical': () => import('./icons/flask-conical'),
  'flask-round': () => import('./icons/flask-round'),
  'flip-horizontal-2': () => import('./icons/flip-horizontal-2'),
  'flip-horizontal': () => import('./icons/flip-horizontal'),
  'flip-vertical-2': () => import('./icons/flip-vertical-2'),
  'flip-vertical': () => import('./icons/flip-vertical'),
  'flower-2': () => import('./icons/flower-2'),
  'flower': () => import('./icons/flower'),
  'focus': () => import('./icons/focus'),
  'fold-horizontal': () => import('./icons/fold-horizontal'),
  'fold-vertical': () => import('./icons/fold-vertical'),
  'folder-archive': () => import('./icons/folder-archive'),
  'folder-check': () => import('./icons/folder-check'),
  'folder-clock': () => import('./icons/folder-clock'),
  'folder-closed': () => import('./icons/folder-closed'),
  'folder-code': () => import('./icons/folder-code'),
  'folder-cog': () => import('./icons/folder-cog'),
  'folder-cog-2': () => import('./icons/folder-cog'),
  'folder-dot': () => import('./icons/folder-dot'),
  'folder-down': () => import('./icons/folder-down'),
  'folder-git-2': () => import('./icons/folder-git-2'),
  'folder-git': () => import('./icons/folder-git'),
  'folder-heart': () => import('./icons/folder-heart'),
  'folder-input': () => import('./icons/folder-input'),
  'folder-kanban': () => import('./icons/folder-kanban'),
  'folder-key': () => import('./icons/folder-key'),
  'folder-lock': () => import('./icons/folder-lock'),
  'folder-minus': () => import('./icons/folder-minus'),
  'folder-open-dot': () => import('./icons/folder-open-dot'),
  'folder-open': () => import('./icons/folder-open'),
  'folder-output': () => import('./icons/folder-output'),
  'folder-pen': () => import('./icons/folder-pen'),
  'folder-edit': () => import('./icons/folder-pen'),
  'folder-plus': () => import('./icons/folder-plus'),
  'folder-root': () => import('./icons/folder-root'),
  'folder-search-2': () => import('./icons/folder-search-2'),
  'folder-search': () => import('./icons/folder-search'),
  'folder-symlink': () => import('./icons/folder-symlink'),
  'folder-sync': () => import('./icons/folder-sync'),
  'folder-tree': () => import('./icons/folder-tree'),
  'folder-up': () => import('./icons/folder-up'),
  'folder-x': () => import('./icons/folder-x'),
  'folder': () => import('./icons/folder'),
  'folders': () => import('./icons/folders'),
  'footprints': () => import('./icons/footprints'),
  'forklift': () => import('./icons/forklift'),
  'forward': () => import('./icons/forward'),
  'frame': () => import('./icons/frame'),
  'framer': () => import('./icons/framer'),
  'frown': () => import('./icons/frown'),
  'fuel': () => import('./icons/fuel'),
  'fullscreen': () => import('./icons/fullscreen'),
  'gallery-horizontal-end': () => import('./icons/gallery-horizontal-end'),
  'gallery-horizontal': () => import('./icons/gallery-horizontal'),
  'gallery-thumbnails': () => import('./icons/gallery-thumbnails'),
  'gallery-vertical-end': () => import('./icons/gallery-vertical-end'),
  'gallery-vertical': () => import('./icons/gallery-vertical'),
  'gamepad-2': () => import('./icons/gamepad-2'),
  'gamepad': () => import('./icons/gamepad'),
  'gauge': () => import('./icons/gauge'),
  'gavel': () => import('./icons/gavel'),
  'gem': () => import('./icons/gem'),
  'ghost': () => import('./icons/ghost'),
  'gift': () => import('./icons/gift'),
  'git-branch-plus': () => import('./icons/git-branch-plus'),
  'git-branch': () => import('./icons/git-branch'),
  'git-commit-horizontal': () => import('./icons/git-commit-horizontal'),
  'git-commit': () => import('./icons/git-commit-horizontal'),
  'git-commit-vertical': () => import('./icons/git-commit-vertical'),
  'git-compare-arrows': () => import('./icons/git-compare-arrows'),
  'git-compare': () => import('./icons/git-compare'),
  'git-fork': () => import('./icons/git-fork'),
  'git-graph': () => import('./icons/git-graph'),
  'git-merge': () => import('./icons/git-merge'),
  'git-pull-request-arrow': () => import('./icons/git-pull-request-arrow'),
  'git-pull-request-closed': () => import('./icons/git-pull-request-closed'),
  'git-pull-request-create-arrow': () => import('./icons/git-pull-request-create-arrow'),
  'git-pull-request-create': () => import('./icons/git-pull-request-create'),
  'git-pull-request-draft': () => import('./icons/git-pull-request-draft'),
  'git-pull-request': () => import('./icons/git-pull-request'),
  'github': () => import('./icons/github'),
  'gitlab': () => import('./icons/gitlab'),
  'glass-water': () => import('./icons/glass-water'),
  'glasses': () => import('./icons/glasses'),
  'globe-lock': () => import('./icons/globe-lock'),
  'globe': () => import('./icons/globe'),
  'goal': () => import('./icons/goal'),
  'grab': () => import('./icons/grab'),
  'graduation-cap': () => import('./icons/graduation-cap'),
  'grape': () => import('./icons/grape'),
  'grid-2x2-check': () => import('./icons/grid-2x2-check'),
  'grid-2-x-2-check': () => import('./icons/grid-2x2-check'),
  'grid-2x2-plus': () => import('./icons/grid-2x2-plus'),
  'grid-2-x-2-plus': () => import('./icons/grid-2x2-plus'),
  'grid-2x2-x': () => import('./icons/grid-2x2-x'),
  'grid-2-x-2-x': () => import('./icons/grid-2x2-x'),
  'grid-2x2': () => import('./icons/grid-2x2'),
  'grid-2-x-2': () => import('./icons/grid-2x2'),
  'grid-3x3': () => import('./icons/grid-3x3'),
  'grid': () => import('./icons/grid-3x3'),
  'grid-3-x-3': () => import('./icons/grid-3x3'),
  'grip-horizontal': () => import('./icons/grip-horizontal'),
  'grip-vertical': () => import('./icons/grip-vertical'),
  'grip': () => import('./icons/grip'),
  'group': () => import('./icons/group'),
  'guitar': () => import('./icons/guitar'),
  'ham': () => import('./icons/ham'),
  'hammer': () => import('./icons/hammer'),
  'hand-coins': () => import('./icons/hand-coins'),
  'hand-heart': () => import('./icons/hand-heart'),
  'hand-helping': () => import('./icons/hand-helping'),
  'helping-hand': () => import('./icons/hand-helping'),
  'hand-metal': () => import('./icons/hand-metal'),
  'hand-platter': () => import('./icons/hand-platter'),
  'hand': () => import('./icons/hand'),
  'handshake': () => import('./icons/handshake'),
  'hard-drive-download': () => import('./icons/hard-drive-download'),
  'hard-drive-upload': () => import('./icons/hard-drive-upload'),
  'hard-drive': () => import('./icons/hard-drive'),
  'hard-hat': () => import('./icons/hard-hat'),
  'hash': () => import('./icons/hash'),
  'haze': () => import('./icons/haze'),
  'hdmi-port': () => import('./icons/hdmi-port'),
  'heading-1': () => import('./icons/heading-1'),
  'heading-2': () => import('./icons/heading-2'),
  'heading-3': () => import('./icons/heading-3'),
  'heading-4': () => import('./icons/heading-4'),
  'heading-5': () => import('./icons/heading-5'),
  'heading-6': () => import('./icons/heading-6'),
  'heading': () => import('./icons/heading'),
  'headphone-off': () => import('./icons/headphone-off'),
  'headphones': () => import('./icons/headphones'),
  'headset': () => import('./icons/headset'),
  'heart-crack': () => import('./icons/heart-crack'),
  'heart-handshake': () => import('./icons/heart-handshake'),
  'heart-off': () => import('./icons/heart-off'),
  'heart-pulse': () => import('./icons/heart-pulse'),
  'heart': () => import('./icons/heart'),
  'heater': () => import('./icons/heater'),
  'hexagon': () => import('./icons/hexagon'),
  'highlighter': () => import('./icons/highlighter'),
  'history': () => import('./icons/history'),
  'hop-off': () => import('./icons/hop-off'),
  'hop': () => import('./icons/hop'),
  'hospital': () => import('./icons/hospital'),
  'hotel': () => import('./icons/hotel'),
  'hourglass': () => import('./icons/hourglass'),
  'house-plug': () => import('./icons/house-plug'),
  'house-plus': () => import('./icons/house-plus'),
  'house-wifi': () => import('./icons/house-wifi'),
  'house': () => import('./icons/house'),
  'home': () => import('./icons/house'),
  'ice-cream-bowl': () => import('./icons/ice-cream-bowl'),
  'ice-cream-2': () => import('./icons/ice-cream-bowl'),
  'ice-cream-cone': () => import('./icons/ice-cream-cone'),
  'ice-cream': () => import('./icons/ice-cream-cone'),
  'id-card': () => import('./icons/id-card'),
  'image-down': () => import('./icons/image-down'),
  'image-minus': () => import('./icons/image-minus'),
  'image-off': () => import('./icons/image-off'),
  'image-play': () => import('./icons/image-play'),
  'image-plus': () => import('./icons/image-plus'),
  'image-up': () => import('./icons/image-up'),
  'image-upscale': () => import('./icons/image-upscale'),
  'image': () => import('./icons/image'),
  'images': () => import('./icons/images'),
  'import': () => import('./icons/import'),
  'inbox': () => import('./icons/inbox'),
  'indent-decrease': () => import('./icons/indent-decrease'),
  'outdent': () => import('./icons/indent-decrease'),
  'indent-increase': () => import('./icons/indent-increase'),
  'indent': () => import('./icons/indent-increase'),
  'indian-rupee': () => import('./icons/indian-rupee'),
  'infinity': () => import('./icons/infinity'),
  'info': () => import('./icons/info'),
  'inspection-panel': () => import('./icons/inspection-panel'),
  'instagram': () => import('./icons/instagram'),
  'italic': () => import('./icons/italic'),
  'iteration-ccw': () => import('./icons/iteration-ccw'),
  'iteration-cw': () => import('./icons/iteration-cw'),
  'japanese-yen': () => import('./icons/japanese-yen'),
  'joystick': () => import('./icons/joystick'),
  'kanban': () => import('./icons/kanban'),
  'key-round': () => import('./icons/key-round'),
  'key-square': () => import('./icons/key-square'),
  'key': () => import('./icons/key'),
  'keyboard-music': () => import('./icons/keyboard-music'),
  'keyboard-off': () => import('./icons/keyboard-off'),
  'keyboard': () => import('./icons/keyboard'),
  'lamp-ceiling': () => import('./icons/lamp-ceiling'),
  'lamp-desk': () => import('./icons/lamp-desk'),
  'lamp-floor': () => import('./icons/lamp-floor'),
  'lamp-wall-down': () => import('./icons/lamp-wall-down'),
  'lamp-wall-up': () => import('./icons/lamp-wall-up'),
  'lamp': () => import('./icons/lamp'),
  'land-plot': () => import('./icons/land-plot'),
  'landmark': () => import('./icons/landmark'),
  'languages': () => import('./icons/languages'),
  'laptop-minimal-check': () => import('./icons/laptop-minimal-check'),
  'laptop-minimal': () => import('./icons/laptop-minimal'),
  'laptop-2': () => import('./icons/laptop-minimal'),
  'laptop': () => import('./icons/laptop'),
  'lasso-select': () => import('./icons/lasso-select'),
  'lasso': () => import('./icons/lasso'),
  'laugh': () => import('./icons/laugh'),
  'layers-2': () => import('./icons/layers-2'),
  'layers': () => import('./icons/layers'),
  'layers-3': () => import('./icons/layers'),
  'layout-dashboard': () => import('./icons/layout-dashboard'),
  'layout-grid': () => import('./icons/layout-grid'),
  'layout-list': () => import('./icons/layout-list'),
  'layout-panel-left': () => import('./icons/layout-panel-left'),
  'layout-panel-top': () => import('./icons/layout-panel-top'),
  'layout-template': () => import('./icons/layout-template'),
  'leaf': () => import('./icons/leaf'),
  'leafy-green': () => import('./icons/leafy-green'),
  'lectern': () => import('./icons/lectern'),
  'letter-text': () => import('./icons/letter-text'),
  'library-big': () => import('./icons/library-big'),
  'library': () => import('./icons/library'),
  'life-buoy': () => import('./icons/life-buoy'),
  'ligature': () => import('./icons/ligature'),
  'lightbulb-off': () => import('./icons/lightbulb-off'),
  'lightbulb': () => import('./icons/lightbulb'),
  'link-2-off': () => import('./icons/link-2-off'),
  'link-2': () => import('./icons/link-2'),
  'link': () => import('./icons/link'),
  'linkedin': () => import('./icons/linkedin'),
  'list-check': () => import('./icons/list-check'),
  'list-checks': () => import('./icons/list-checks'),
  'list-collapse': () => import('./icons/list-collapse'),
  'list-end': () => import('./icons/list-end'),
  'list-filter-plus': () => import('./icons/list-filter-plus'),
  'list-filter': () => import('./icons/list-filter'),
  'list-minus': () => import('./icons/list-minus'),
  'list-music': () => import('./icons/list-music'),
  'list-ordered': () => import('./icons/list-ordered'),
  'list-plus': () => import('./icons/list-plus'),
  'list-restart': () => import('./icons/list-restart'),
  'list-start': () => import('./icons/list-start'),
  'list-todo': () => import('./icons/list-todo'),
  'list-tree': () => import('./icons/list-tree'),
  'list-video': () => import('./icons/list-video'),
  'list-x': () => import('./icons/list-x'),
  'list': () => import('./icons/list'),
  'loader-circle': () => import('./icons/loader-circle'),
  'loader-2': () => import('./icons/loader-circle'),
  'loader-pinwheel': () => import('./icons/loader-pinwheel'),
  'loader': () => import('./icons/loader'),
  'locate-fixed': () => import('./icons/locate-fixed'),
  'locate-off': () => import('./icons/locate-off'),
  'locate': () => import('./icons/locate'),
  'lock-keyhole-open': () => import('./icons/lock-keyhole-open'),
  'unlock-keyhole': () => import('./icons/lock-keyhole-open'),
  'lock-keyhole': () => import('./icons/lock-keyhole'),
  'lock-open': () => import('./icons/lock-open'),
  'unlock': () => import('./icons/lock-open'),
  'lock': () => import('./icons/lock'),
  'log-in': () => import('./icons/log-in'),
  'log-out': () => import('./icons/log-out'),
  'logs': () => import('./icons/logs'),
  'lollipop': () => import('./icons/lollipop'),
  'luggage': () => import('./icons/luggage'),
  'magnet': () => import('./icons/magnet'),
  'mail-check': () => import('./icons/mail-check'),
  'mail-minus': () => import('./icons/mail-minus'),
  'mail-open': () => import('./icons/mail-open'),
  'mail-plus': () => import('./icons/mail-plus'),
  'mail-question': () => import('./icons/mail-question'),
  'mail-search': () => import('./icons/mail-search'),
  'mail-warning': () => import('./icons/mail-warning'),
  'mail-x': () => import('./icons/mail-x'),
  'mail': () => import('./icons/mail'),
  'mailbox': () => import('./icons/mailbox'),
  'mails': () => import('./icons/mails'),
  'map-pin-check-inside': () => import('./icons/map-pin-check-inside'),
  'map-pin-check': () => import('./icons/map-pin-check'),
  'map-pin-house': () => import('./icons/map-pin-house'),
  'map-pin-minus-inside': () => import('./icons/map-pin-minus-inside'),
  'map-pin-minus': () => import('./icons/map-pin-minus'),
  'map-pin-off': () => import('./icons/map-pin-off'),
  'map-pin-plus-inside': () => import('./icons/map-pin-plus-inside'),
  'map-pin-plus': () => import('./icons/map-pin-plus'),
  'map-pin-x-inside': () => import('./icons/map-pin-x-inside'),
  'map-pin-x': () => import('./icons/map-pin-x'),
  'map-pin': () => import('./icons/map-pin'),
  'map-pinned': () => import('./icons/map-pinned'),
  'map-plus': () => import('./icons/map-plus'),
  'map': () => import('./icons/map'),
  'martini': () => import('./icons/martini'),
  'maximize-2': () => import('./icons/maximize-2'),
  'maximize': () => import('./icons/maximize'),
  'medal': () => import('./icons/medal'),
  'megaphone-off': () => import('./icons/megaphone-off'),
  'megaphone': () => import('./icons/megaphone'),
  'meh': () => import('./icons/meh'),
  'memory-stick': () => import('./icons/memory-stick'),
  'menu': () => import('./icons/menu'),
  'merge': () => import('./icons/merge'),
  'message-circle-code': () => import('./icons/message-circle-code'),
  'message-circle-dashed': () => import('./icons/message-circle-dashed'),
  'message-circle-heart': () => import('./icons/message-circle-heart'),
  'message-circle-more': () => import('./icons/message-circle-more'),
  'message-circle-off': () => import('./icons/message-circle-off'),
  'message-circle-plus': () => import('./icons/message-circle-plus'),
  'message-circle-question': () => import('./icons/message-circle-question'),
  'message-circle-reply': () => import('./icons/message-circle-reply'),
  'message-circle-warning': () => import('./icons/message-circle-warning'),
  'message-circle-x': () => import('./icons/message-circle-x'),
  'message-circle': () => import('./icons/message-circle'),
  'message-square-code': () => import('./icons/message-square-code'),
  'message-square-dashed': () => import('./icons/message-square-dashed'),
  'message-square-diff': () => import('./icons/message-square-diff'),
  'message-square-dot': () => import('./icons/message-square-dot'),
  'message-square-heart': () => import('./icons/message-square-heart'),
  'message-square-lock': () => import('./icons/message-square-lock'),
  'message-square-more': () => import('./icons/message-square-more'),
  'message-square-off': () => import('./icons/message-square-off'),
  'message-square-plus': () => import('./icons/message-square-plus'),
  'message-square-quote': () => import('./icons/message-square-quote'),
  'message-square-reply': () => import('./icons/message-square-reply'),
  'message-square-share': () => import('./icons/message-square-share'),
  'message-square-text': () => import('./icons/message-square-text'),
  'message-square-warning': () => import('./icons/message-square-warning'),
  'message-square-x': () => import('./icons/message-square-x'),
  'message-square': () => import('./icons/message-square'),
  'messages-square': () => import('./icons/messages-square'),
  'mic-off': () => import('./icons/mic-off'),
  'mic-vocal': () => import('./icons/mic-vocal'),
  'mic-2': () => import('./icons/mic-vocal'),
  'mic': () => import('./icons/mic'),
  'microchip': () => import('./icons/microchip'),
  'microscope': () => import('./icons/microscope'),
  'microwave': () => import('./icons/microwave'),
  'milestone': () => import('./icons/milestone'),
  'milk-off': () => import('./icons/milk-off'),
  'milk': () => import('./icons/milk'),
  'minimize-2': () => import('./icons/minimize-2'),
  'minimize': () => import('./icons/minimize'),
  'minus': () => import('./icons/minus'),
  'monitor-check': () => import('./icons/monitor-check'),
  'monitor-cog': () => import('./icons/monitor-cog'),
  'monitor-dot': () => import('./icons/monitor-dot'),
  'monitor-down': () => import('./icons/monitor-down'),
  'monitor-off': () => import('./icons/monitor-off'),
  'monitor-pause': () => import('./icons/monitor-pause'),
  'monitor-play': () => import('./icons/monitor-play'),
  'monitor-smartphone': () => import('./icons/monitor-smartphone'),
  'monitor-speaker': () => import('./icons/monitor-speaker'),
  'monitor-stop': () => import('./icons/monitor-stop'),
  'monitor-up': () => import('./icons/monitor-up'),
  'monitor-x': () => import('./icons/monitor-x'),
  'monitor': () => import('./icons/monitor'),
  'moon-star': () => import('./icons/moon-star'),
  'moon': () => import('./icons/moon'),
  'mountain-snow': () => import('./icons/mountain-snow'),
  'mountain': () => import('./icons/mountain'),
  'mouse-off': () => import('./icons/mouse-off'),
  'mouse-pointer-2': () => import('./icons/mouse-pointer-2'),
  'mouse-pointer-ban': () => import('./icons/mouse-pointer-ban'),
  'mouse-pointer-click': () => import('./icons/mouse-pointer-click'),
  'mouse-pointer': () => import('./icons/mouse-pointer'),
  'mouse': () => import('./icons/mouse'),
  'move-3d': () => import('./icons/move-3d'),
  'move-3-d': () => import('./icons/move-3d'),
  'move-diagonal-2': () => import('./icons/move-diagonal-2'),
  'move-diagonal': () => import('./icons/move-diagonal'),
  'move-down-left': () => import('./icons/move-down-left'),
  'move-down-right': () => import('./icons/move-down-right'),
  'move-down': () => import('./icons/move-down'),
  'move-horizontal': () => import('./icons/move-horizontal'),
  'move-left': () => import('./icons/move-left'),
  'move-right': () => import('./icons/move-right'),
  'move-up-left': () => import('./icons/move-up-left'),
  'move-up-right': () => import('./icons/move-up-right'),
  'move-up': () => import('./icons/move-up'),
  'move-vertical': () => import('./icons/move-vertical'),
  'move': () => import('./icons/move'),
  'music-2': () => import('./icons/music-2'),
  'music-3': () => import('./icons/music-3'),
  'music-4': () => import('./icons/music-4'),
  'music': () => import('./icons/music'),
  'navigation-2-off': () => import('./icons/navigation-2-off'),
  'navigation-2': () => import('./icons/navigation-2'),
  'navigation-off': () => import('./icons/navigation-off'),
  'navigation': () => import('./icons/navigation'),
  'network': () => import('./icons/network'),
  'newspaper': () => import('./icons/newspaper'),
  'nfc': () => import('./icons/nfc'),
  'notebook-pen': () => import('./icons/notebook-pen'),
  'notebook-tabs': () => import('./icons/notebook-tabs'),
  'notebook-text': () => import('./icons/notebook-text'),
  'notebook': () => import('./icons/notebook'),
  'notepad-text-dashed': () => import('./icons/notepad-text-dashed'),
  'notepad-text': () => import('./icons/notepad-text'),
  'nut-off': () => import('./icons/nut-off'),
  'nut': () => import('./icons/nut'),
  'octagon-alert': () => import('./icons/octagon-alert'),
  'alert-octagon': () => import('./icons/octagon-alert'),
  'octagon-minus': () => import('./icons/octagon-minus'),
  'octagon-pause': () => import('./icons/octagon-pause'),
  'pause-octagon': () => import('./icons/octagon-pause'),
  'octagon-x': () => import('./icons/octagon-x'),
  'x-octagon': () => import('./icons/octagon-x'),
  'octagon': () => import('./icons/octagon'),
  'omega': () => import('./icons/omega'),
  'option': () => import('./icons/option'),
  'orbit': () => import('./icons/orbit'),
  'origami': () => import('./icons/origami'),
  'package-2': () => import('./icons/package-2'),
  'package-check': () => import('./icons/package-check'),
  'package-minus': () => import('./icons/package-minus'),
  'package-open': () => import('./icons/package-open'),
  'package-plus': () => import('./icons/package-plus'),
  'package-search': () => import('./icons/package-search'),
  'package-x': () => import('./icons/package-x'),
  'package': () => import('./icons/package'),
  'paint-bucket': () => import('./icons/paint-bucket'),
  'paint-roller': () => import('./icons/paint-roller'),
  'paintbrush-vertical': () => import('./icons/paintbrush-vertical'),
  'paintbrush-2': () => import('./icons/paintbrush-vertical'),
  'paintbrush': () => import('./icons/paintbrush'),
  'palette': () => import('./icons/palette'),
  'panel-bottom-close': () => import('./icons/panel-bottom-close'),
  'panel-bottom-dashed': () => import('./icons/panel-bottom-dashed'),
  'panel-bottom-inactive': () => import('./icons/panel-bottom-dashed'),
  'panel-bottom-open': () => import('./icons/panel-bottom-open'),
  'panel-bottom': () => import('./icons/panel-bottom'),
  'panel-left-close': () => import('./icons/panel-left-close'),
  'sidebar-close': () => import('./icons/panel-left-close'),
  'panel-left-dashed': () => import('./icons/panel-left-dashed'),
  'panel-left-inactive': () => import('./icons/panel-left-dashed'),
  'panel-left-open': () => import('./icons/panel-left-open'),
  'sidebar-open': () => import('./icons/panel-left-open'),
  'panel-left': () => import('./icons/panel-left'),
  'sidebar': () => import('./icons/panel-left'),
  'panel-right-close': () => import('./icons/panel-right-close'),
  'panel-right-dashed': () => import('./icons/panel-right-dashed'),
  'panel-right-inactive': () => import('./icons/panel-right-dashed'),
  'panel-right-open': () => import('./icons/panel-right-open'),
  'panel-right': () => import('./icons/panel-right'),
  'panel-top-close': () => import('./icons/panel-top-close'),
  'panel-top-dashed': () => import('./icons/panel-top-dashed'),
  'panel-top-inactive': () => import('./icons/panel-top-dashed'),
  'panel-top-open': () => import('./icons/panel-top-open'),
  'panel-top': () => import('./icons/panel-top'),
  'panels-left-bottom': () => import('./icons/panels-left-bottom'),
  'panels-right-bottom': () => import('./icons/panels-right-bottom'),
  'panels-top-left': () => import('./icons/panels-top-left'),
  'layout': () => import('./icons/panels-top-left'),
  'paperclip': () => import('./icons/paperclip'),
  'parentheses': () => import('./icons/parentheses'),
  'parking-meter': () => import('./icons/parking-meter'),
  'party-popper': () => import('./icons/party-popper'),
  'pause': () => import('./icons/pause'),
  'paw-print': () => import('./icons/paw-print'),
  'pc-case': () => import('./icons/pc-case'),
  'pen-line': () => import('./icons/pen-line'),
  'edit-3': () => import('./icons/pen-line'),
  'pen-off': () => import('./icons/pen-off'),
  'pen-tool': () => import('./icons/pen-tool'),
  'pen': () => import('./icons/pen'),
  'edit-2': () => import('./icons/pen'),
  'pencil-line': () => import('./icons/pencil-line'),
  'pencil-off': () => import('./icons/pencil-off'),
  'pencil-ruler': () => import('./icons/pencil-ruler'),
  'pencil': () => import('./icons/pencil'),
  'pentagon': () => import('./icons/pentagon'),
  'percent': () => import('./icons/percent'),
  'person-standing': () => import('./icons/person-standing'),
  'philippine-peso': () => import('./icons/philippine-peso'),
  'phone-call': () => import('./icons/phone-call'),
  'phone-forwarded': () => import('./icons/phone-forwarded'),
  'phone-incoming': () => import('./icons/phone-incoming'),
  'phone-missed': () => import('./icons/phone-missed'),
  'phone-off': () => import('./icons/phone-off'),
  'phone-outgoing': () => import('./icons/phone-outgoing'),
  'phone': () => import('./icons/phone'),
  'pi': () => import('./icons/pi'),
  'piano': () => import('./icons/piano'),
  'pickaxe': () => import('./icons/pickaxe'),
  'picture-in-picture-2': () => import('./icons/picture-in-picture-2'),
  'picture-in-picture': () => import('./icons/picture-in-picture'),
  'piggy-bank': () => import('./icons/piggy-bank'),
  'pilcrow-left': () => import('./icons/pilcrow-left'),
  'pilcrow-right': () => import('./icons/pilcrow-right'),
  'pilcrow': () => import('./icons/pilcrow'),
  'pill-bottle': () => import('./icons/pill-bottle'),
  'pill': () => import('./icons/pill'),
  'pin-off': () => import('./icons/pin-off'),
  'pin': () => import('./icons/pin'),
  'pipette': () => import('./icons/pipette'),
  'pizza': () => import('./icons/pizza'),
  'plane-landing': () => import('./icons/plane-landing'),
  'plane-takeoff': () => import('./icons/plane-takeoff'),
  'plane': () => import('./icons/plane'),
  'play': () => import('./icons/play'),
  'plug-2': () => import('./icons/plug-2'),
  'plug-zap': () => import('./icons/plug-zap'),
  'plug-zap-2': () => import('./icons/plug-zap'),
  'plug': () => import('./icons/plug'),
  'plus': () => import('./icons/plus'),
  'pocket-knife': () => import('./icons/pocket-knife'),
  'pocket': () => import('./icons/pocket'),
  'podcast': () => import('./icons/podcast'),
  'pointer-off': () => import('./icons/pointer-off'),
  'pointer': () => import('./icons/pointer'),
  'popcorn': () => import('./icons/popcorn'),
  'popsicle': () => import('./icons/popsicle'),
  'pound-sterling': () => import('./icons/pound-sterling'),
  'power-off': () => import('./icons/power-off'),
  'power': () => import('./icons/power'),
  'presentation': () => import('./icons/presentation'),
  'printer-check': () => import('./icons/printer-check'),
  'printer': () => import('./icons/printer'),
  'projector': () => import('./icons/projector'),
  'proportions': () => import('./icons/proportions'),
  'puzzle': () => import('./icons/puzzle'),
  'pyramid': () => import('./icons/pyramid'),
  'qr-code': () => import('./icons/qr-code'),
  'quote': () => import('./icons/quote'),
  'rabbit': () => import('./icons/rabbit'),
  'radar': () => import('./icons/radar'),
  'radiation': () => import('./icons/radiation'),
  'radical': () => import('./icons/radical'),
  'radio-receiver': () => import('./icons/radio-receiver'),
  'radio-tower': () => import('./icons/radio-tower'),
  'radio': () => import('./icons/radio'),
  'radius': () => import('./icons/radius'),
  'rail-symbol': () => import('./icons/rail-symbol'),
  'rainbow': () => import('./icons/rainbow'),
  'rat': () => import('./icons/rat'),
  'ratio': () => import('./icons/ratio'),
  'receipt-cent': () => import('./icons/receipt-cent'),
  'receipt-euro': () => import('./icons/receipt-euro'),
  'receipt-indian-rupee': () => import('./icons/receipt-indian-rupee'),
  'receipt-japanese-yen': () => import('./icons/receipt-japanese-yen'),
  'receipt-pound-sterling': () => import('./icons/receipt-pound-sterling'),
  'receipt-russian-ruble': () => import('./icons/receipt-russian-ruble'),
  'receipt-swiss-franc': () => import('./icons/receipt-swiss-franc'),
  'receipt-text': () => import('./icons/receipt-text'),
  'receipt': () => import('./icons/receipt'),
  'rectangle-ellipsis': () => import('./icons/rectangle-ellipsis'),
  'form-input': () => import('./icons/rectangle-ellipsis'),
  'rectangle-horizontal': () => import('./icons/rectangle-horizontal'),
  'rectangle-vertical': () => import('./icons/rectangle-vertical'),
  'recycle': () => import('./icons/recycle'),
  'redo-2': () => import('./icons/redo-2'),
  'redo-dot': () => import('./icons/redo-dot'),
  'redo': () => import('./icons/redo'),
  'refresh-ccw-dot': () => import('./icons/refresh-ccw-dot'),
  'refresh-ccw': () => import('./icons/refresh-ccw'),
  'refresh-cw-off': () => import('./icons/refresh-cw-off'),
  'refresh-cw': () => import('./icons/refresh-cw'),
  'refrigerator': () => import('./icons/refrigerator'),
  'regex': () => import('./icons/regex'),
  'remove-formatting': () => import('./icons/remove-formatting'),
  'repeat-1': () => import('./icons/repeat-1'),
  'repeat-2': () => import('./icons/repeat-2'),
  'repeat': () => import('./icons/repeat'),
  'replace-all': () => import('./icons/replace-all'),
  'replace': () => import('./icons/replace'),
  'reply-all': () => import('./icons/reply-all'),
  'reply': () => import('./icons/reply'),
  'rewind': () => import('./icons/rewind'),
  'ribbon': () => import('./icons/ribbon'),
  'rocket': () => import('./icons/rocket'),
  'rocking-chair': () => import('./icons/rocking-chair'),
  'roller-coaster': () => import('./icons/roller-coaster'),
  'rotate-3d': () => import('./icons/rotate-3d'),
  'rotate-3-d': () => import('./icons/rotate-3d'),
  'rotate-ccw-square': () => import('./icons/rotate-ccw-square'),
  'rotate-ccw': () => import('./icons/rotate-ccw'),
  'rotate-cw-square': () => import('./icons/rotate-cw-square'),
  'rotate-cw': () => import('./icons/rotate-cw'),
  'route-off': () => import('./icons/route-off'),
  'route': () => import('./icons/route'),
  'router': () => import('./icons/router'),
  'rows-2': () => import('./icons/rows-2'),
  'rows': () => import('./icons/rows-2'),
  'rows-3': () => import('./icons/rows-3'),
  'panels-top-bottom': () => import('./icons/rows-3'),
  'rows-4': () => import('./icons/rows-4'),
  'rss': () => import('./icons/rss'),
  'ruler': () => import('./icons/ruler'),
  'russian-ruble': () => import('./icons/russian-ruble'),
  'sailboat': () => import('./icons/sailboat'),
  'salad': () => import('./icons/salad'),
  'sandwich': () => import('./icons/sandwich'),
  'satellite-dish': () => import('./icons/satellite-dish'),
  'satellite': () => import('./icons/satellite'),
  'save-all': () => import('./icons/save-all'),
  'save-off': () => import('./icons/save-off'),
  'save': () => import('./icons/save'),
  'scale-3d': () => import('./icons/scale-3d'),
  'scale-3-d': () => import('./icons/scale-3d'),
  'scale': () => import('./icons/scale'),
  'scaling': () => import('./icons/scaling'),
  'scan-barcode': () => import('./icons/scan-barcode'),
  'scan-eye': () => import('./icons/scan-eye'),
  'scan-face': () => import('./icons/scan-face'),
  'scan-heart': () => import('./icons/scan-heart'),
  'scan-line': () => import('./icons/scan-line'),
  'scan-qr-code': () => import('./icons/scan-qr-code'),
  'scan-search': () => import('./icons/scan-search'),
  'scan-text': () => import('./icons/scan-text'),
  'scan': () => import('./icons/scan'),
  'school': () => import('./icons/school'),
  'scissors-line-dashed': () => import('./icons/scissors-line-dashed'),
  'scissors': () => import('./icons/scissors'),
  'screen-share-off': () => import('./icons/screen-share-off'),
  'screen-share': () => import('./icons/screen-share'),
  'scroll-text': () => import('./icons/scroll-text'),
  'scroll': () => import('./icons/scroll'),
  'search-check': () => import('./icons/search-check'),
  'search-code': () => import('./icons/search-code'),
  'search-slash': () => import('./icons/search-slash'),
  'search-x': () => import('./icons/search-x'),
  'search': () => import('./icons/search'),
  'section': () => import('./icons/section'),
  'send-horizontal': () => import('./icons/send-horizontal'),
  'send-horizonal': () => import('./icons/send-horizontal'),
  'send-to-back': () => import('./icons/send-to-back'),
  'send': () => import('./icons/send'),
  'separator-horizontal': () => import('./icons/separator-horizontal'),
  'separator-vertical': () => import('./icons/separator-vertical'),
  'server-cog': () => import('./icons/server-cog'),
  'server-crash': () => import('./icons/server-crash'),
  'server-off': () => import('./icons/server-off'),
  'server': () => import('./icons/server'),
  'settings-2': () => import('./icons/settings-2'),
  'settings': () => import('./icons/settings'),
  'shapes': () => import('./icons/shapes'),
  'share-2': () => import('./icons/share-2'),
  'share': () => import('./icons/share'),
  'sheet': () => import('./icons/sheet'),
  'shell': () => import('./icons/shell'),
  'shield-alert': () => import('./icons/shield-alert'),
  'shield-ban': () => import('./icons/shield-ban'),
  'shield-check': () => import('./icons/shield-check'),
  'shield-ellipsis': () => import('./icons/shield-ellipsis'),
  'shield-half': () => import('./icons/shield-half'),
  'shield-minus': () => import('./icons/shield-minus'),
  'shield-off': () => import('./icons/shield-off'),
  'shield-plus': () => import('./icons/shield-plus'),
  'shield-question': () => import('./icons/shield-question'),
  'shield-x': () => import('./icons/shield-x'),
  'shield-close': () => import('./icons/shield-x'),
  'shield': () => import('./icons/shield'),
  'ship-wheel': () => import('./icons/ship-wheel'),
  'ship': () => import('./icons/ship'),
  'shirt': () => import('./icons/shirt'),
  'shopping-bag': () => import('./icons/shopping-bag'),
  'shopping-basket': () => import('./icons/shopping-basket'),
  'shopping-cart': () => import('./icons/shopping-cart'),
  'shovel': () => import('./icons/shovel'),
  'shower-head': () => import('./icons/shower-head'),
  'shrink': () => import('./icons/shrink'),
  'shrub': () => import('./icons/shrub'),
  'shuffle': () => import('./icons/shuffle'),
  'sigma': () => import('./icons/sigma'),
  'signal-high': () => import('./icons/signal-high'),
  'signal-low': () => import('./icons/signal-low'),
  'signal-medium': () => import('./icons/signal-medium'),
  'signal-zero': () => import('./icons/signal-zero'),
  'signal': () => import('./icons/signal'),
  'signature': () => import('./icons/signature'),
  'signpost-big': () => import('./icons/signpost-big'),
  'signpost': () => import('./icons/signpost'),
  'siren': () => import('./icons/siren'),
  'skip-back': () => import('./icons/skip-back'),
  'skip-forward': () => import('./icons/skip-forward'),
  'skull': () => import('./icons/skull'),
  'slack': () => import('./icons/slack'),
  'slash': () => import('./icons/slash'),
  'slice': () => import('./icons/slice'),
  'sliders-horizontal': () => import('./icons/sliders-horizontal'),
  'sliders-vertical': () => import('./icons/sliders-vertical'),
  'sliders': () => import('./icons/sliders-vertical'),
  'smartphone-charging': () => import('./icons/smartphone-charging'),
  'smartphone-nfc': () => import('./icons/smartphone-nfc'),
  'smartphone': () => import('./icons/smartphone'),
  'smile-plus': () => import('./icons/smile-plus'),
  'smile': () => import('./icons/smile'),
  'snail': () => import('./icons/snail'),
  'snowflake': () => import('./icons/snowflake'),
  'sofa': () => import('./icons/sofa'),
  'soup': () => import('./icons/soup'),
  'space': () => import('./icons/space'),
  'spade': () => import('./icons/spade'),
  'sparkle': () => import('./icons/sparkle'),
  'sparkles': () => import('./icons/sparkles'),
  'stars': () => import('./icons/sparkles'),
  'speaker': () => import('./icons/speaker'),
  'speech': () => import('./icons/speech'),
  'spell-check-2': () => import('./icons/spell-check-2'),
  'spell-check': () => import('./icons/spell-check'),
  'spline': () => import('./icons/spline'),
  'split': () => import('./icons/split'),
  'spray-can': () => import('./icons/spray-can'),
  'sprout': () => import('./icons/sprout'),
  'square-activity': () => import('./icons/square-activity'),
  'activity-square': () => import('./icons/square-activity'),
  'square-arrow-down-left': () => import('./icons/square-arrow-down-left'),
  'arrow-down-left-square': () => import('./icons/square-arrow-down-left'),
  'square-arrow-down-right': () => import('./icons/square-arrow-down-right'),
  'arrow-down-right-square': () => import('./icons/square-arrow-down-right'),
  'square-arrow-down': () => import('./icons/square-arrow-down'),
  'arrow-down-square': () => import('./icons/square-arrow-down'),
  'square-arrow-left': () => import('./icons/square-arrow-left'),
  'arrow-left-square': () => import('./icons/square-arrow-left'),
  'square-arrow-out-down-left': () => import('./icons/square-arrow-out-down-left'),
  'arrow-down-left-from-square': () => import('./icons/square-arrow-out-down-left'),
  'square-arrow-out-down-right': () => import('./icons/square-arrow-out-down-right'),
  'arrow-down-right-from-square': () => import('./icons/square-arrow-out-down-right'),
  'square-arrow-out-up-left': () => import('./icons/square-arrow-out-up-left'),
  'arrow-up-left-from-square': () => import('./icons/square-arrow-out-up-left'),
  'square-arrow-out-up-right': () => import('./icons/square-arrow-out-up-right'),
  'arrow-up-right-from-square': () => import('./icons/square-arrow-out-up-right'),
  'square-arrow-right': () => import('./icons/square-arrow-right'),
  'arrow-right-square': () => import('./icons/square-arrow-right'),
  'square-arrow-up-left': () => import('./icons/square-arrow-up-left'),
  'arrow-up-left-square': () => import('./icons/square-arrow-up-left'),
  'square-arrow-up-right': () => import('./icons/square-arrow-up-right'),
  'arrow-up-right-square': () => import('./icons/square-arrow-up-right'),
  'square-arrow-up': () => import('./icons/square-arrow-up'),
  'arrow-up-square': () => import('./icons/square-arrow-up'),
  'square-asterisk': () => import('./icons/square-asterisk'),
  'asterisk-square': () => import('./icons/square-asterisk'),
  'square-bottom-dashed-scissors': () => import('./icons/square-bottom-dashed-scissors'),
  'scissors-square-dashed-bottom': () => import('./icons/square-bottom-dashed-scissors'),
  'square-chart-gantt': () => import('./icons/square-chart-gantt'),
  'gantt-chart-square': () => import('./icons/square-chart-gantt'),
  'square-gantt-chart': () => import('./icons/square-chart-gantt'),
  'square-check-big': () => import('./icons/square-check-big'),
  'check-square': () => import('./icons/square-check-big'),
  'square-check': () => import('./icons/square-check'),
  'check-square-2': () => import('./icons/square-check'),
  'square-chevron-down': () => import('./icons/square-chevron-down'),
  'chevron-down-square': () => import('./icons/square-chevron-down'),
  'square-chevron-left': () => import('./icons/square-chevron-left'),
  'chevron-left-square': () => import('./icons/square-chevron-left'),
  'square-chevron-right': () => import('./icons/square-chevron-right'),
  'chevron-right-square': () => import('./icons/square-chevron-right'),
  'square-chevron-up': () => import('./icons/square-chevron-up'),
  'chevron-up-square': () => import('./icons/square-chevron-up'),
  'square-code': () => import('./icons/square-code'),
  'code-square': () => import('./icons/square-code'),
  'square-dashed-bottom-code': () => import('./icons/square-dashed-bottom-code'),
  'square-dashed-bottom': () => import('./icons/square-dashed-bottom'),
  'square-dashed-kanban': () => import('./icons/square-dashed-kanban'),
  'kanban-square-dashed': () => import('./icons/square-dashed-kanban'),
  'square-dashed-mouse-pointer': () => import('./icons/square-dashed-mouse-pointer'),
  'mouse-pointer-square-dashed': () => import('./icons/square-dashed-mouse-pointer'),
  'square-dashed': () => import('./icons/square-dashed'),
  'box-select': () => import('./icons/square-dashed'),
  'square-divide': () => import('./icons/square-divide'),
  'divide-square': () => import('./icons/square-divide'),
  'square-dot': () => import('./icons/square-dot'),
  'dot-square': () => import('./icons/square-dot'),
  'square-equal': () => import('./icons/square-equal'),
  'equal-square': () => import('./icons/square-equal'),
  'square-function': () => import('./icons/square-function'),
  'function-square': () => import('./icons/square-function'),
  'square-kanban': () => import('./icons/square-kanban'),
  'kanban-square': () => import('./icons/square-kanban'),
  'square-library': () => import('./icons/square-library'),
  'library-square': () => import('./icons/square-library'),
  'square-m': () => import('./icons/square-m'),
  'm-square': () => import('./icons/square-m'),
  'square-menu': () => import('./icons/square-menu'),
  'menu-square': () => import('./icons/square-menu'),
  'square-minus': () => import('./icons/square-minus'),
  'minus-square': () => import('./icons/square-minus'),
  'square-mouse-pointer': () => import('./icons/square-mouse-pointer'),
  'inspect': () => import('./icons/square-mouse-pointer'),
  'square-parking-off': () => import('./icons/square-parking-off'),
  'parking-square-off': () => import('./icons/square-parking-off'),
  'square-parking': () => import('./icons/square-parking'),
  'parking-square': () => import('./icons/square-parking'),
  'square-pen': () => import('./icons/square-pen'),
  'pen-box': () => import('./icons/square-pen'),
  'edit': () => import('./icons/square-pen'),
  'pen-square': () => import('./icons/square-pen'),
  'square-percent': () => import('./icons/square-percent'),
  'percent-square': () => import('./icons/square-percent'),
  'square-pi': () => import('./icons/square-pi'),
  'pi-square': () => import('./icons/square-pi'),
  'square-pilcrow': () => import('./icons/square-pilcrow'),
  'pilcrow-square': () => import('./icons/square-pilcrow'),
  'square-play': () => import('./icons/square-play'),
  'play-square': () => import('./icons/square-play'),
  'square-plus': () => import('./icons/square-plus'),
  'plus-square': () => import('./icons/square-plus'),
  'square-power': () => import('./icons/square-power'),
  'power-square': () => import('./icons/square-power'),
  'square-radical': () => import('./icons/square-radical'),
  'square-scissors': () => import('./icons/square-scissors'),
  'scissors-square': () => import('./icons/square-scissors'),
  'square-sigma': () => import('./icons/square-sigma'),
  'sigma-square': () => import('./icons/square-sigma'),
  'square-slash': () => import('./icons/square-slash'),
  'slash-square': () => import('./icons/square-slash'),
  'square-split-horizontal': () => import('./icons/square-split-horizontal'),
  'split-square-horizontal': () => import('./icons/square-split-horizontal'),
  'square-split-vertical': () => import('./icons/square-split-vertical'),
  'split-square-vertical': () => import('./icons/square-split-vertical'),
  'square-square': () => import('./icons/square-square'),
  'square-stack': () => import('./icons/square-stack'),
  'square-terminal': () => import('./icons/square-terminal'),
  'terminal-square': () => import('./icons/square-terminal'),
  'square-user-round': () => import('./icons/square-user-round'),
  'user-square-2': () => import('./icons/square-user-round'),
  'square-user': () => import('./icons/square-user'),
  'user-square': () => import('./icons/square-user'),
  'square-x': () => import('./icons/square-x'),
  'x-square': () => import('./icons/square-x'),
  'square': () => import('./icons/square'),
  'squircle': () => import('./icons/squircle'),
  'squirrel': () => import('./icons/squirrel'),
  'stamp': () => import('./icons/stamp'),
  'star-half': () => import('./icons/star-half'),
  'star-off': () => import('./icons/star-off'),
  'star': () => import('./icons/star'),
  'step-back': () => import('./icons/step-back'),
  'step-forward': () => import('./icons/step-forward'),
  'stethoscope': () => import('./icons/stethoscope'),
  'sticker': () => import('./icons/sticker'),
  'sticky-note': () => import('./icons/sticky-note'),
  'store': () => import('./icons/store'),
  'stretch-horizontal': () => import('./icons/stretch-horizontal'),
  'stretch-vertical': () => import('./icons/stretch-vertical'),
  'strikethrough': () => import('./icons/strikethrough'),
  'subscript': () => import('./icons/subscript'),
  'sun-dim': () => import('./icons/sun-dim'),
  'sun-medium': () => import('./icons/sun-medium'),
  'sun-moon': () => import('./icons/sun-moon'),
  'sun-snow': () => import('./icons/sun-snow'),
  'sun': () => import('./icons/sun'),
  'sunrise': () => import('./icons/sunrise'),
  'sunset': () => import('./icons/sunset'),
  'superscript': () => import('./icons/superscript'),
  'swatch-book': () => import('./icons/swatch-book'),
  'swiss-franc': () => import('./icons/swiss-franc'),
  'switch-camera': () => import('./icons/switch-camera'),
  'sword': () => import('./icons/sword'),
  'swords': () => import('./icons/swords'),
  'syringe': () => import('./icons/syringe'),
  'table-2': () => import('./icons/table-2'),
  'table-cells-merge': () => import('./icons/table-cells-merge'),
  'table-cells-split': () => import('./icons/table-cells-split'),
  'table-columns-split': () => import('./icons/table-columns-split'),
  'table-of-contents': () => import('./icons/table-of-contents'),
  'table-properties': () => import('./icons/table-properties'),
  'table-rows-split': () => import('./icons/table-rows-split'),
  'table': () => import('./icons/table'),
  'tablet-smartphone': () => import('./icons/tablet-smartphone'),
  'tablet': () => import('./icons/tablet'),
  'tablets': () => import('./icons/tablets'),
  'tag': () => import('./icons/tag'),
  'tags': () => import('./icons/tags'),
  'tally-1': () => import('./icons/tally-1'),
  'tally-2': () => import('./icons/tally-2'),
  'tally-3': () => import('./icons/tally-3'),
  'tally-4': () => import('./icons/tally-4'),
  'tally-5': () => import('./icons/tally-5'),
  'tangent': () => import('./icons/tangent'),
  'target': () => import('./icons/target'),
  'telescope': () => import('./icons/telescope'),
  'tent-tree': () => import('./icons/tent-tree'),
  'tent': () => import('./icons/tent'),
  'terminal': () => import('./icons/terminal'),
  'test-tube-diagonal': () => import('./icons/test-tube-diagonal'),
  'test-tube-2': () => import('./icons/test-tube-diagonal'),
  'test-tube': () => import('./icons/test-tube'),
  'test-tubes': () => import('./icons/test-tubes'),
  'text-cursor-input': () => import('./icons/text-cursor-input'),
  'text-cursor': () => import('./icons/text-cursor'),
  'text-quote': () => import('./icons/text-quote'),
  'text-search': () => import('./icons/text-search'),
  'text-select': () => import('./icons/text-select'),
  'text-selection': () => import('./icons/text-select'),
  'text': () => import('./icons/text'),
  'theater': () => import('./icons/theater'),
  'thermometer-snowflake': () => import('./icons/thermometer-snowflake'),
  'thermometer-sun': () => import('./icons/thermometer-sun'),
  'thermometer': () => import('./icons/thermometer'),
  'thumbs-down': () => import('./icons/thumbs-down'),
  'thumbs-up': () => import('./icons/thumbs-up'),
  'ticket-check': () => import('./icons/ticket-check'),
  'ticket-minus': () => import('./icons/ticket-minus'),
  'ticket-percent': () => import('./icons/ticket-percent'),
  'ticket-plus': () => import('./icons/ticket-plus'),
  'ticket-slash': () => import('./icons/ticket-slash'),
  'ticket-x': () => import('./icons/ticket-x'),
  'ticket': () => import('./icons/ticket'),
  'tickets-plane': () => import('./icons/tickets-plane'),
  'tickets': () => import('./icons/tickets'),
  'timer-off': () => import('./icons/timer-off'),
  'timer-reset': () => import('./icons/timer-reset'),
  'timer': () => import('./icons/timer'),
  'toggle-left': () => import('./icons/toggle-left'),
  'toggle-right': () => import('./icons/toggle-right'),
  'toilet': () => import('./icons/toilet'),
  'tornado': () => import('./icons/tornado'),
  'torus': () => import('./icons/torus'),
  'touchpad-off': () => import('./icons/touchpad-off'),
  'touchpad': () => import('./icons/touchpad'),
  'tower-control': () => import('./icons/tower-control'),
  'toy-brick': () => import('./icons/toy-brick'),
  'tractor': () => import('./icons/tractor'),
  'traffic-cone': () => import('./icons/traffic-cone'),
  'train-front-tunnel': () => import('./icons/train-front-tunnel'),
  'train-front': () => import('./icons/train-front'),
  'train-track': () => import('./icons/train-track'),
  'tram-front': () => import('./icons/tram-front'),
  'train': () => import('./icons/tram-front'),
  'trash-2': () => import('./icons/trash-2'),
  'trash': () => import('./icons/trash'),
  'tree-deciduous': () => import('./icons/tree-deciduous'),
  'tree-palm': () => import('./icons/tree-palm'),
  'palmtree': () => import('./icons/tree-palm'),
  'tree-pine': () => import('./icons/tree-pine'),
  'trees': () => import('./icons/trees'),
  'trello': () => import('./icons/trello'),
  'trending-down': () => import('./icons/trending-down'),
  'trending-up-down': () => import('./icons/trending-up-down'),
  'trending-up': () => import('./icons/trending-up'),
  'triangle-alert': () => import('./icons/triangle-alert'),
  'alert-triangle': () => import('./icons/triangle-alert'),
  'triangle-dashed': () => import('./icons/triangle-dashed'),
  'triangle-right': () => import('./icons/triangle-right'),
  'triangle': () => import('./icons/triangle'),
  'trophy': () => import('./icons/trophy'),
  'truck': () => import('./icons/truck'),
  'turtle': () => import('./icons/turtle'),
  'tv-minimal-play': () => import('./icons/tv-minimal-play'),
  'tv-minimal': () => import('./icons/tv-minimal'),
  'tv-2': () => import('./icons/tv-minimal'),
  'tv': () => import('./icons/tv'),
  'twitch': () => import('./icons/twitch'),
  'twitter': () => import('./icons/twitter'),
  'type-outline': () => import('./icons/type-outline'),
  'type': () => import('./icons/type'),
  'umbrella-off': () => import('./icons/umbrella-off'),
  'umbrella': () => import('./icons/umbrella'),
  'underline': () => import('./icons/underline'),
  'undo-2': () => import('./icons/undo-2'),
  'undo-dot': () => import('./icons/undo-dot'),
  'undo': () => import('./icons/undo'),
  'unfold-horizontal': () => import('./icons/unfold-horizontal'),
  'unfold-vertical': () => import('./icons/unfold-vertical'),
  'ungroup': () => import('./icons/ungroup'),
  'university': () => import('./icons/university'),
  'school-2': () => import('./icons/university'),
  'unlink-2': () => import('./icons/unlink-2'),
  'unlink': () => import('./icons/unlink'),
  'unplug': () => import('./icons/unplug'),
  'upload': () => import('./icons/upload'),
  'usb': () => import('./icons/usb'),
  'user-check': () => import('./icons/user-check'),
  'user-cog': () => import('./icons/user-cog'),
  'user-minus': () => import('./icons/user-minus'),
  'user-pen': () => import('./icons/user-pen'),
  'user-plus': () => import('./icons/user-plus'),
  'user-round-check': () => import('./icons/user-round-check'),
  'user-check-2': () => import('./icons/user-round-check'),
  'user-round-cog': () => import('./icons/user-round-cog'),
  'user-cog-2': () => import('./icons/user-round-cog'),
  'user-round-minus': () => import('./icons/user-round-minus'),
  'user-minus-2': () => import('./icons/user-round-minus'),
  'user-round-pen': () => import('./icons/user-round-pen'),
  'user-round-plus': () => import('./icons/user-round-plus'),
  'user-plus-2': () => import('./icons/user-round-plus'),
  'user-round-search': () => import('./icons/user-round-search'),
  'user-round-x': () => import('./icons/user-round-x'),
  'user-x-2': () => import('./icons/user-round-x'),
  'user-round': () => import('./icons/user-round'),
  'user-2': () => import('./icons/user-round'),
  'user-search': () => import('./icons/user-search'),
  'user-x': () => import('./icons/user-x'),
  'user': () => import('./icons/user'),
  'users-round': () => import('./icons/users-round'),
  'users-2': () => import('./icons/users-round'),
  'users': () => import('./icons/users'),
  'utensils-crossed': () => import('./icons/utensils-crossed'),
  'fork-knife-crossed': () => import('./icons/utensils-crossed'),
  'utensils': () => import('./icons/utensils'),
  'fork-knife': () => import('./icons/utensils'),
  'utility-pole': () => import('./icons/utility-pole'),
  'variable': () => import('./icons/variable'),
  'vault': () => import('./icons/vault'),
  'vegan': () => import('./icons/vegan'),
  'venetian-mask': () => import('./icons/venetian-mask'),
  'vibrate-off': () => import('./icons/vibrate-off'),
  'vibrate': () => import('./icons/vibrate'),
  'video-off': () => import('./icons/video-off'),
  'video': () => import('./icons/video'),
  'videotape': () => import('./icons/videotape'),
  'view': () => import('./icons/view'),
  'voicemail': () => import('./icons/voicemail'),
  'volleyball': () => import('./icons/volleyball'),
  'volume-1': () => import('./icons/volume-1'),
  'volume-2': () => import('./icons/volume-2'),
  'volume-off': () => import('./icons/volume-off'),
  'volume-x': () => import('./icons/volume-x'),
  'volume': () => import('./icons/volume'),
  'vote': () => import('./icons/vote'),
  'wallet-cards': () => import('./icons/wallet-cards'),
  'wallet-minimal': () => import('./icons/wallet-minimal'),
  'wallet-2': () => import('./icons/wallet-minimal'),
  'wallet': () => import('./icons/wallet'),
  'wallpaper': () => import('./icons/wallpaper'),
  'wand-sparkles': () => import('./icons/wand-sparkles'),
  'wand-2': () => import('./icons/wand-sparkles'),
  'wand': () => import('./icons/wand'),
  'warehouse': () => import('./icons/warehouse'),
  'washing-machine': () => import('./icons/washing-machine'),
  'watch': () => import('./icons/watch'),
  'waves-ladder': () => import('./icons/waves-ladder'),
  'waves': () => import('./icons/waves'),
  'waypoints': () => import('./icons/waypoints'),
  'webcam': () => import('./icons/webcam'),
  'webhook-off': () => import('./icons/webhook-off'),
  'webhook': () => import('./icons/webhook'),
  'weight': () => import('./icons/weight'),
  'wheat-off': () => import('./icons/wheat-off'),
  'wheat': () => import('./icons/wheat'),
  'whole-word': () => import('./icons/whole-word'),
  'wifi-high': () => import('./icons/wifi-high'),
  'wifi-low': () => import('./icons/wifi-low'),
  'wifi-off': () => import('./icons/wifi-off'),
  'wifi-zero': () => import('./icons/wifi-zero'),
  'wifi': () => import('./icons/wifi'),
  'wind-arrow-down': () => import('./icons/wind-arrow-down'),
  'wind': () => import('./icons/wind'),
  'wine-off': () => import('./icons/wine-off'),
  'wine': () => import('./icons/wine'),
  'workflow': () => import('./icons/workflow'),
  'worm': () => import('./icons/worm'),
  'wrap-text': () => import('./icons/wrap-text'),
  'wrench': () => import('./icons/wrench'),
  'x': () => import('./icons/x'),
  'youtube': () => import('./icons/youtube'),
  'zap-off': () => import('./icons/zap-off'),
  'zap': () => import('./icons/zap'),
  'zoom-in': () => import('./icons/zoom-in'),
  'zoom-out': () => import('./icons/zoom-out'),
};
export default dynamicIconImports;
