import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { MEDIA_FRAGMENT, MediaSchema } from '../components/media.fragment'
import { RICH_TEXT_FRAGMENT, RichTextSchema } from '../components/richText.fragment'
import { OpeningTimeSchema, SpecialOpeningTimeSchema } from './sectionStoreIndex.fragment'

export const RetailStoreDataSchema = z.object({
  shopifyId: z.string(),
  geoPoint: z.object({
    lat: z.number(),
    lng: z.number(),
  }),
  city: z.string(),
  street: z.string(),
  postalCode: z.string(),
  country: z.string(),
  googleMapsLink: z.string().optional(),
  telephoneNumber: z.string().optional(),
  isOpen: z.boolean(),
  defaultOpeningTimes: z.object({
    monday: OpeningTimeSchema,
    tuesday: OpeningTimeSchema,
    wednesday: OpeningTimeSchema,
    thursday: OpeningTimeSchema,
    friday: OpeningTimeSchema,
    saturday: OpeningTimeSchema,
    sunday: OpeningTimeSchema,
  }),
  specialOpeningTimes: z.array(SpecialOpeningTimeSchema),
})

export type RetailStoreData = z.infer<typeof RetailStoreDataSchema>

export const SectionStoreSchema = z.object({
  _type: z.literal('sectionStore'),
  _id: z.string(),
  title: z.string(),
  store: RetailStoreDataSchema,
  description: RichTextSchema.optional(),
  storeMedia: MediaSchema,
})

export type SectionStoreData = z.infer<typeof SectionStoreSchema>

export const SECTION_STORE_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionStore' => {
    _type,
    _id,
    store-> {
      geoPoint,
      ${coalesceQuery('city', locale)},
      street,
      postalCode,
      googleMapsLink,
      telephoneNumber,
      defaultOpeningTimes,
      isOpen,
      specialOpeningTimes[(dateTime(@->date + 'T00:00:00Z') < dateTime(now()) + 60*60*24*14) && (dateTime(@->date + 'T00:00:00Z') > dateTime(now()) - 60*60*24)][0...7]-> {
        ...,
        ${coalesceQuery('message', locale)}
      }
    },
    ${coalesceQuery('title', locale)},
    defined(description) => {
      ${RICH_TEXT_FRAGMENT(locale, 'description')}
    },
    storeMedia-> {
      ${MEDIA_FRAGMENT()}
    }
  }
`)
