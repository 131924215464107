import { defineQuery } from 'next-sanity'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { Link, LINK_FRAGMENT } from '../components/link.fragment'

export interface AccountData {
  login: string
  register: string
  signIn: string
  signUp: string
  reset: string
  resetPassword: string
  newCustomer: string
  returningCustomer: string
  forgotYourPassword: string
  forgotPassword: string
  yourEmail: string
  password: string
  confirmPassword: string
  activateAccount: string
  submit: string
  saveInfo: string
  changeEmailMessage: string
  currentPassword: string
  newPassword: string
  changePassword: string
  userInfo: string
  personalInfo: string
  orders: string
  streetName: string
  postalCode: string
  city: string
  country: string
  address: string
  saveAddress: string
  checkEmail: string
  passwordsMatchError: string
  passwordChangeSuccess: string
  currentPasswordFailMessage: string
  loginFailMessage: string
  userInfoChangeSuccessMesssage: string
  shippingAddress: string
  billingAddress: string
  orderTotal: string
  tax: string
  shipping: string
  subtotal: string
  delivered: string
  shipped: string
  processing: string
  orderPlaced: string
  trackShipment: string
  order: string
  date: string
  requestReturn: string
  logout: string
  myAccount: string
  forgotPasswordErrorMessage: string
  noOrdersYet: string
  productNoLongerAvailable: string
  invoice: string
  shopNowLink: Link
}

export const ACCOUNT_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  ${coalesceQuery('login', locale)},
  ${coalesceQuery('register', locale)},
  ${coalesceQuery('signIn', locale)},
  ${coalesceQuery('signUp', locale)},
  ${coalesceQuery('reset', locale)},
  ${coalesceQuery('resetPassword', locale)},
  ${coalesceQuery('newCustomer', locale)},
  ${coalesceQuery('returningCustomer', locale)},
  ${coalesceQuery('forgotYourPassword', locale)},
  ${coalesceQuery('forgotPassword', locale)},
  ${coalesceQuery('yourEmail', locale)},
  ${coalesceQuery('password', locale)},
  ${coalesceQuery('confirmPassword', locale)},
  ${coalesceQuery('activateAccount', locale)},
  ${coalesceQuery('submit', locale)},
  ${coalesceQuery('saveInfo', locale)},
  ${coalesceQuery('changeEmailMessage', locale)},
  ${coalesceQuery('currentPassword', locale)},
  ${coalesceQuery('newPassword', locale)},
  ${coalesceQuery('changePassword', locale)},
  ${coalesceQuery('userInfo', locale)},
  ${coalesceQuery('personalInfo', locale)},
  ${coalesceQuery('orders', locale)},
  ${coalesceQuery('streetName', locale)},
  ${coalesceQuery('postalCode', locale)},
  ${coalesceQuery('city', locale)},
  ${coalesceQuery('country', locale)},
  ${coalesceQuery('address', locale)},
  ${coalesceQuery('saveAddress', locale)},
  ${coalesceQuery('checkEmail', locale)},
  ${coalesceQuery('passwordsMatchError', locale)},
  ${coalesceQuery('passwordChangeSuccess', locale)},
  ${coalesceQuery('currentPasswordFailMessage', locale)},
  ${coalesceQuery('loginFailMessage', locale)},
  ${coalesceQuery('userInfoChangeSuccessMesssage', locale)},
  ${coalesceQuery('shippingAddress', locale)},
  ${coalesceQuery('billingAddress', locale)},
  ${coalesceQuery('orderTotal', locale)},
  ${coalesceQuery('tax', locale)},
  ${coalesceQuery('shipping', locale)},
  ${coalesceQuery('subtotal', locale)},
  ${coalesceQuery('delivered', locale)},
  ${coalesceQuery('shipped', locale)},
  ${coalesceQuery('processing', locale)},
  ${coalesceQuery('orderPlaced', locale)},
  ${coalesceQuery('trackShipment', locale)},
  ${coalesceQuery('order', locale)},
  ${coalesceQuery('date', locale)},
  ${coalesceQuery('requestReturn', locale)},
  ${coalesceQuery('logout', locale)},
  ${coalesceQuery('myAccount', locale)},
  ${coalesceQuery('forgotPasswordErrorMessage', locale)},
  ${coalesceQuery('noOrdersYet', locale)},
  ${coalesceQuery('productNoLongerAvailable', locale)},
  ${coalesceQuery('invoice', locale)},
  shopNowLink-> {
    ${LINK_FRAGMENT(locale)}
  }
`)
