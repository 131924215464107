import { FunctionComponent } from 'react'
import Link from 'next/link'

import { Badge } from '@ui/components/ui/badge'

import MisterSwiper from 'src/components/shared/MisterSwiper'
import { TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import { useTranslations } from '../../../contexts/Globals.context'
import MisterMedia from '../../shared/media/MisterMedia'
import { OverviewCardProps, SectionProps } from './section-overview-cards'

const OverviewCard: FunctionComponent<OverviewCardProps> = ({ title, description, overviewImage, slug, isNew }) => {
  const translate = useTranslations()
  const hasImage = overviewImage?.mobile?.url || overviewImage?.desktop?.url

  return (
    <Link
      onClick={() => trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_CATEGORY_IMAGE, { clickTitle: title, clickDestination: slug })}
      href={slug}
      title={title}
      className='flex w-auto flex-shrink-0 snap-center flex-col gap-4 lg:w-60'
    >
      <div className='relative overflow-hidden rounded-md'>
        {hasImage ? (
          <MisterMedia className='h-full min-h-[320px] w-full min-w-[240px] transform-gpu object-cover transition-transform duration-300 hover:scale-105' media={overviewImage} />
        ) : (
          <div className='h-[320px] w-[240px] rounded-md bg-brand-grey'></div>
        )}
      </div>

      <div className='flex flex-col gap-1'>
        <div>
          {isNew && (
            <Badge size='lg' className='float-right mx-1'>
              {translate('new', 'New')}
            </Badge>
          )}
          <span className='text-body-xl-bold text-brand-blue'>{title}</span>
        </div>
        {description && <span className='text-pretty pr-4 text-body-md text-brand-blue'>{description}</span>}
      </div>
    </Link>
  )
}

const OverviewCards: FunctionComponent<SectionProps> = ({ data }) => {
  const displayCollection = data.collections[0]

  if (!displayCollection) {
    return null
  }

  return (
    <MisterSwiper slideClassName='!w-[240px] ' containerClassName='!px-4 lg:!px-8'>
      {displayCollection?.data?.map((collection, index) => <OverviewCard key={`overview-card-${index}`} {...collection} />)}
    </MisterSwiper>
  )
}

export default OverviewCards
