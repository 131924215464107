import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { SectionType } from '../common/sectionTypes.fragment'
import { Section, SECTION_LIST, SectionSchema } from '../pages/sections.fragment'

const ContextualizedCssSchema = z.object({
  _type: z.literal('object'),
  _key: z.enum(['base', 'md', 'lg']),
  name: z.enum(['base', 'md', 'lg']),
  value: z.string(),
})

const SectionAbTesterBaseSchema = z.object({
  _id: z.string(),
  _type: z.literal('sectionAbTester'),
  enabled: z.boolean(),
  name: z.string(),
  featureFlag: z.string(),
  alternativeSectionVariantKey: z.string(),
  cssAspectRatio: z.array(ContextualizedCssSchema).optional(),
  cssMinHeight: z.array(ContextualizedCssSchema).optional(),
})

export type SectionAbTesterData = z.infer<typeof SectionAbTesterBaseSchema> & {
  controlSection: Section
  alternativeSection: Section
}

export const SectionAbTesterSchema: z.ZodType<SectionAbTesterData> = SectionAbTesterBaseSchema.extend({
  controlSection: z.lazy(() => SectionSchema),
  alternativeSection: z.lazy(() => SectionSchema),
})

export const SECTION_AB_TESTER_FRAGMENT = (locale = DEFAULT_LOCALE, types: SectionType[]): string => {
  const controlSectionType = types.flatMap((type) => type?.controlSection || []).filter(Boolean) || []
  const alternativeSectionType = types.flatMap((type) => type?.alternativeSection || []).filter(Boolean) || []

  if (controlSectionType.length === 0 || alternativeSectionType.length === 0) {
    return ''
  }

  return defineQuery(`
  _type == 'sectionAbTester' => {
    ...,
    controlSection->{
     ${SECTION_LIST(locale, controlSectionType)}
    },
    alternativeSection->{
     ${SECTION_LIST(locale, alternativeSectionType)}
    },
  }
`)
}
