import { Fragment, FunctionComponent } from 'react'

import { Badge as BadgeComponent } from '@ui/components/ui/badge'

import { useTranslations } from 'src/contexts/Globals.context'
import { useProductCard } from './ProductCard.context'

const Badge: FunctionComponent = () => {
  const { product } = useProductCard('Badge')
  const translate = useTranslations()

  const MAX_BADGES = 2
  let badgesCount = 0

  const badgesToShow = [
    {
      badge: 'comingSoon',
      show: !product.discontinued && !product.isAvailable,
      value: <BadgeComponent className='z-productCard truncate bg-white text-brand-blue'>{translate('comingSoon', 'Coming soon')}</BadgeComponent>,
    },
    {
      badge: 'soldOut',
      show: product.discontinued && !product.isAvailable,
      value: <BadgeComponent className='z-productCard truncate bg-brand-grey-dark'>{translate('addToCartButtonSoldOutText', 'Sold out')}</BadgeComponent>,
    },
    { badge: 'new', show: product.badges?.new && product.isAvailable, value: <BadgeComponent className='z-productCard'>{translate('new', 'New')}</BadgeComponent> },
    {
      badge: 'limitedEdition',
      show: product.badges?.limitedEdition && product.isAvailable,
      value: <BadgeComponent className='z-productCard truncate bg-brand-brass'>{translate('limitedEdition', 'Limited edition')}</BadgeComponent>,
    },
    {
      badge: 'widerStripes',
      show: product.badges?.widerStripes && product.isAvailable,
      value: <BadgeComponent className='z-productCard truncate bg-white text-brand-blue'>{translate('widerStripes', 'Wider stripes')}</BadgeComponent>,
    },
  ]

  return (
    <div className='absolute end-2 top-2 flex flex-row-reverse gap-2 md:end-4 md:top-4'>
      {badgesToShow.map(({ show, value }, i) => {
        if (show && badgesCount < MAX_BADGES) {
          badgesCount++
          return <Fragment key={i}>{value}</Fragment>
        }
      })}
    </div>
  )
}

export default Badge
