import { FunctionComponent, PropsWithChildren } from 'react'
import dynamic from 'next/dynamic'
import { ToastContainer } from 'react-toastify'

import { GetMinimalNavigationResponse } from 'data-access'
import { AverageRating } from 'data-access/database/types'
import { AnnouncementBar } from 'data-access/sanity/fragments/components/announcementBar.fragment'
import { CookieBanner } from 'data-access/sanity/fragments/globals/cookieBanner.fragment'

// import MisterRedirectionNotice from './MisterRedirectionNotice'

import ShopTheLookDrawer from '../../features/shop-the-look/ShopTheLookDrawer'
import MisterElevar from '../scripts/MisterElevar'
import MisterScriptsBodyStart from '../scripts/MisterScriptsBodyStart'
import MisterAnnouncementBar from './announcement/MisterAnnouncementBar'
import Header from './header/Header'
import ScrollHeaderWrapper from './header/ScrollHeaderWrapper'

import 'react-toastify/dist/ReactToastify.css'

export interface MisterLayoutProps {
  navigation: GetMinimalNavigationResponse
  footer?: any
  preview?: boolean
  announcementBarActive?: boolean
  defaultAnnouncementBar?: AnnouncementBar
  cart?: any
  cookieBanner?: CookieBanner
  averageRating?: AverageRating
  showFooterRecommendations?: boolean
}

const MisterFooter = dynamic(() => import('./footer/MisterFooter'))
const MisterCookieBanner = dynamic(() => import('../legal/MisterCookieBanner'))
const MisterCart = dynamic(() => import('./cart/MisterCart'), { ssr: false })
const MisterPreview = dynamic(() => import('./preview/MisterPreview'), { ssr: false })

const MisterLayout: FunctionComponent<PropsWithChildren<MisterLayoutProps>> = ({
  navigation,
  footer,
  preview,
  defaultAnnouncementBar,
  cart,
  children,
  cookieBanner,
  averageRating,
  showFooterRecommendations = true,
}) => (
  <>
    <MisterScriptsBodyStart />
    <MisterElevar />
    {/* <MisterRedirectionNotice /> */}
    <ScrollHeaderWrapper
      announcementBar={<MisterAnnouncementBar defaultAnnouncementBar={defaultAnnouncementBar} secondaryMenuItems={navigation.secondaryMenuItems} />}
      header={<Header data={navigation} preview={preview} />}
    />
    <main>{children}</main>
    <MisterFooter data={footer} averageRating={averageRating} showFooterRecommendations={showFooterRecommendations} />
    <MisterCart data={cart} />
    <ToastContainer position='bottom-center' hideProgressBar={true} newestOnTop={true} closeButton={false} className='text-body-lg drop-shadow-lg' />
    {cookieBanner && <MisterCookieBanner content={cookieBanner} />}
    {preview && <MisterPreview />}
    <ShopTheLookDrawer />
  </>
)

export default MisterLayout
