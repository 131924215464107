import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { RICH_TEXT_FRAGMENT, RichTextSchema } from '../components/richText.fragment'

export const DeliveryTimeSchema = z.object({
  type: z.enum(['orderedBy', 'businessDays']),
  orderBefore: z.string().optional(),
  businessDays: z.string().optional(),
})

export const DeliveryCostsSchema = z.object({
  type: z.enum(['free', 'freeFrom', 'fixed', 'custom']),
  freeFromPrice: z.number().optional(),
  otherwisePrice: z.number().optional(),
  price: z.number().optional(),
  custom: z.string().optional(),
})

const ReturnCostsSchema = z.object({
  type: z.enum(['free', 'custom']),
  custom: z.string().optional(),
})

export const ShipmentAreaSchema = z.object({
  countries: z.array(
    z.object({
      name: z.string(),
      countryCode: z.string(),
    }),
  ),
  deliveryTime: DeliveryTimeSchema,
  shippingCosts: DeliveryCostsSchema,
  returnCosts: ReturnCostsSchema,
})

export const SectionShippingInformationSchema = z.object({
  _type: z.literal('sectionShippingInformation'),
  _id: z.string(),
  showSearchbar: z.boolean(),
  extraInformationHeader: z.string().optional(),
  extraInformation: RichTextSchema.optional(),
  shipmentAreas: z.array(ShipmentAreaSchema).optional(),
})

export type DeliveryTime = z.infer<typeof DeliveryTimeSchema>
export type DeliveryCosts = z.infer<typeof DeliveryCostsSchema>
export type ShipmentArea = z.infer<typeof ShipmentAreaSchema>
export type SectionShippingInformationData = z.infer<typeof SectionShippingInformationSchema>

export const SECTION_SHIPPING_INFORMATION_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionShippingInformation' => {
    _type,
    _id,
    showSearchbar,
    defined(extraInformationHeader) => {
      ${coalesceQuery('extraInformationHeader', locale)}
      },
    defined(extraInformation) => {
      ${RICH_TEXT_FRAGMENT(locale, 'extraInformation')}
    },
    shipmentAreas[]-> {
      countries[]-> {
        ${coalesceQuery('name', locale)},
        countryCode,
      },
      deliveryTime {
        type,
        orderBefore,
        businessDays,
      },
      shippingCosts {
        type,
        defined(freeFromPrice) => {
          ${coalesceQuery('freeFromPrice', locale)}
        },
        defined(otherwisePrice) => {
          ${coalesceQuery('otherwisePrice', locale)}
        },
        defined(price) => {
          ${coalesceQuery('price', locale)}
        },
        defined(custom) => {
          ${coalesceQuery('custom', locale)}
        },
      },
      returnCosts {
        type,
        defined(freeFromPrice) => {
          ${coalesceQuery('freeFromPrice', locale)}
        },
        defined(custom) => {
          ${coalesceQuery('custom', locale)}
        },
      },
    }
  }
`)
