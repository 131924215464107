import { FunctionComponent, useContext, useEffect } from 'react'
import { Bookmark } from 'lucide-react'
import { useRouter } from 'next/router'
import { useToggle } from 'react-use'

import { ProductCardProduct } from 'data-access/sanity/fragments/components/productCardProduct.fragment'
import { cn } from 'ui/lib/utils'
import { Button } from '@ui/components/ui/button'

import MisterSpinner from 'src/components/shared/loader/MisterSpinner'
import { WishlistContext } from 'src/contexts/Wishlist.context'
import { useProductCard } from './ProductCard.context'

const WishListButton: FunctionComponent = () => {
  const { product } = useProductCard('Wish list Button')
  const { locale } = useRouter()
  const { updateItems, itemInWishlistCheck, loading: wishlistLoading } = useContext(WishlistContext)!
  const [currentUpdating, setCurrentUpdating] = useToggle(false)

  const handleWishlistClick = async (WishlistItem: ProductCardProduct) => {
    setCurrentUpdating(true)
    await updateItems(WishlistItem, itemInWishlistCheck(product, locale) ? 'remove' : 'add')
  }

  useEffect(() => {
    if (!wishlistLoading) {
      setCurrentUpdating(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wishlistLoading])

  return (
    <Button
      type='button'
      size='icon'
      variant='secondary'
      hover='scaleEffect'
      onClick={() => {
        handleWishlistClick(product)
      }}
      className='absolute bottom-2 left-2 z-[19] hidden border-none transition-all duration-200 ease-out md:-left-5 md:bottom-4 md:opacity-0 group-focus-within/product:md:left-4 group-focus-within/product:md:opacity-100 group-hover/product:md:left-4 group-hover/product:md:opacity-100 @[15.625rem]/product-card:md:flex'
      data-testid='wishlist-button'
    >
      {currentUpdating && wishlistLoading ? (
        <MisterSpinner size='micro' />
      ) : (
        <Bookmark className={cn('stroke-[1.5] transition-all duration-100 ease-out', itemInWishlistCheck(product, locale) ? 'fill-primary' : 'fill-none')} />
      )}
    </Button>
  )
}

export default WishListButton
