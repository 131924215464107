import { FunctionComponent } from 'react'
import { Eye } from 'lucide-react'

import { ProductCategory } from 'data-access/sanity/fragments/components/mainMenuItem.fragment'
import { Button } from '@ui/components/ui/button'
import { NavigationMenuLink } from '@ui/components/ui/navigation-menu'
import { convertSlug } from 'utilities/string/url'

import MisterImage from 'src/components/shared/image/MisterImage'
import MisterSiteLink, { makeHref } from 'src/components/shared/site-link/MisterSiteLink'
import { useTranslations } from 'src/contexts/Globals.context'
import { TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import { ActiveVariant } from '../Header'

interface MegaMenuLevelTwoProps {
  productCategory: ProductCategory
  activeVariant: ActiveVariant
}

export const MegaMenuLevelTwo: FunctionComponent<MegaMenuLevelTwoProps> = ({ productCategory, activeVariant }) => {
  const translate = useTranslations()
  return (
    <div key={productCategory.productCategory.slug.current} className='grid grid-cols-6'>
      <div className='relative col-span-6 rounded-md bg-brand-beige-light xl:col-span-5 xl:col-start-2'>
        <div className='absolute inset-0 flex flex-col justify-between p-8'>
          <h2 className='text-heading-1 text-brand-blue'>{productCategory.productCategoryMenuTitle}</h2>
          <NavigationMenuLink asChild>
            <MisterSiteLink
              onClick={() => {
                const href = makeHref({ _type: 'link', type: 'internal', page: { slug: productCategory.productCategory.slug, _type: 'collection' } })
                trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, { clickDestination: href })
              }}
              link={convertSlug(productCategory.productCategory.slug, 'collection')}
            >
              <Button hover='expandIcon' Icon={Eye} variant='secondary'>
                {translate('viewAll', 'View all')}
              </Button>
            </MisterSiteLink>
          </NavigationMenuLink>
        </div>
        <MisterImage priority={true} mobile={productCategory.productCategoryImage} className='max-h-[432px] rounded-md object-cover object-center' />
      </div>
    </div>
  )
}
