import { FunctionComponent, useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'

import { getCollectionsByType } from 'data-access'
import { SupportedLocale } from 'data-access/domain/constants'
import { ProductType } from 'data-access/sanity/types'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@ui/components/ui/tabs'
import { cn } from '@ui/lib/utils'

import MisterDropdown from 'src/components/shared/input/MisterDropdown'
import MisterSkeletonLoader from 'src/components/shared/loader/MisterSkeletonLoader'
import { useTranslations } from 'src/contexts/Globals.context'
import MisterSizeGuideContent from 'src/features/size-guide/MisterSizeGuideContent'
import MisterSizeGuideSkeleton from 'src/features/size-guide/MisterSizeGuideSkeleton'
import { useQueryParams } from 'src/hooks/utils/useQueryParams'

interface Collection {
  _id: string
  title: string
}

const SectionSizeCharts: FunctionComponent = () => {
  const { locale, isReady } = useRouter()
  const translate = useTranslations()
  const productTypes: ProductType[] = ['trousers', 'shorts', 'tops', 'shoes']

  const [{ tab, collection }, setQueryParams] = useQueryParams({
    tab: { type: 'string', default: productTypes[0] as ProductType },
    collection: { type: 'string', default: '' },
  })

  const { data, error, isLoading } = useQuery({
    queryKey: [tab],
    queryFn: () => getCollectionsByType(tab as ProductType, locale as SupportedLocale),
    enabled: !!tab && !!locale && isReady,
  })

  const collections = data?.sort((a, b) => a.title.localeCompare(b.title))

  // Only keep currentCollection state for the full object
  const [currentCollection, setCurrentCollection] = useState<Collection | undefined>()

  // Initialize currentCollection when collections load or collection URL param changes
  useEffect(() => {
    if (collections && isReady) {
      if (collection) {
        // If we have a collection ID in URL, find that collection
        const foundCollection = collections.find((c) => c._id === collection)
        setCurrentCollection(foundCollection || collections[0])
      } else {
        setCurrentCollection(collections[0])
      }
    }
  }, [collections, collection, isReady])

  // Update URL when collection changes
  useEffect(() => {
    if (isReady && currentCollection) {
      // Only update if we have both
      setQueryParams({
        tab,
        collection: currentCollection._id,
      })
    }
  }, [currentCollection, isReady, tab])

  return (
    <div className='w-full pb-10' id='size-charts'>
      <Tabs
        value={tab}
        onValueChange={(value) =>
          setQueryParams({
            tab: value as ProductType,
            collection: '', // Reset collection when changing tabs
          })
        }
      >
        <TabsList className='mb-12 inline-flex w-full min-w-24 overflow-x-scroll scrollbar-hide md:mb-16'>
          {productTypes.map((type, i) => (
            <TabsTrigger key={i} value={type}>
              {translate(type, type)}
            </TabsTrigger>
          ))}
        </TabsList>
        <div className='grid grid-cols-1 grid-rows-1'>
          {productTypes.map((type, i) => (
            <TabsContent
              key={i}
              value={type}
              className={cn('col-span-1 col-start-1 row-span-1 row-start-1 flex flex-col opacity-0 transition-opacity', collections && 'opacity-1')}
            >
              <h2 className='mb-5 text-body-xl-bold md:text-callout-title-sm'>{translate(type, type)}</h2>
              <MisterDropdown
                wrapperClassName='mb-9'
                value={currentCollection?._id}
                options={collections?.map((collection) => ({
                  label: collection.title,
                  value: collection._id,
                }))}
                placeholderText={translate('collections', 'Select a collection')}
                onChangeHandler={(option) => {
                  const selectedCollection = collections?.find((c) => c._id === option)
                  setCurrentCollection(selectedCollection)
                }}
              />
            </TabsContent>
          ))}
          {
            <div
              className={cn('opacity-1 pointer-events-none col-span-1 col-start-1 row-span-1 row-start-1 mb-9 flex flex-col gap-5 transition-opacity', collections && 'opacity-0')}
            >
              <MisterSkeletonLoader className='h-8 w-24' />
              <MisterSkeletonLoader className='h-13 w-full' />
            </div>
          }
        </div>
      </Tabs>
      {isLoading && <MisterSizeGuideSkeleton />}
      {!error && !isLoading && currentCollection && <MisterSizeGuideContent collectionId={currentCollection._id} />}
    </div>
  )
}

export default SectionSizeCharts
