import { defineQuery } from 'next-sanity'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { RICH_TEXT_FRAGMENT, RichText } from './richText.fragment'

export interface ProductSizeInfoNote {
  content?: RichText
  showInLocales?: boolean
}

export const PRODUCT_SIZE_INFO_NOTE_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  ${RICH_TEXT_FRAGMENT(locale, 'content')},
  "showInLocales": showInLocales[$locale],
`)
