import { useQuery } from '@tanstack/react-query'

import { getProductsAvailability } from 'data-access'
import { DEFAULT_LOCALE } from 'data-access/domain/constants'
import { ProductCardProduct } from 'data-access/sanity/fragments/components/productCardProduct.fragment'

export type ProductWithEnrichedAvailability = Awaited<ReturnType<typeof enrichProductWithLiveAvailability>>

export const enrichProductWithLiveAvailability = (product: ProductCardProduct, locale = DEFAULT_LOCALE, variantSku?: string) => {
  const isGiftCard = product?.slug?.current?.includes('gift')

  const { data } = useQuery({
    queryKey: ['productsAvailability', locale, product?._id],
    staleTime: 5 * 60 * 1000, // 5 minutes
    queryFn: () => getProductsAvailability(locale, product?._id),
    enabled: !!locale && !!product?._id,
    select: (data) => (data || []).filter(Boolean)?.[0],
  })

  const mappedProductAvailability = data?.variants.edges
    .flatMap(({ node }) => {
      return {
        sku: node.sku,
        availableForSale: node.availableForSale,
        quantityAvailable: node.quantityAvailable,
      }
    })
    .filter(Boolean)
    .map((variant) => {
      const matchingVariant = product.variants.find((productVariant) => productVariant.sku === variant.sku)
      return {
        ...matchingVariant,
        inventory: {
          quantity: variant.quantityAvailable || matchingVariant?.inventory?.quantity || 0,
        },
        isAvailable: isGiftCard ? true : (variant?.availableForSale ?? matchingVariant?.isAvailable),
      }
    })

  const productIsAvailable = isGiftCard
    ? true
    : mappedProductAvailability
      ? // The MisterSearchProductCard component passes a variantSku to the product card to be used to check the stock status
        variantSku
        ? mappedProductAvailability.some(({ sku, inventory }) => sku === variantSku && inventory.quantity > 0)
        : // Otherwise, we check if any variant is in stock
          mappedProductAvailability.some(({ inventory }) => inventory.quantity > 0)
      : product.isAvailable

  product.isAvailable = productIsAvailable

  return product
}
