import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { SlugSchema } from '../../types'
import { coalesceQuery } from '../../utils'
import { GET_IMAGE_FRAGMENT, ImageSchema } from '../common/getImage.fragment'

export const UpsellSchema = z.object({
  upsellCollection: z.object({
    slug: SlugSchema,
  }),
  upsellCollectionImage: ImageSchema,
  toInclude: z.array(z.string()).optional(),
  toExclude: z.array(z.string()).optional(),
  upsellLinkText: z.string(),
  upsellCollectionMessage: z.string(),
})

export type Upsell = z.infer<typeof UpsellSchema>

export const UPSELL_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  defined(upsellCollection) => {
    ${coalesceQuery(
      'upsellCollection',
      locale,
      'upsellCollection',
      `-> {
        _type,
        defined(slug) => {
          ${coalesceQuery('slug', locale, '', ', slug')}
        },
      }`,
    )}
  },
  ${coalesceQuery('upsellCollectionImage', locale, '', `{${GET_IMAGE_FRAGMENT()}}`)},
  defined(toInclude) => {
    "toInclude": array::unique(
      toInclude[]->{
        "slug": coalesce(
          products[]->slug.current,
          [slug.current]
        )
      }.slug[]
    )
  },
  defined(toExclude) => {
    "toExclude": array::unique(
      toExclude[]->{
        "slug": coalesce(
          products[]->slug.current,
          [slug.current]
        )
      }.slug[]
    )
  },
  defined(upsellLinkText) => {
    ${coalesceQuery('upsellLinkText', locale)}
  },
  defined(upsellCollectionMessage) => {
    ${coalesceQuery('upsellCollectionMessage', locale)}
  },
`)
