import { defineQuery } from 'next-sanity'
import { z } from 'zod'

export const SectionSizeChartsSchema = z.object({
  _type: z.literal('sectionSizeCharts'),
  _id: z.string(),
})

export type SectionSizeChartsData = z.infer<typeof SectionSizeChartsSchema>

export const SECTION_SIZE_CHART_FRAGMENT = () =>
  defineQuery(`
  _type == 'sectionSizeCharts' => {
    _type,
    _id,
  }
`)
