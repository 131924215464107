import { Fragment, FunctionComponent, useContext } from 'react'

import { FooterMenuItem } from 'data-access/sanity/fragments/components/footerMenuItem.fragment'
import { cn } from 'ui/lib/utils'
import { Badge } from '@ui/components/ui/badge'
import { sort } from 'utilities/array/sortArray'
import { convertSlug } from 'utilities/string/url'

import { GlobalsContext, useTranslations } from 'src/contexts/Globals.context'
import MisterSiteLink from '../../shared/site-link/MisterSiteLink'

interface Props {
  menuItems: FooterMenuItem[]
}

const MisterFooterMenuItems: FunctionComponent<Props> = ({ menuItems }) => {
  const translate = useTranslations()
  const { stylesOrder } = useContext(GlobalsContext)

  return (
    <>
      {menuItems
        ?.filter((menuItem) => (menuItem?.footerMenuItems?.length || 0) > 0 || menuItem.titleLink?.page?.slug)
        .map((menuItem, i) => {
          const sortedMenuItems = stylesOrder?.applyOrderTo?.footer ? sort(menuItem?.footerMenuItems || [], stylesOrder?.styles || []) : menuItem?.footerMenuItems
          return (
            <div className='break-inside-avoid pb-10' key={i}>
              {menuItem.titleLink ? (
                <MisterSiteLink link={menuItem.titleLink} className='hover:underline'>
                  <h3 className='py-2 text-body-lg-bold'>{menuItem?.title}</h3>
                </MisterSiteLink>
              ) : (
                <h3 className='py-2 text-body-lg-bold'>{menuItem?.title}</h3>
              )}
              {sortedMenuItems && (
                <ul key={i}>
                  {sortedMenuItems.map((footerMenuItem, j) => {
                    return (
                      <Fragment key={j}>
                        {footerMenuItem._type != 'collection' ? (
                          <li key={j}>
                            <MisterSiteLink link={footerMenuItem} className='inline-block py-2 text-body-md hover:underline'>
                              {footerMenuItem?.linkText}
                            </MisterSiteLink>
                          </li>
                        ) : (
                          <li className={cn(footerMenuItem?.new && 'flex items-start xs:justify-between sm:justify-normal')}>
                            <MisterSiteLink link={convertSlug(footerMenuItem.slug, 'collection')} className='flex items-center py-2 text-body-md hover:underline'>
                              {footerMenuItem.collectionTitle}
                            </MisterSiteLink>
                            {footerMenuItem?.new && (
                              <Badge size='sm' className='my-2 ml-3 inline-block bg-brand-blue-grey'>
                                {translate('new', 'New')}
                              </Badge>
                            )}
                          </li>
                        )}
                      </Fragment>
                    )
                  })}
                </ul>
              )}
            </div>
          )
        })}
    </>
  )
}

export default MisterFooterMenuItems
