import { ChangeEvent, FunctionComponent } from 'react'
import { Plus, Search } from 'lucide-react'

import { cn } from 'ui/lib/utils'

import { useTranslations } from 'src/contexts/Globals.context'

interface Props {
  placeholderText?: string
  value?: string
  className?: string
  onChangeHandler?: (e: ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onClear?: () => void
}

const MisterInputSearchBar: FunctionComponent<Props> = ({ placeholderText, value, className, onChangeHandler, onKeyDown, onClear }) => {
  const translate = useTranslations()
  return (
    <div className={cn(className, 'relative')}>
      <Search className='absolute top-1/2 -translate-y-1/2 translate-x-4' size={18} />
      <input
        onKeyDown={onKeyDown}
        type='search'
        placeholder={placeholderText || translate('search', 'Search')}
        value={value}
        onChange={onChangeHandler}
        className='w-full rounded-full bg-brand-beige-light py-2 pl-11 pr-4 placeholder:normal-case'
      />
      {value && (
        <button onClick={onClear}>
          <Plus className='absolute right-0 top-1/2 -translate-x-4 -translate-y-1/2 rotate-45' size={18} />
        </button>
      )}
    </div>
  )
}

export default MisterInputSearchBar
