import { CSSProperties, FunctionComponent } from 'react'
import { usePostHog } from 'posthog-js/react'

import { Link } from 'data-access/sanity/fragments/components/link.fragment'
import { SectionGridCalloutsData } from 'data-access/sanity/fragments/sections/sectionGridCallouts.fragment'
import { cn } from '@ui/lib/utils'

import { captureFeatureInteraction, useExperimentFeatureFlag } from 'src/hooks/posthog/useExperimentFeatureFlag'
import { TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import MisterMedia from '../media/MisterMedia'
import MisterSiteLink from '../site-link/MisterSiteLink'

interface GridCalloutProps extends SectionGridCalloutsData {
  className?: string
  style?: CSSProperties
}

const GridCallout: FunctionComponent<GridCalloutProps> = ({ _id, image, text, button, columnSpan, className, style }) => {
  const hasImage = image?.mobile?.url || image?.desktop?.url

  const content = (
    <>
      <div className='relative h-full w-full'>
        {hasImage ? <MisterMedia media={image} className='h-full w-full rounded-md object-cover' /> : <div className='h-full min-h-[284px] w-full bg-brand-grey' />}
      </div>
      <div className='absolute inset-0 flex flex-col justify-end gap-2 rounded-md bg-gradient-to-t from-black/70 to-transparent to-50% p-6 lg:gap-4 lg:p-10'>
        {text && <h4 className={cn('text-balance text-callout-title-sm text-white', columnSpan === 2 ? 'w-3/4 lg:text-callout-title-lg' : 'lg:text-callout-title-md')}>{text}</h4>}
        {button?.buttonText && <span className='-m-4 p-4 text-btn-lg text-white underline'>{button.buttonText}</span>}
      </div>
    </>
  )

  // A/B test easies grid callout cta destination
  // @see https://eu.posthog.com/project/12378/experiments/33300
  const postHog = usePostHog()
  const flag = 'easies-grid-callout'
  const variant: 'control' | 'easies-brand-page' | string | boolean | undefined = useExperimentFeatureFlag(flag, undefined, false)
  const overviewPcpEasies_id = '72543b7b-692e-4a62-bfaf-5ccf1f75e620'
  const regularPcpEasiesCampaign25_id = '33d14e33-c7e3-4ced-ab5d-0c25fe13d1cc'
  const isEasiesGridCallout = _id === overviewPcpEasies_id || _id === regularPcpEasiesCampaign25_id
  const easiesBrandPageLink: Link = {
    _type: 'link',
    page: {
      slug: {
        current: 'easies-25',
      },
    },
  }

  const handleClick = (clickDestination: string) => {
    trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_GRID_CALLOUT, { clickTitle: text, clickDestination })
    if (isEasiesGridCallout) {
      captureFeatureInteraction(flag, postHog)
    }
  }

  return (
    <li style={style} className={cn(className, 'w-full')}>
      {button?.link ? (
        <MisterSiteLink
          onClick={() => handleClick(button.link?.page?.slug?.current || '')}
          link={isEasiesGridCallout && variant === 'easies-brand-page' ? easiesBrandPageLink : button.link}
          className='flex w-full flex-col gap-2'
        >
          {content}
        </MisterSiteLink>
      ) : (
        <div className='flex h-full flex-col gap-2'>{content}</div>
      )}
    </li>
  )
}

export default GridCallout
