/**
 * Convert a string to camel case.
 *
 * @param str - The string to convert to camel case.
 * @returns The camel case string.
 */
export const toCamelCase = (str: string): string => {
  return str
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (_, c) => c.toUpperCase())
    .replace(/^[A-Z]/, (c) => c.toLowerCase())
}

/**
 * Convert a string to sentence case.
 *
 * @param str - The string to convert to sentence case.
 * @returns The sentence case string.
 */
export const toSentenceCase = (str: string): string => {
  if (!str) {
    return ''
  }

  return str
    .replace(/([A-Z]+)/g, (match) => ` ${match}`)
    .replace(/([A-Z][a-z])/g, (match) => ` ${match}`)
    .replace(/\s+/g, ' ')
    .replace(/\b\w/g, (c) => c.toUpperCase())
    .trim()
}

/**
 * Convert an array of strings to a sentence.
 *
 * @param arr - The array of strings to convert to a sentence.
 * @returns The sentence string.
 */
export const arrayToSentence = (arr: string[]) => {
  if (arr.length === 1) {
    return arr[0]
  }
  let last = arr.pop()
  return arr.join(', ') + ', and ' + last
}
