import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { SlugSchema } from '../../types'
import { coalesceQuery } from '../../utils'
import { GET_IMAGE_FRAGMENT, ImageSchema } from '../common/getImage.fragment'
import { LINK_FRAGMENT, LINK_FRAGMENT_WITH_SHOW_IN_LOCALES, LinkSchema, LinkWithShowInLocalesSchema } from './link.fragment'
import { MENU_CARD_FRAGMENT, MenuCardSchema } from './menuCard.fragment'

export const StyleMenuSchema = z.object({
  title: z.string(),
  slug: SlugSchema,
  collectionDescription: z.string(),
  new: z.boolean(),
  colors: z
    .array(
      z.object({
        colorId: z.string(),
        hex: z.string(),
        image: ImageSchema.optional(),
      }),
    )
    .optional(),
  styleImage: ImageSchema,
})

export const ProductCategorySchema = z.object({
  productCategoryMenuTitle: z.string(),
  productCategory: z.object({
    title: z.string(),
    slug: SlugSchema,
  }),
  productCategoryImage: ImageSchema,
  styles: z.array(StyleMenuSchema).optional(),
})

export const MainMenuItemSchema = z.object({
  _key: z.string(),
  _type: z.literal('mainMenuItem'),
  mainMenuCards: z.array(MenuCardSchema),
  mainMenuItemLink: LinkSchema,
  productCategories: z.array(ProductCategorySchema).optional(),
  productCategoriesV1: z.array(ProductCategorySchema).optional(),
  shopByTypeList: z
    .object({
      title: z.string(),
      links: z.array(
        z.union([
          LinkWithShowInLocalesSchema,
          z.object({
            _type: z.literal('collection'),
            title: z.string(),
            slug: SlugSchema,
          }),
        ]),
      ),
    })
    .optional(),
  shopBySeasonList: z
    .object({
      title: z.string(),
      links: z.array(
        z.union([
          LinkWithShowInLocalesSchema,
          z.object({
            _type: z.literal('collection'),
            title: z.string(),
            slug: SlugSchema,
          }),
        ]),
      ),
    })
    .optional(),
  featuredStyles: z.array(StyleMenuSchema).optional(),
})

export type StyleMenu = z.infer<typeof StyleMenuSchema>
export type ProductCategory = z.infer<typeof ProductCategorySchema>
export type MainMenuItem = z.infer<typeof MainMenuItemSchema>

export const MAIN_MENU_ITEM_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
    _key,
    _type,
    _type == 'reference' => @-> {
      ${LINK_FRAGMENT(locale)}
    },
    mainMenuCards[]-> {
      ${MENU_CARD_FRAGMENT(locale)}
    },
    mainMenuItemLink-> {
      ${LINK_FRAGMENT(locale)}
    },
    productCategories[] {
      ${coalesceQuery('productCategoryMenuTitle', locale)},
      productCategory-> {
        ${coalesceQuery('title', locale)},
        ${coalesceQuery('slug', locale)}
      },
      productCategoryImage {
        ${GET_IMAGE_FRAGMENT()}
      },
      ${coalesceQuery(
        'styles',
        locale,
        'styles',
        `
        [style->showInLocales[$locale] != false] {
          ...style-> {
            ${coalesceQuery('title', locale)},
            ${coalesceQuery('slug', locale)},
            ${coalesceQuery('collectionDescription', locale)},
            new,
            "colors": array::compact(products[@->showInLocales[$locale] != false]->color-> {
              "colorId": _id,
              defined(hexCode) => {
                "hex": hexCode.hex,
              },
              defined(image) => {
                "image": image->mobile {
                  ${GET_IMAGE_FRAGMENT()}
                },
              }
            })
          },
          styleImage {
            ${GET_IMAGE_FRAGMENT()}
          }
        }`,
      )}
    },
    productCategoriesV1[] {
      ${coalesceQuery('productCategoryMenuTitle', locale)},
      productCategory-> {
        ${coalesceQuery('title', locale)},
        ${coalesceQuery('slug', locale)}
      },
      productCategoryImage {
        ${GET_IMAGE_FRAGMENT()}
      },
      ${coalesceQuery(
        'styles',
        locale,
        'styles',
        `
        [style->showInLocales[$locale] != false] {
          ...style-> {
            ${coalesceQuery('title', locale)},
            ${coalesceQuery('slug', locale)},
            ${coalesceQuery('collectionDescription', locale)},
            new,
            "colors": array::compact(products[@->showInLocales[$locale] != false]->color-> {
              "colorId": _id,
              defined(hexCode) => {
                "hex": hexCode.hex,
              },
              defined(image) => {
                "image": image->mobile {
                  ${GET_IMAGE_FRAGMENT()}
                },
              }
            })
          },
          styleImage {
            ${GET_IMAGE_FRAGMENT()}
          }
        }`,
      )}
    },
    shopByTypeList {
      ${coalesceQuery('title', locale)},
      links[]-> {
        _type,
        _type == 'link' => {
          ${LINK_FRAGMENT_WITH_SHOW_IN_LOCALES(locale)}
        },
        _type == 'collection' && showInLocales[$locale] != false => {
          ${coalesceQuery('title', locale)},
          ${coalesceQuery('slug', locale)},
          "showInLocales": showInLocales[$locale]
        }
      }
    },
    shopBySeasonList {
      ${coalesceQuery('title', locale)},
      links[]-> {
        _type,
        _type == 'link' => {
          ${LINK_FRAGMENT_WITH_SHOW_IN_LOCALES(locale)}
        },
        _type == 'collection' => {
          ${coalesceQuery('title', locale)},
          ${coalesceQuery('slug', locale)},
          "showInLocales": showInLocales[$locale]
        }
      }
    },
    ${coalesceQuery(
      'featuredStyles[0]',
      locale,
      'featuredStyles',
      `
      [style->showInLocales[$locale] != false] {
        ...style-> {
          ${coalesceQuery('title', locale)},
          ${coalesceQuery('slug', locale)},
          ${coalesceQuery('collectionDescription', locale)},
          new,
          "showInLocales": showInLocales[$locale],
          "colors": array::compact(products[@->showInLocales[$locale] != false]->color-> {
            "colorId": _id,
            defined(hexCode) => {
              "hex": hexCode.hex,
            },
            defined(image) => {
              "image": image->mobile {
                ${GET_IMAGE_FRAGMENT()}
              },
            }
          })
        },
        styleImage {
          ${GET_IMAGE_FRAGMENT()}
        }
      }`,
    )}

  } {
    ...,
    productCategories[length(styles) > 0],
    productCategoriesV1[length(styles) > 0]
`)
