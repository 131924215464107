import { FunctionComponent } from 'react'
import { isSameDay } from 'date-fns'
import { Clock, MapPin, Store } from 'lucide-react'

import { StoreObject } from 'data-access/sanity/fragments/sections/sectionStoreIndex.fragment'
import { Button } from '@ui/components/ui/button'

import MisterMedia from 'src/components/shared/media/MisterMedia'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import { useTranslations } from 'src/contexts/Globals.context'
import useSpecialDays from 'src/hooks/utils/useSpecialDays'

interface StoreCardProps {
  store: StoreObject
  todaysOpeningHours: string
  viewStoreDetails: string
}

const StoreCard: FunctionComponent<StoreCardProps> = ({ store, todaysOpeningHours, viewStoreDetails }) => {
  const translate = useTranslations()
  const { today, dayOfTheWeek, upcomingSpecialDays } = useSpecialDays(store.store.defaultOpeningTimes, store.store.specialOpeningTimes)
  const todayIsASpecialDay = upcomingSpecialDays.some(({ date }) => isSameDay(date, today?.toISOString() ?? ''))
  const todaysOpeningTimes = todayIsASpecialDay
    ? upcomingSpecialDays.find(({ date }) => isSameDay(date, today?.toISOString() ?? ''))
    : store.store.defaultOpeningTimes[dayOfTheWeek || 'monday']

  const storeLink = {
    page: {
      slug: store.page.slug,
    },
  }

  return (
    <li className='flex flex-col border border-brand-grey'>
      <div className='aspect-video shrink-0 overflow-hidden md:aspect-[16/21]'>
        <MisterSiteLink link={storeLink}>
          <MisterMedia media={store.image} className='size-full object-cover' />
        </MisterSiteLink>
      </div>
      <div className='flex h-full flex-col justify-between gap-6 p-4 pb-7 pt-8'>
        <div className='flex flex-col gap-4'>
          <h3 className='text-heading-5'>{store.store.city}</h3>
          <div className='flex flex-col gap-3 text-body-lg md:text-body-md'>
            <div className='flex gap-2'>
              <Clock className='size-5 stroke-[1.5]' />
              <div className='flex gap-2'>
                {store.store.isOpen ? (
                  <>
                    <span>{todaysOpeningHours}</span>
                    {todaysOpeningTimes && (
                      <span className='shrink-0'>{todaysOpeningTimes.closed ? translate('Closed') : `${todaysOpeningTimes.open} - ${todaysOpeningTimes.close}`}</span>
                    )}
                  </>
                ) : (
                  <span>{translate('openingSoon', 'Opening soon')}</span>
                )}
              </div>
            </div>

            <div className='flex gap-2'>
              <MapPin className='size-5 stroke-[1.5]' />
              <a href={store.store.googleMapsLink} target='_blank' rel='noopener noreferrer' className='underline'>
                {store.store.street}, {store.store.postalCode}, {store.store.city}
              </a>
            </div>
          </div>
        </div>
        <MisterSiteLink link={storeLink}>
          <Button variant='outline' className='flex w-full gap-2'>
            <Store className='size-4 shrink-0' />
            <span>{viewStoreDetails}</span>
          </Button>
        </MisterSiteLink>
      </div>
    </li>
  )
}

export default StoreCard
