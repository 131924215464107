import { FunctionComponent } from 'react'

import { cn } from 'ui/lib/utils'

import { useTranslations } from '../../../contexts/Globals.context'
import OptionIcon from '../../shared/OptionIcon'

export interface Option {
  name: string
  values: string[]
}

interface AvailableOption {
  [key: string]: string | undefined
  Length?: string
  Waist?: string
  Size?: string
}

interface OptionsSelectProps {
  id: string | number
  selectedOptions?: Record<string, any>
  options?: Option[]
  availableOptions: AvailableOption[]
  liveAvailableOptions: AvailableOption[] | undefined
  productQuantity?: number
  availableInventories?: number[]
  stockWarningThreshold?: number
  isGiftCard?: boolean
  isCollectionCard?: boolean
  onChange: (value: { [x: string]: string }) => void
}

const OptionsSelect: FunctionComponent<OptionsSelectProps> = ({
  id: identifier,
  selectedOptions,
  options,
  availableOptions,
  liveAvailableOptions,
  productQuantity,
  availableInventories,
  stockWarningThreshold,
  isGiftCard,
  isCollectionCard,
  onChange,
}) => {
  const translate = useTranslations()
  const showOptionLabel = options ? options?.length > 1 : false

  return (
    <div className={cn('grid grid-cols-[auto_1fr] gap-2', isCollectionCard ? 'mb-5 py-1' : 'mb-3')}>
      {options?.map(({ name, values: optionValues }, sizeRowIndex) => {
        const { [name]: remove, ...otherSelectedOption } = selectedOptions || {}
        const optionSelectSize: 'micro' | 'small' | 'regular' =
          !showOptionLabel && !isCollectionCard && optionValues.length <= 8 ? 'regular' : optionValues.length >= 10 ? 'micro' : 'small'

        // todo: cache in Vercel KV/Redis, https://linear.app/mr-marvis/issue/ECOMM-1509/cache-shopify-queries-in-vercel-redis
        const isAvailable = (value: string | undefined) => {
          // This checks against live Shopify data first and then Sanity data
          return (liveAvailableOptions || availableOptions)
            .filter((option) => Object.keys(otherSelectedOption).length === 0 || Object.entries(otherSelectedOption).every(([key, v]) => option[key] === v))
            .find((option) => option[name] === value)
        }

        return (
          <fieldset key={`${identifier}-${name}-options`} className='contents'>
            <div className='col-span-2 grid grid-cols-subgrid'>
              {showOptionLabel && (
                <div className='flex w-min select-none items-center justify-center gap-1'>
                  <OptionIcon aria-hidden className='size-6 lg:size-7' option={name} />
                  <span className='text-nowrap text-body-sm capitalize'>{translate(`option${name.replace(' ', '')}`, name)}</span>
                </div>
              )}

              <div
                className={cn(
                  'text-btn-sm font-semibold',
                  showOptionLabel
                    ? 'flex flex-wrap justify-start gap-1'
                    : isCollectionCard && optionValues.length > 6
                      ? `grid grid-cols-7 gap-y-4`
                      : 'col-span-2 flex justify-between',
                )}
              >
                {optionValues
                  .map((optionValue) => [`${identifier}-${name}-${optionValue}-option-value`.toLowerCase(), optionValue])
                  .map(([id, optionValue], sizeColIndex) => {
                    const isSingleSelector = availableInventories?.length == optionValues?.length
                    const inventory = isSingleSelector ? availableInventories[sizeColIndex] : productQuantity

                    return (
                      <div className='relative flex flex-col items-center' data-testid='size-select' key={id}>
                        <input
                          id={id}
                          name={name}
                          onChange={(e) => {
                            if (onChange) {
                              onChange({ ...selectedOptions, [name]: e.target.value })
                            }
                          }}
                          type='radio'
                          value={optionValue}
                          checked={selectedOptions?.[name] === optionValue}
                          className='peer hidden'
                        />
                        <label
                          role='button'
                          htmlFor={id}
                          className={cn(
                            'flex items-center justify-center whitespace-nowrap rounded-sm text-center text-body-sm tracking-tight transition-colors duration-100 hover:bg-brand-beige active:ring-1 active:ring-brand-blue-action peer-checked:bg-brand-blue-light peer-disabled:cursor-default peer-disabled:text-brand-blue-dark-disabled',
                            optionSelectSize === 'regular' && (String(optionValue).length < 8 ? 'size-10' : 'h-10 w-auto px-2'),
                            optionSelectSize === 'small' && (String(optionValue).length < 6 ? 'size-8' : 'h-8 w-10'),
                            optionSelectSize === 'micro' && 'size-6 sm:size-8 xl:size-7',
                            { 'text-brand-muted peer-checked:bg-brand-muted peer-checked:text-white': !isAvailable(optionValue) && !isGiftCard },
                            isCollectionCard && !isAvailable(optionValue) && 'pointer-events-none',
                          )}
                        >
                          {optionValue}
                        </label>
                        {!isGiftCard &&
                          options.filter((i) => i).length === sizeRowIndex + 1 &&
                          !!inventory &&
                          inventory <= (stockWarningThreshold ? stockWarningThreshold : 5) &&
                          inventory > 0 && (
                            <div
                              className={cn(
                                'absolute top-full text-nowrap pt-1 text-center text-body-xs opacity-0 peer-checked:opacity-100',
                                isSingleSelector && 'peer-hover:opacity-100',
                              )}
                            >
                              {translate('onlyQuantityLeftText', 'Only {quantity} left').replace('{quantity}', `${inventory}`)}
                            </div>
                          )}
                      </div>
                    )
                  })}
              </div>
            </div>
          </fieldset>
        )
      })}
    </div>
  )
}

export default OptionsSelect
