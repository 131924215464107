import { FunctionComponent, useContext, useEffect, useState } from 'react'
import Fuse from 'fuse.js'
import { useSearchParams } from 'next/navigation'

import { SectionCareData, Style } from 'data-access/sanity/fragments/sections/sectionCare.fragment'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@ui/components/ui/accordion'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@ui/components/ui/tabs'
import { cn } from '@ui/lib/utils'
import { sort } from 'utilities/array/sortArray'

import { ProductType } from '@/types/collection'

import { useGlobalTopMargin } from 'src/components/globalState/MisterScrollMargin'
import MisterCareSet from 'src/components/shared/care/MisterCareSet'
import MisterInputSearchBar from 'src/components/shared/input/MisterInputSearchBar'
import { GlobalsContext, useTranslations } from '../../../contexts/Globals.context'

interface Props {
  data: SectionCareData
}

interface FilteredStyle extends Style {
  isFiltered: boolean
}

interface Category {
  categoryTitle: string
  styles: FilteredStyle[]
}

const productTypeOrder: ProductType[] = ['trousers', 'shorts', 'tops', 'shoes', 'other']

const SectionCare: FunctionComponent<Props> = ({ data }) => {
  const translate = useTranslations()
  const { stylesOrder } = useContext(GlobalsContext)
  const [activeTab, setActiveTab] = useState('tab0')
  const [activeAccordion, setActiveAccordion] = useState<string | undefined>(undefined)
  const [showAllAccordions, setShowAllAccordions] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')
  const searchParams = useSearchParams()
  const collectionParam = searchParams.get('collection')
  const [{ scrollTopMargin }] = useGlobalTopMargin()
  const [filteredCategories, setFilteredCategories] = useState<Category[]>(() => {
    const grouped = sort(data?.carePageData?.styles, stylesOrder.styles || []).reduce((acc, style) => {
      const existingCategory = acc.find((category) => category.categoryTitle === style.productType)

      if (!existingCategory) {
        acc.push({
          categoryTitle: style.productType,
          styles: [{ ...style, isFiltered: false }],
        })
      } else {
        existingCategory.styles.push({ ...style, isFiltered: false })
      }

      return acc
    }, [] as Category[])

    // Sort the grouped categories by the predefined product type order
    const sortedGrouped = grouped.sort((a, b) => {
      const aIndex = productTypeOrder.indexOf(a.categoryTitle as ProductType)
      const bIndex = productTypeOrder.indexOf(b.categoryTitle as ProductType)

      // If the product type is not found in the order, place it last
      return (aIndex === -1 ? Infinity : aIndex) - (bIndex === -1 ? Infinity : bIndex)
    })

    return sortedGrouped
  })

  const [filteredStyles, setFilteredStyles] = useState<FilteredStyle[]>([])

  const handleTabChange = (value: string) => {
    setActiveTab(value)
    setActiveAccordion(undefined)
  }

  const handleAccordionChange = (value: string) => {
    setActiveAccordion(value)
  }

  const handleSearchChange = (value: string, hideTabs = false) => {
    setSearchQuery(value)
    filterStyles(value)
    setActiveTab(hideTabs && value ? 'undefined' : 'tab0')
  }

  const filterStyles = (searchValue: string) => {
    setShowAllAccordions(false)

    setFilteredCategories((prevFilteredCategories) => {
      const updatedFilteredCategories = prevFilteredCategories.map((category) => {
        const fuse = new Fuse(category.styles, {
          keys: ['title'],
          threshold: 0.3, // Adjust this to control the strictness (0 is exact match, 1 allows everything)
        })

        return {
          ...category,
          styles: category.styles?.map((style) => {
            const matches = fuse.search(searchValue)
            const matchedId = style._id === searchValue

            if (matchedId) {
              setSearchQuery(style.title)
            }

            const isFiltered = matches.some((result) => result.item.title === style.title) || matchedId

            return { ...style, isFiltered }
          }),
        }
      })

      setFilteredStyles(() => {
        const updatedFilteredStyles = updatedFilteredCategories.flatMap((category) => category.styles.filter((style) => style.isFiltered))
        setActiveAccordion(updatedFilteredStyles.length === 1 ? `accordion${updatedFilteredStyles[0]?._id}` : undefined)
        return updatedFilteredStyles
      })

      return updatedFilteredCategories
    })
  }

  useEffect(() => {
    if (collectionParam) {
      const collectionId = decodeURI(collectionParam.split('?')[0] || collectionParam)

      filterStyles(collectionId)
      setActiveTab('undefined')

      const targetSection = document.querySelector('#care')
      setTimeout(() => {
        if (targetSection) {
          targetSection.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      }, 300) // wait for accordion to open
    }
  }, [collectionParam])

  const StyleList = (styles: FilteredStyle[]) => {
    return styles.map((style, i) => (
      <AccordionItem
        className={cn(searchQuery && !style.isFiltered && !showAllAccordions && 'opacity-30 hover:opacity-90', activeAccordion === `accordion${style._id}` && 'opacity-100')}
        value={`accordion${style._id}`}
        key={i}
      >
        <AccordionTrigger>{style.title}</AccordionTrigger>
        {style.care && (
          <AccordionContent>
            <MisterCareSet data={style.care} className='py-4' />
          </AccordionContent>
        )}
        {style?.products?.length > 0 && (
          <AccordionContent variant='nested'>
            <Accordion type='single' collapsible>
              {style.products?.map((product, y) => (
                <AccordionItem value={`accordion${product._id}`} key={'acc-' + y}>
                  <AccordionTrigger>{product.title.includes('*') ? product.title.split('*')?.[0] : product.title}</AccordionTrigger>
                  <AccordionContent>
                    <MisterCareSet data={product.care} className='py-4' />
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </AccordionContent>
        )}
      </AccordionItem>
    ))
  }

  return (
    <div className='w-full pb-10' id='care' style={{ scrollMarginTop: `${scrollTopMargin ? scrollTopMargin + 28 : 100}px` }}>
      <MisterInputSearchBar
        className='mb-9'
        placeholderText={translate('searchProductName', 'Search by product')}
        value={searchQuery}
        onChangeHandler={(e) => handleSearchChange(e.target.value, true)}
        onClear={() => handleSearchChange('')}
      />
      <Tabs value={activeTab} onValueChange={handleTabChange}>
        <TabsList className='mb-4 overflow-x-scroll scrollbar-hide'>
          {filteredCategories.map((category, i) => {
            const hasFilteredStyles = category.styles?.some((style) => style.isFiltered)
            return (
              <TabsTrigger key={i} value={`tab${i}`}>
                <div className='relative capitalize'>
                  <span>{translate(category.categoryTitle, category.categoryTitle)}</span>
                  {hasFilteredStyles && searchQuery && (
                    <span className='absolute top-0 h-full translate-x-1 translate-y-[1px] content-center text-body-sm opacity-60'>
                      ({category.styles?.filter((style) => style.isFiltered).length})
                    </span>
                  )}
                </div>
              </TabsTrigger>
            )
          })}
        </TabsList>
        {!filteredCategories.some((category) => category.styles?.some((style) => style.isFiltered)) && searchQuery !== '' ? (
          <div className='flex justify-center py-6'>
            <span className='text-heading-5 font-bold'>{translate('noResults', 'No results')}</span>
          </div>
        ) : activeTab === 'undefined' ? (
          <Accordion type='single' collapsible onValueChange={(value) => handleAccordionChange(value)} value={activeAccordion}>
            {StyleList(filteredStyles)}
          </Accordion>
        ) : (
          filteredCategories.map((category, i) => {
            const filteredStyles = category.styles?.filter((style) => style.isFiltered)
            const unfilteredStyles = category.styles?.filter((style) => !style.isFiltered)

            return (
              <TabsContent key={i} value={`tab${i}`} className='flex flex-col gap-2'>
                <Accordion type='single' collapsible onValueChange={(value) => handleAccordionChange(value)} value={activeAccordion}>
                  {StyleList([...(filteredStyles ?? []), ...(unfilteredStyles ?? [])])}
                </Accordion>
              </TabsContent>
            )
          })
        )}
      </Tabs>
    </div>
  )
}

export default SectionCare
