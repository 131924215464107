import { FC, useCallback, useMemo } from 'react'
import { useRefinementList } from 'react-instantsearch'

import { cn } from '@ui/lib/utils'

import { TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import { useTranslations } from '../../../../contexts/Globals.context'
import ColorRenderer from './renderers/ColorRenderer'
import DefaultRenderer from './renderers/DefaultRenderer'
import PriceRenderer from './renderers/PriceRenderer'
import SizeRenderer from './renderers/SizeRenderer'

export type RendererProps = {
  attribute: string
  label: string
  value: string
  isRefined: boolean
  count?: number
  refine: (value: string) => void
}

const renderers: Record<string, FC<RendererProps>> = {
  sizes: SizeRenderer,
  lengthSizes: SizeRenderer,
  waistSizes: SizeRenderer,
  colors: ColorRenderer,
  price: PriceRenderer,
}

type SearchFilterGroupProps = {
  attribute: string
  operator: 'and' | 'or'
  categoryName: string
  categoryTitle: string
  className?: string
}

const NON_DEFAULT_FILTERS = ['colors', 'sizes', 'lengthSizes', 'waistSizes', 'price']
const SIZE_ATTRIBUTES = ['sizes', 'lengthSizes', 'waistSizes']

const SearchFilterGroup: FC<SearchFilterGroupProps> = ({ attribute, operator, categoryName, categoryTitle, className }) => {
  const translate = useTranslations()

  // @see https://www.algolia.com/doc/api-reference/widgets/refinement-list/react/
  const { items, refine, isShowingMore, canToggleShowMore, toggleShowMore, canRefine } = useRefinementList({
    attribute,
    operator,
    showMore: !NON_DEFAULT_FILTERS.includes(attribute as any),
    limit: NON_DEFAULT_FILTERS.includes(attribute as any) ? 20 : 5,
    showMoreLimit: NON_DEFAULT_FILTERS.includes(attribute as any) ? undefined : 100,
  })

  const handleRefine = useCallback(
    (value: string) => {
      trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_FILTER, {
        filterGroup: attribute,
        filterValue: value,
      })
      refine(value)
    },
    [attribute, refine],
  )

  const handleToggleShowMore = useCallback(() => toggleShowMore(), [toggleShowMore])

  const FilterGroupRenderer = useMemo(() => renderers[attribute] || DefaultRenderer, [attribute])
  const flexClassnames = useMemo(() => (NON_DEFAULT_FILTERS.includes(attribute as any) ? 'flex-row gap-2 flex-wrap' : 'flex-col gap-[22px]'), [attribute])
  const hasRefinedItems = useMemo(() => items.some((item) => item.isRefined), [items])

  // Early return if there are no items to display, refinement
  // is not possible, or there's only one non-refined item.
  if (items.length === 0 || !canRefine || (items.length <= 1 && !hasRefinedItems)) {
    return null
  }

  const Tag = SIZE_ATTRIBUTES.includes(attribute) ? 'ol' : 'ul'

  return (
    <div className={cn(className, 'flex flex-col gap-6')}>
      <h3 className='text-body-lg-bold'>{categoryTitle}</h3>
      <Tag className={cn(flexClassnames, 'flex')}>
        {attribute === 'price' ? (
          <PriceRenderer attribute={attribute} />
        ) : (
          items.map(({ label, value, isRefined, count }) => (
            <li data-insights-filter={`${attribute}:${value}`} key={`filter-${attribute}-${value}`} className='flex items-center space-x-2'>
              <FilterGroupRenderer attribute={attribute} label={label} value={value} isRefined={isRefined} refine={handleRefine} count={count} />
            </li>
          ))
        )}
        {canToggleShowMore && (
          <li onClick={handleToggleShowMore} className='text-body-1 cursor-pointer underline'>
            {isShowingMore ? translate('showLess', 'Show less') : translate('showMore', 'Show more')}
          </li>
        )}
      </Tag>
    </div>
  )
}

export default SearchFilterGroup
