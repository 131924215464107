/**
 * Sort an array of objects based on the order of the keys in the order array.
 * If the key is not in the order array, it will be placed at the end.
 *
 * @param array - The array of objects to sort.
 * @param order - The order of the keys to sort by.
 * @param key - The key to sort by.
 * @returns The sorted array.
 */
export const sort = <T = object>(array: T[], order: string[], key = '_id') =>
  array.sort((a, b) => {
    const getIndexOf = (item: any) => (item[key] && order.includes(item[key]) ? order.indexOf(item[key]) : Number.MAX_SAFE_INTEGER)
    return getIndexOf(a) - getIndexOf(b)
  })

/**
 * Sort an array of objects based on the order of the keys in the order array.
 * If the key is not in the order array, it will be placed at the end.
 *
 * @param styleColors - The array of objects to sort.
 * @param colorOrder - The order of the keys to sort by.
 * @returns The sorted array.
 */
export const sortColors = (
  styleColors: {
    colorId: string | undefined
    [key: string]: any
  }[],
  colorOrder: string[],
): {
  colorId: string | undefined
  [key: string]: any
}[] =>
  styleColors.sort(({ colorId: a }, { colorId: b }) => {
    // If a or b is undefined, we want to sort it to the end
    if (a === undefined && b === undefined) return 0 // Both are undefined, keep order
    if (a === undefined) return 1 // a is undefined, b comes first
    if (b === undefined) return -1 // b is undefined, a comes first

    const indexA = colorOrder.indexOf(a)
    const indexB = colorOrder.indexOf(b)

    // If a or b is not found in colorOrder, place it at the end
    if (indexA === -1 && indexB === -1) return 0 // Both not found, keep order
    if (indexA === -1) return 1 // a not found, b comes first
    if (indexB === -1) return -1 // b not found, a comes first

    return indexA - indexB // Sort based on the order
  })
