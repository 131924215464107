import { FunctionComponent, useState } from 'react'
import { ChevronDown, ChevronUp, LoaderCircle } from 'lucide-react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { ProductCardProduct, ShopifyVariantInSanity } from 'data-access/sanity/fragments/components/productCardProduct.fragment'
import { ShopifyProductAvailability } from 'data-access/shopify/types'
import { Button } from '@ui/components/ui/button'
import { Separator } from '@ui/components/ui/separator'
import { cn } from '@ui/lib/utils'
import { CombinedOptions } from 'utilities/array/combineOptions'
import { getPriceValidUntil } from 'utilities/date/format/formatPriceValidUntil'
import { formatPrice, localeCurrencyMap } from 'utilities/string/format/price'

import OptionsSelect from 'src/components/pages/products/OptionsSelect'
import CollapseContainer from 'src/components/shared/CollapseContainer'
import MisterImage from 'src/components/shared/image/MisterImage'
import MisterIcon from 'src/components/shared/MisterIcon'
import { useTranslations } from 'src/contexts/Globals.context'
import useProductForm from 'src/hooks/shop/useProductForm2'
import useProductsAvailability from 'src/hooks/shop/useProductsAvailability'
import { productTitle as productTitleHandler } from 'src/utils/product.util'

interface QuickAddToCartProps {
  variants: ShopifyVariantInSanity[]
  selectedVariant: ShopifyVariantInSanity | null
  setSelectedVariant: (variant: ShopifyVariantInSanity) => void
  atcOpen?: boolean
  product: ProductCardProduct
  options: CombinedOptions
  availableOptions: Record<string, string>[]
  liveAvailableOptions: Record<string, string>[] | undefined
  liveProductData: ShopifyProductAvailability | undefined
}

const QuickAddToCart: FunctionComponent<QuickAddToCartProps> = ({
  variants,
  selectedVariant,
  setSelectedVariant,
  atcOpen,
  product,
  options,
  availableOptions,
  liveAvailableOptions,
  liveProductData,
}) => {
  const translate = useTranslations()
  const [selectedOptions, setSelectedOptions] = useState(variants.length === 1 ? availableOptions[0] : {})

  return (
    <form>
      <div className='flex flex-col py-5'>
        <fieldset className='space-y-2'>
          <label className='text-body-sm-bold'>{translate('chooseSize', 'Choose a size')}</label>
          <div className='max-h-48 min-h-40 w-full overflow-scroll pr-2 scrollbar-hide'>
            <div className='relative h-full pb-4'>
              {product.primaryCollection?.productType === 'trousers' ? (
                <OptionsSelect
                  id={product._id}
                  options={options}
                  availableOptions={availableOptions}
                  liveAvailableOptions={liveAvailableOptions}
                  productQuantity={selectedVariant?.inventory?.quantity}
                  availableInventories={liveProductData?.variants?.edges?.map(({ node }) => node?.quantityAvailable)}
                  stockWarningThreshold={5}
                  isGiftCard={false}
                  isCollectionCard={true}
                  onChange={(v) => {
                    const variant = variants.find(({ options: s }) => s?.every(({ name, value }) => v?.[name] === value))
                    if (variant) {
                      setSelectedVariant(variant)
                    }
                    setSelectedOptions(v)
                  }}
                  selectedOptions={selectedOptions}
                />
              ) : (
                variants.map((variant) => {
                  const title = variant.title || variant.options.map((option) => option.value).join(' / ')
                  return (
                    <div
                      key={variant.shopifyVariantId}
                      className={cn(
                        'flex items-center overflow-hidden rounded-md px-2 has-[:checked]:bg-brand-beige-light',
                        !variant.isAvailable ? '' : 'hover:bg-brand-beige-light',
                      )}
                      data-testid='size-select'
                    >
                      <input
                        onChange={() => setSelectedVariant(variant)}
                        className='peer hidden'
                        checked={selectedVariant?.shopifyVariantId === variant.shopifyVariantId}
                        disabled={!variant.isAvailable}
                        type='radio'
                        value={variant.shopifyVariantId}
                        id={`${selectedVariant?.sku}${variant.shopifyVariantId}`}
                      />
                      <label
                        className='m-0 w-full cursor-pointer py-2 text-body-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 peer-checked:font-bold peer-disabled:cursor-not-allowed peer-disabled:text-brand-grey-dark peer-disabled:opacity-50'
                        tabIndex={atcOpen ? 0 : -1}
                        htmlFor={`${selectedVariant?.sku}${variant.shopifyVariantId}`}
                      >
                        {title}
                      </label>
                      {variant.inventory.quantity <= 5 && variant.inventory.quantity > 0 && (
                        <span className='whitespace-nowrap text-body-sm'>
                          {translate('onlyQuantityLeftText', 'Only {quantity} left').replace('{quantity}', variant.inventory.quantity)}
                        </span>
                      )}
                    </div>
                  )
                })
              )}
              <div className='pointer-events-none absolute inset-0 @container'>
                <div className='br-gradient-from-t sticky inset-x-0 -top-[1px] mt-auto hidden h-5 animate-fade-at-start items-center justify-center bg-gradient-to-b from-white to-transparent [animation-timeline:scroll()] @h-[12rem]:flex'>
                  <ChevronUp className='hidden size-5 text-brand-grey-dark supports-[animation-timeline]:block' />
                </div>

                <div className='br-gradient-from-b sticky inset-x-0 top-[11rem] mt-auto hidden h-5 animate-fade-at-end items-center justify-center bg-gradient-to-t from-white to-transparent [animation-timeline:scroll()] @h-[12rem]:flex'>
                  <ChevronDown className='hidden size-5 text-brand-grey-dark supports-[animation-timeline]:block' />
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <Separator />
      </div>
    </form>
  )
}

interface MiniProductCardProps {
  product: ProductCardProduct
  setCurrentCard: (currentCard: number | null) => void
  currentCard: number | null
  id: number
}

const MiniProductCard: FunctionComponent<MiniProductCardProps> = ({ product, setCurrentCard, currentCard, id }) => {
  const { locale } = useRouter()
  const translate = useTranslations()
  const liveProductData = useProductsAvailability(product.shopifyProductId)
  const { variants, loadingAddToCart, handleAddToCart, selectedVariant, setSelectedVariant, options, availableOptions, liveAvailableOptions } = useProductForm({
    slug: product.slug.current,
    product,
    productMedia: product.images,
    liveProductData,
    onAddToCartComplete: () => setCurrentCard(null),
  })
  const isCurrentCard = currentCard === id

  const priceValidUntil = getPriceValidUntil()

  const handleQuickAddToCart = () => {
    if (isCurrentCard) {
      handleAddToCart()
    } else {
      setCurrentCard(id)
    }
  }

  const { title, category: subtitle } = productTitleHandler(product.productTitle, true)
  return (
    <div className='grid h-full grid-cols-[auto_1fr] gap-x-3'>
      <Link href={`/products/${product?.slug.current}`} className='col-span-2 col-start-1 row-span-2 row-start-1 grid grid-cols-subgrid grid-rows-subgrid'>
        <div className='col-start-1 row-span-2 row-start-1 flex w-24 justify-center rounded-md bg-brand-pck-bg'>
          <MisterImage className='w-20 object-cover' mobile={product.images[0]} mobileSizes='200px' desktopSizes='200px' />
        </div>
        <div className='row-start-1 flex w-full flex-col gap-1 py-2'>
          <div className='flex justify-between'>
            <span className='text-body-lg-bold'>{title}</span>
            <span className='text-body-lg-bold'>{formatPrice(product.price)}</span>
          </div>
          <span className='text-body-md'>{subtitle}</span>
        </div>
      </Link>

      <CollapseContainer collapse={!isCurrentCard} className='col-span-2 col-start-1 row-start-3 row-end-4'>
        <QuickAddToCart
          variants={variants}
          selectedVariant={selectedVariant}
          setSelectedVariant={setSelectedVariant}
          atcOpen={isCurrentCard}
          product={product}
          options={options}
          availableOptions={availableOptions}
          liveAvailableOptions={liveAvailableOptions}
          liveProductData={liveProductData}
        />
      </CollapseContainer>
      <Button
        className={cn(
          'z-10 col-start-1 col-end-3 row-start-2 row-end-5 self-end justify-self-end bg-brand-beige-light text-white transition-[width,background-color] duration-300',
          isCurrentCard ? 'w-full bg-brand-blue-action' : '',
        )}
        variant='ghost'
        size='icon'
        onClick={handleQuickAddToCart}
        disabled={isCurrentCard && !selectedVariant}
        data-testid='quick-add-to-cart'
        itemProp={isCurrentCard ? 'offers' : undefined}
        itemType={isCurrentCard ? 'https://schema.org/Offer' : undefined}
        itemScope={isCurrentCard ? true : undefined}
      >
        <div className={cn('inline-grid items-center transition-[grid-template-columns] duration-300 ease-out', isCurrentCard ? 'grid-cols-[auto,1fr]' : 'grid-cols-[auto,0fr]')}>
          {loadingAddToCart ? (
            <LoaderCircle className='size-4 animate-spin' />
          ) : (
            <MisterIcon type='cart' className={cn('size-6 transition-colors', !isCurrentCard && 'text-brand-blue')} />
          )}
          <span className={cn('min-w-0 text-white transition-[opacity] duration-300 ease-out', isCurrentCard ? 'opacity-1 ml-3' : 'ml-0 opacity-0')}>
            {isCurrentCard && (
              <>
                <meta itemProp='price' content={String(product.price)} />
                <meta itemProp='priceValidUntil' content={priceValidUntil} />
                <meta itemProp='itemCondition' content='https://schema.org/NewCondition' />
                <meta itemProp='priceCurrency' content={localeCurrencyMap?.[locale || 'en'] || 'EUR'} />
              </>
            )}
            {selectedVariant ? translate('addToCartButtonText', 'Add to Cart') : translate('chooseSize', 'Choose a size')}
          </span>
        </div>
      </Button>
      <CollapseContainer collapse={!isCurrentCard} className='col-span-2 col-start-1 row-start-4 self-end'>
        <div className='h-10' />
      </CollapseContainer>
      <CollapseContainer collapse={!isCurrentCard} className={cn('col-span-2 col-start-1 row-start-5 self-end transition-all duration-150', !isCurrentCard && 'delay-150')}>
        <Button
          variant='outline'
          size='md'
          onClick={() => setCurrentCard(null)}
          className={cn('flex w-full transition-[margin,opacity] duration-300', !isCurrentCard ? '-mt-11 opacity-0' : 'mt-2')}
        >
          {translate('close')}
        </Button>
      </CollapseContainer>
    </div>
  )
}

export default MiniProductCard
