import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceFilter, coalesceQuery } from '../../utils'
import { MEDIA_FRAGMENT, MediaSchema } from '../components/media.fragment'

export const SectionOverviewCardDataSchema = z.object({
  _id: z.string(),
  showInLocale: z.boolean(),
  isPrimary: z.boolean(),
  isNew: z.boolean(),
  title: z.string(),
  slug: z.string(),
  description: z.string().optional(),
  bulletImage: MediaSchema.optional(), // Used in Pills
  overviewImage: MediaSchema.optional(), // Used in Overview Cards
})

export const SectionOverviewCardsCollectionDataSchema = z.object({
  title: z.string(),
  slug: z.string(),
  data: z.array(SectionOverviewCardDataSchema),
})

export const SectionOverviewCardsSchema = z.object({
  _id: z.string(),
  _type: z.literal('sectionOverviewCards'),
  isAutomated: z.boolean(),
  isPrimaryCollectionPage: z.boolean(), // To determine whether we show overview cards or pills
  stylesOrder: z.array(z.string()),
  collections: z.array(SectionOverviewCardsCollectionDataSchema),
})

export type SectionOverviewCardData = z.infer<typeof SectionOverviewCardDataSchema>
export type SectionOverviewCardsCollectionData = z.infer<typeof SectionOverviewCardsCollectionDataSchema>
export type SectionOverviewCardsData = z.infer<typeof SectionOverviewCardsSchema>

const commonCollectionFields = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _id,
  isPrimary,
  'showInLocale': showInLocales.${locale},
  "isNew": new,
  ${coalesceQuery('title', locale)},
  ${coalesceQuery('slug', locale, 'slug', '.current')},
  ${coalesceQuery('longDescription', locale, 'description')},
  "overviewImage": select(
    defined(collectionCardImage) => collectionCardImage-> {
      ${MEDIA_FRAGMENT()}
    },
    defined(collectionSliderImage) => collectionSliderImage-> {
      ${MEDIA_FRAGMENT()}
    },
    bulletImage-> {
      ${MEDIA_FRAGMENT()}
    }
  ),
  "bulletImage": bulletImage-> {
    ${MEDIA_FRAGMENT()}
  },
`)

export const SECTION_OVERVIEW_CARDS_FRAGMENT = (locale = DEFAULT_LOCALE, slug?: string): string =>
  defineQuery(`
  _type == 'sectionOverviewCards' => {
    _id,
    _type,
    "stylesOrder": *[_type == 'stylesOrder'][0].order[]->._id,
    isAutomated,
    ...*[_type == 'collection' && ${coalesceFilter('slug', locale)}.current == "${slug}"][0] {
      "isPrimaryCollectionPage": isPrimary,
      "relatedCollections": select(
        isPrimary == true => parentCollection[]-> {
          ${coalesceQuery('title', locale)},
          ${coalesceQuery('slug', locale)}.current,
          "data": *[_type == 'collection' && isPrimary == true && showInLocales[$locale] != false && references(^.products[]->._id)][0...20] | order(_id asc) {
            ${commonCollectionFields(locale)}
          }
        },
        {
          ${coalesceQuery('title', locale)},
          ${coalesceQuery('slug', locale)}.current,
          "data": *[_type == 'collection' && isPrimary == true && showInLocales[$locale] != false && references(^.products[]->._id)][0...20] | order(_id asc) {
            ${commonCollectionFields(locale)}
          }
        }
      ),
    "manualCollections": [{
      ...parentCollection[0]-> {
        ${coalesceQuery('title', locale)},
        ${coalesceQuery('slug', locale)}.current,
      },
      "data": ^.manualCollections[]-> {
        ${commonCollectionFields(locale)}
        }
      }]
    }
  } {
    _id,
    _type,
    stylesOrder,
    isAutomated,
    isPrimaryCollectionPage,
    "collections": select(
      isAutomated == false => manualCollections,
      relatedCollections
    )
  }
`)
