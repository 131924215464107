/**
 * Default paths for the URL builder
 */
export const defaultPaths = {
  product: 'products',
  collection: 'collections',
  pageArticle: 'blog',
  page: '',
  faqQuestion: 'faq',
}
