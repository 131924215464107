'use client'

import * as React from 'react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { cva } from 'class-variance-authority'
import { ChevronDown } from 'lucide-react'

import { cn } from '@ui/lib/utils'

const accordionTriggerVariants = cva('flex flex-1 items-center justify-between py-4 text-start outline-none transition-all', {
  variants: {
    variant: {
      default: 'text-lead-s font-semibold [&[data-state=open]>div>div:first-child]:rotate-180 [&[data-state=open]>div>div:first-child]:opacity-0',
      nested: 'text-body-md [&[data-state=open]]:font-semibold',
      chevron: 'text-btn-xl font-semibold',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})
const accordionContentVariants = cva('pb-5 pt-0 text-body-md transition-all duration-700 [&_p]:m-0', {
  variants: {
    variant: {
      default: '',
      nested: '[&>div>div[data-state=open]]:bg-brand-beige-light [&>div>div]:transition-all [&>div]:divide-y [&>div]:divide-solid [&>div]:divide-brand-beige-light',
      chevron: '',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})
const accordionItemVariants = cva('[&>h3]:transition-all [&>h3]:duration-300', {
  variants: {
    variant: {
      default: 'border-t border-brand-blue-light first:border-t-0',
      nested: 'px-4 [&[data-state=open]>h3]:border-opacity-0 [[data-state=open]_+_&>h3]:border-opacity-0',
      chevron: '',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

type AccordionVariant = 'default' | 'nested' | 'chevron'

const AccordionVariantContext = React.createContext<AccordionVariant>('default')

const Accordion = AccordionPrimitive.Root

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item> & { variant?: AccordionVariant }
>(({ className, variant: variantProp, ...props }, ref) => {
  const contextVariant = React.useContext(AccordionVariantContext)
  const variant = variantProp || contextVariant
  return <AccordionPrimitive.Item ref={ref} className={cn(accordionItemVariants({ variant, className }))} {...props} />
})
AccordionItem.displayName = 'AccordionItem'

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & { variant?: AccordionVariant }
>(({ className, children, variant: variantProp, ...props }, ref) => {
  const contextVariant = React.useContext(AccordionVariantContext)
  const variant = variantProp || contextVariant

  return (
    <AccordionPrimitive.Header className='flex'>
      <AccordionPrimitive.Trigger
        ref={ref}
        className={cn(accordionTriggerVariants({ variant, className }), variant === 'chevron' && '[&[data-state=open]>svg]:rotate-180')}
        {...props}
      >
        {children}
        {variant === 'default' && (
          <div className={cn('relative')}>
            <div className='absolute right-0 w-3 translate-x-[0.25px] rotate-90 border-t-[1.5px] border-brand-blue transition-all duration-300' />
            <div className={cn('absolute right-0 w-3 border-t-[1.5px] border-brand-blue')} />
          </div>
        )}

        {variant === 'chevron' && <ChevronDown className='h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200' />}
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  )
})
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content> & { variant?: AccordionVariant }
>(({ variant, className, children, ...props }, ref) => (
  <AccordionPrimitive.Content ref={ref} className={cn('overflow-hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down')} {...props}>
    <AccordionVariantContext.Provider value={variant || 'default'}>
      <div className={cn(accordionContentVariants({ variant, className }))}>{children}</div>
    </AccordionVariantContext.Provider>
  </AccordionPrimitive.Content>
))
AccordionContent.displayName = AccordionPrimitive.Content.displayName

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent }
