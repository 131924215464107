import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { GET_IMAGE_FRAGMENT, ImageSchema } from '../common/getImage.fragment'

export const MediaSchema = z.object({
  _type: z.literal('media'),
  _id: z.string(),
  type: z.union([z.literal('image'), z.literal('remoteVideo')]),
  alt: z.string(),
  desktop: ImageSchema.optional(),
  mobile: ImageSchema.optional(),
  videoPoster: ImageSchema.optional(),
  videoPosterDesktop: ImageSchema.optional(),
  remoteVideoUrl: z.string().optional(),
  remoteVideoUrlDesktop: z.string().optional(),
  videoAutoplay: z.boolean(),
  videoControls: z.boolean(),
})

export type Media = z.infer<typeof MediaSchema>

export const MEDIA_FRAGMENT = () =>
  defineQuery(`
  _type,
  _id,
  type,
  alt,
  defined(desktop) => {
    desktop {
      ${GET_IMAGE_FRAGMENT()}
    }
  },
  defined(mobile) => {
    mobile {
      ${GET_IMAGE_FRAGMENT()}
    }
  },
  defined(videoPoster) => {
    videoPoster {
      ${GET_IMAGE_FRAGMENT()}
    }
  },
  defined(videoPosterDesktop) => {
    videoPosterDesktop {
      ${GET_IMAGE_FRAGMENT()}
    }
  },
  type == "remoteVideo" && defined(remoteVideoUrl) => {
    remoteVideoUrl
  },
  type == "remoteVideo" && defined(remoteVideoUrlDesktop) => {
    remoteVideoUrlDesktop
  },
  'videoAutoplay': videoAutoplay != false,
  'videoControls': videoControls != false,
`)
