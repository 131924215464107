import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { CTA_FRAGMENT, CtaSchema } from '../components/cta.fragment'
import { MEDIA_FRAGMENT, MediaSchema } from '../components/media.fragment'

export const SectionBannerCtaSchema = z.object({
  _type: z.literal('sectionBannerCta'),
  _id: z.string(),
  name: z.string(),
  title: z.string(),
  titleCase: z.enum(['uppercase', 'capitalised']).optional(),
  titleFont: z.enum(['default', 'aw24', 'festive', 'easies']),
  hideTitle: z.boolean().optional(),
  subtitle: z.string().optional(),
  textShadowTitle: z.enum(['default', 'large']).optional(),
  textShadowSubtitle: z.enum(['default', 'large']).optional(),
  padding: z.boolean().optional(),
  copyPosition: z.enum(['left', 'middle', 'right']).optional(),
  copyPositionDesktop: z.enum(['left', 'middle', 'right']).optional(),
  copyPositionVertical: z.enum(['middle', 'bottom']).optional(),
  copyPositionVerticalDesktop: z.enum(['middle', 'bottom']).optional(),
  darkTitle: z
    .object({
      mobile: z.boolean(),
      desktop: z.boolean(),
    })
    .optional(),
  darkSubtitle: z
    .object({
      mobile: z.boolean(),
      desktop: z.boolean(),
    })
    .optional(),
  ctaToBottom: z.object({
    mobile: z.boolean(),
    desktop: z.boolean(),
  }),
  media: MediaSchema,
  cta: CtaSchema.optional(),
})

export type SectionBannerCtaData = z.infer<typeof SectionBannerCtaSchema>

export const SECTION_BANNER_CTA_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionBannerCta' => {
    _type,
    _id,
    name,
    ${coalesceQuery('title', locale)},
    defined(titleCase) => {titleCase},
    defined(titleFont) => {titleFont},
    defined(hideTitle) => {hideTitle},
    defined(subtitle) => {${coalesceQuery('subtitle', locale)}},
    defined(textShadowTitle) => {textShadowTitle},
    defined(textShadowSubtitle) => {textShadowSubtitle},
    defined(padding) => {padding},
    defined(copyPosition) => {copyPosition},
    defined(copyPositionDesktop) => {copyPositionDesktop},
    defined(copyPositionVertical) => {copyPositionVertical},
    defined(copyPositionVerticalDesktop) => {copyPositionVerticalDesktop},
    defined(ctaToBottom) => {ctaToBottom},
    defined(darkTitle) => {darkTitle},
    defined(darkSubtitle) => {darkSubtitle},
    defined(media) => {
      media-> {
        ${MEDIA_FRAGMENT()}
      },
    },
    defined(cta) => {
      cta-> {
        ${CTA_FRAGMENT(locale)}
      }
    },
  }
`)
