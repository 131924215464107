import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useHits, useInstantSearch } from 'react-instantsearch'
import { useCookie } from 'react-use'

import { AlgoliaProductHit } from 'data-access/algolia/types'
import { ProductCardProduct } from 'data-access/sanity/fragments/components/productCardProduct.fragment'
import { SectionGridCalloutsData } from 'data-access/sanity/fragments/sections/sectionGridCallouts.fragment'
import { cn } from '@ui/lib/utils'

import { useUI } from 'src/contexts/UI.context'
import ProductCard from 'src/features/product-card/ProductCard'
import useCart from 'src/hooks/shop/useCart'
import { useCookieConsentContext } from '../../../hooks/context/useCookieConsentContext'
import { defaultCookieConfig } from '../../../utils/cookie.util'
import MisterSearchProductCard from '../../shared/productCard/MisterSearchProductCard'
import GridCallouts from './GridCallouts'

type SearchResultsProps = {
  gridCallouts: SectionGridCalloutsData[]
  className?: string
  additionalProducts?: ProductCardProduct[]
}

const SearchResults: FunctionComponent<SearchResultsProps> = ({ gridCallouts, additionalProducts, className }) => {
  const { results } = useInstantSearch()
  const { currencyCode } = useCart()
  const { filtersActive } = useUI()
  const [numOfRenders, setNumOfRenders] = useState(0)
  const { items, sendEvent } = useHits<AlgoliaProductHit>()
  const { hasConsentedToCookie } = useCookieConsentContext()
  const [, setAlgoliaLastIndexName] = useCookie('algoliaLastIndexName')
  const [, setAlgoliaLastQueryId] = useCookie('algoliaLastQueryId')

  // Increment the number of renders whenever the query ID changes to help with the animation
  useEffect(() => {
    setNumOfRenders((prev) => prev + 1)
  }, [results.queryID])

  // Store in local storage for telemetry purposes later on in the user journey
  // @see https://www.algolia.com/doc/guides/sending-events/guides/queryid/#track-the-queryid-in-cookies-or-local-storage
  useEffect(() => {
    if (hasConsentedToCookie('statistics')) {
      if (results.index) {
        setAlgoliaLastIndexName(results.index, defaultCookieConfig)
      }

      if (results.queryID) {
        setAlgoliaLastQueryId(results.queryID, defaultCookieConfig)
      }
    }
  }, [hasConsentedToCookie, results, setAlgoliaLastIndexName, setAlgoliaLastQueryId])

  // Make sure to artificially animate the results after 2 renders to make the layout shift more smooth
  const shouldAnimate = useMemo(() => numOfRenders > 2, [numOfRenders])
  const hasGridCallOuts = useMemo(() => gridCallouts && gridCallouts.length > 0, [gridCallouts])

  return (
    <ol className={className}>
      {items.map((item) => (
        <MisterSearchProductCard
          key={`search-results-${item.objectID}-${results.queryID}`}
          hit={item}
          className={cn(
            'duration-300 animate-in fill-mode-forwards',
            shouldAnimate && 'zoom-in-90 group-data-[search-active=true]/grid:animate-out group-data-[search-active=true]/grid:zoom-out-90',
          )}
          onClick={() => {
            sendEvent('click', item, 'PCP Product Clicked')
          }}
          onAddToCart={() => {
            sendEvent('conversion', item, 'PCP Add to Cart', {
              eventSubtype: 'addToCart',
              currency: currencyCode,
              objectData: [
                {
                  quantity: 1,
                  price: item?.price || 0,
                  ...(results.queryID && { queryID: results.queryID }),
                },
              ],
            })
          }}
        />
      ))}
      {!filtersActive &&
        additionalProducts?.map((product, i) => (
          <ProductCard
            key={`search-results-${product._id}`}
            index={i + items.length}
            product={product}
            quickAddToCart={<ProductCard.QuickAddToCart />}
            wishList={<ProductCard.WishListButton />}
            image={<ProductCard.HoverImage imageSizes='(max-width: 1024px) 50vw, 28vw' />}
            badge={<ProductCard.Badge />}
            info={<ProductCard.Info />}
            action={<ProductCard.Link />}
            className={cn(
              'duration-300 animate-in fill-mode-forwards',
              shouldAnimate && 'zoom-in-90 group-data-[search-active=true]/grid:animate-out group-data-[search-active=true]/grid:zoom-out-90',
            )}
          />
        ))}

      {hasGridCallOuts && <GridCallouts gridCallouts={gridCallouts} items={items} queryID={results.queryID} />}
    </ol>
  )
}

export default SearchResults
