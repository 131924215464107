import { FunctionComponent, useEffect, useState } from 'react'
import { Info } from 'lucide-react'
import { useRouter } from 'next/router'

import { SupportedLocale } from 'data-access/domain/constants'

import { useTranslations } from '../../../contexts/Globals.context'
import { getTrustpilotUrl } from '../../../utils/reviews.util'
import MisterIcon from '../../shared/MisterIcon'
import MisterSiteLink from '../../shared/site-link/MisterSiteLink'

interface Props {
  reviewRating: number | null
  reviewCount: number | null
  reviewExplanationTooltip?: string
}

const MisterTrustpilotWidget: FunctionComponent<Props> = ({ reviewRating, reviewExplanationTooltip, reviewCount }) => {
  const { locale } = useRouter()
  const translate = useTranslations()
  const trustPilotLink = getTrustpilotUrl(locale as SupportedLocale)
  const [overAllRatingFormatted, setOverAllRatingFormatted] = useState<string>('')
  const reviewExplanationTooltipWithCount = reviewExplanationTooltip?.replace('{count}', String(reviewCount ?? 0))

  useEffect(() => {
    setOverAllRatingFormatted(new Intl.NumberFormat(locale, { maximumSignificantDigits: 2 }).format(reviewRating ?? 0))
  }, [locale, reviewRating])

  if (!reviewRating || !reviewCount || !trustPilotLink || reviewRating < 3.75) {
    return null
  }

  const ratingTextArray = [
    reviewRating > 4.2 ? translate('reviewAttributeValueExcellent') : translate('reviewAttributeValueGreat'),
    ...translate('labelOutOfStars').replace('{rating}', overAllRatingFormatted).replace('{max}', '5').split(' '),
  ]

  return (
    <div className='flex w-full flex-col gap-4' title={reviewExplanationTooltipWithCount} aria-label={reviewExplanationTooltipWithCount}>
      <div className='flex gap-1'>
        <span className='font-semibold'> {ratingTextArray.slice(0, 2).join(' ')}</span> {ratingTextArray.slice(2).join(' ')}
        {reviewExplanationTooltip && (
          <div className='hidden cursor-help lg:block' title={reviewExplanationTooltipWithCount}>
            <Info className='size-4 opacity-50' />
          </div>
        )}
      </div>
      <MisterSiteLink link={{ type: 'external', externalLink: trustPilotLink }} title='Trustpilot'>
        <div className='flex items-center justify-between md:flex-col md:items-start md:gap-4'>
          <MisterIcon type={`trustpilot-${(Math.round(reviewRating * 2) / 2).toFixed(1).replace('.0', '')}-stars`} ariaLabel='Trustpilot-stars' />
          <MisterIcon type='trustpilot-logo' ariaLabel='Trustpilot-logo' />
        </div>
      </MisterSiteLink>
    </div>
  )
}

export default MisterTrustpilotWidget
