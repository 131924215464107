import { FunctionComponent, useContext } from 'react'
import { Eye } from 'lucide-react'

import { StyleMenu } from 'data-access/sanity/fragments/components/mainMenuItem.fragment'
import { Button } from '@ui/components/ui/button'
import { NavigationMenuLink } from '@ui/components/ui/navigation-menu'
import { sortColors } from 'utilities/array/sortArray'
import { convertSlug } from 'utilities/string/url'

import MisterImage from 'src/components/shared/image/MisterImage'
import MisterSiteLink, { makeHref } from 'src/components/shared/site-link/MisterSiteLink'
import { GlobalsContext, useTranslations } from 'src/contexts/Globals.context'
import { NAVIGATION_LINK_VARIANTS, TELEMETRY_DATA_LAYER_EVENTS, trackEvent } from 'src/utils/telemetry.util'
import { ActiveVariant } from '../Header'
import StaticColorWheel from './StaticColorWheel'

interface MegaMenuLevelThreeProps {
  style?: StyleMenu
  activeVariant: ActiveVariant
}

export const MegaMenuLevelThree: FunctionComponent<MegaMenuLevelThreeProps> = ({ style, activeVariant }) => {
  const translate = useTranslations()
  const { colorOrder } = useContext(GlobalsContext)

  if (!style) {
    return null
  }

  const sortedColors = style.colors ? sortColors(style.colors, colorOrder) : []

  if (activeVariant === 'control') {
    return (
      <div key={style.slug.current} className='grid grid-cols-6'>
        <div className='col-span-6 2xl:col-span-5 2xl:col-start-2'>
          <div className='relative grid h-[26.5625rem] grid-cols-12 overflow-hidden rounded-md bg-brand-beige-light 2xl:col-start-2 2xl:h-[28rem]'>
            <div className='relative col-span-8 col-start-6 row-span-1 row-start-1 h-full'>
              <div className='absolute bottom-0 left-0 top-0'>
                <MisterImage mobile={style.styleImage} priority={true} className='h-full w-min overflow-hidden object-cover object-left' />
              </div>
            </div>
            <div className='z-10 col-span-6 col-start-1 row-span-1 row-start-1 h-full'>
              <div className='flex h-full flex-col justify-between gap-8 p-6 2xl:gap-8'>
                <div className='flex max-w-60 flex-col gap-1'>
                  <h2 className='text-heading-5 text-brand-blue 2xl:text-heading-4'>{style.title}</h2>
                  <span className='text-body-lg'>{style.collectionDescription}</span>
                </div>
                <div className='flex flex-col justify-between gap-6 2xl:gap-8'>
                  <div className='relative w-min'>
                    <StaticColorWheel items={sortedColors.map((color) => ({ ...color, hex: color.hex }))} />
                    <div className='absolute inset-0 z-10 flex items-center justify-center'>
                      <div className='flex size-[6.5rem] items-center justify-center rounded-full bg-white shadow-2xl shadow-black/30'>
                        <span>
                          {style.colors?.length ?? 0} {translate('colors', 'colors')}
                        </span>
                      </div>
                    </div>
                  </div>
                  <NavigationMenuLink asChild>
                    <MisterSiteLink
                      onClick={() => {
                        const href = makeHref({ _type: 'link', type: 'internal', page: { slug: style.slug, _type: 'collection' } })
                        trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, { clickDestination: href, navigationLevel: 3, variant: NAVIGATION_LINK_VARIANTS.LINK })
                      }}
                      link={convertSlug(style.slug, 'collection')}
                      className='relative'
                    >
                      <Button hover='expandIcon' Icon={Eye} variant='secondary'>
                        {translate('shopStyle', 'Shop Style')}
                      </Button>
                    </MisterSiteLink>
                  </NavigationMenuLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <NavigationMenuLink>
      <MisterSiteLink
        onClick={() => {
          const href = makeHref({ _type: 'link', type: 'internal', page: { slug: style.slug, _type: 'collection' } })
          trackEvent(TELEMETRY_DATA_LAYER_EVENTS.CLICK_NAVIGATION_LINK, { clickDestination: href, navigationLevel: 3, variant: NAVIGATION_LINK_VARIANTS.COLLECTION_IMAGE })
        }}
        link={convertSlug(style.slug, 'collection')}
        key={style.slug.current}
        className='relative flex flex-col gap-4'
      >
        <div className='relative h-[21rem] overflow-hidden rounded-lg bg-brand-beige 2xl:h-[24rem]'>
          <div className='absolute inset-0 ml-auto h-full w-fit bg-brand-beige'>
            <MisterImage priority={true} mobile={style.styleImage} className='h-full w-min overflow-hidden object-cover object-center' alt={style.title} />
          </div>
        </div>
        <div className='flex flex-col gap-1'>
          <h5 className='text-body-xl-bold'>{style.title}</h5>
          <p className='text-body-md'>{style.collectionDescription}</p>
        </div>
      </MisterSiteLink>
    </NavigationMenuLink>
  )
}
