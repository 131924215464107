import { defineQuery } from 'next-sanity'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { Media, MEDIA_FRAGMENT } from '../components/media.fragment'
import { Upsell, UPSELL_FRAGMENT } from '../components/upsell.fragment'

export interface Cart {
  cartTitle: string
  backToShoppingMessage: string
  closeButtonMessage: string
  emptyCartMessage: string
  cartMention?: {
    text?: string
    image?: Media
  }
  upsellBanners: Upsell[]
  subtotalText: string
  checkoutText: string
  checkoutTextMaintenance: string
  activateCheckout: boolean
  recommendedForYou: string
  frequentlyBoughtTogether: string
}

export const CART_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type,
  name,
  ${coalesceQuery('cartTitle', locale)},
  ${coalesceQuery('backToShoppingMessage', locale)},
  ${coalesceQuery('closeButtonMessage', locale)},
  ${coalesceQuery('emptyCartMessage', locale)},
  cartMention {
    ${coalesceQuery('text', locale)},
    defined(image) => {
      image-> {
        ${MEDIA_FRAGMENT()}
      }
    }
  },
  upsellBanners[@->showInLocales[$locale] != false]-> {
    ${UPSELL_FRAGMENT(locale)}
  },
  ${coalesceQuery('subtotalText', locale)},
  ${coalesceQuery('checkoutText', locale)},
  ${coalesceQuery('checkoutTextMaintenance', locale)},
  ${coalesceQuery('activateCheckout', locale)},
  ${coalesceQuery('recommendedForYou', locale)},
  ${coalesceQuery('frequentlyBoughtTogether', locale)},
`)
