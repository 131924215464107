import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { BadgesSchema, SlugSchema } from '../../types'
import { coalesceQuery } from '../../utils'
import { GET_IMAGE_FRAGMENT, ImageSchema } from '../common/getImage.fragment'

export const ShopifyVariantInSanitySchema = z.object({
  shopifyVariantId: z.string(),
  price: z.number(),
  sku: z.string(),
  inventory: z.object({
    quantity: z.number(),
  }),
  options: z.array(
    z.object({
      name: z.string(),
      value: z.string(),
    }),
  ),
  isAvailable: z.boolean(),
  title: z.string(),
})

export type ShopifyVariantInSanity = z.infer<typeof ShopifyVariantInSanitySchema>

export const ProductCardProductSchema = z.object({
  _id: z.string(),
  productTitle: z.string(),
  slug: SlugSchema,
  badges: BadgesSchema,
  discontinued: z.boolean().optional(),
  images: z.array(ImageSchema),
  shopifyProductId: z.string().optional(),
  isAvailable: z.boolean(),
  price: z.number(),
  variants: z.array(ShopifyVariantInSanitySchema),
  colorId: z.string().optional(),
  primaryCollection: z
    .object({
      _id: z.string(),
      productType: z.string(),
    })
    .optional(),
})

export type ProductCardProduct = z.infer<typeof ProductCardProductSchema>

export const PRODUCT_CARD_PRODUCT_FRAGMENT = (locale = DEFAULT_LOCALE, limitImages: number | false = 2) =>
  defineQuery(`
  _id,
  ${coalesceQuery('productTitle', locale)},
  slug,
  badges,
  defined(discontinued[$locale]) => {
    'discontinued': discontinued[$locale]
  },
  'images': coalesce(images[_type == 'image' || _type == 'reference'][${limitImages ? `0...${limitImages}` : ''}] {
    _type == 'image' => {
      ${GET_IMAGE_FRAGMENT()}
    },
    _type == 'reference' => @-> {
      ...image {
        ${GET_IMAGE_FRAGMENT()}
      },
    },
  }, []),
  defined(shopifyProductId[$locale]) => {
    'shopifyProductId': shopifyProductId[$locale]
  },
  'isAvailable': count(shopifyVariants[@->inventory[locationId == $inventoryLocationId][0].quantity > 0]) > 0 && availableInShopify[$locale],
  'price': shopifyVariants[0]->price[$locale],
  'variants': shopifyVariants[]-> {
    'shopifyVariantId': shopifyId[$locale],
    'price': price[$locale],
    sku,
    inventory[locationId == $inventoryLocationId][0] {
      quantity
    },
    options[] {
      name,
      'value': value[$locale],
    },
    'isAvailable': inventory[locationId == $inventoryLocationId][0].quantity > 0,
    'title': array::join(options[].value[$locale], ' / ')
  },
  'colorId': color->_id,
  'primaryCollection': *[_type == 'collection' && ^._id in products[]._ref && isPrimary == true][0] {
    _id,
    productType,
  }
`)
