import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react'

import { ShopifyVariantInSanity } from 'data-access/sanity/fragments/components/productCardProduct.fragment'
import { cn } from 'ui/lib/utils'
import { combineOptions, optionsToObject } from 'utilities/array/combineOptions'

import OptionIcon from 'src/components/shared/OptionIcon'
import { useTranslations } from 'src/contexts/Globals.context'
import { ProductWithEnrichedAvailability } from 'src/domain/productCard.domain'
import LowStockWarning from './LowStockWarning'

interface SplitOptionSelectProps {
  product: ProductWithEnrichedAvailability
  selectedVariant: ShopifyVariantInSanity | null
  setSelectedVariant: Dispatch<SetStateAction<ShopifyVariantInSanity | null>>
}

const SplitOptionSelect: FunctionComponent<SplitOptionSelectProps> = ({ product, selectedVariant, setSelectedVariant }) => {
  const translate = useTranslations()
  const options = combineOptions(product.variants.flatMap(({ options }) => options))

  // Show option label if there are more than one option
  const showOptionLabel = options?.length > 1

  // Get all available options
  const availableOptions = product.variants.filter(({ isAvailable }) => isAvailable).map(({ options }) => optionsToObject(options || []))

  const [selectedOptions, setSelectedOptions] = useState(product.variants.length === 1 ? availableOptions[0] : {})

  return (
    <div className='mb-5 grid grid-cols-[auto_1fr] gap-2 py-1'>
      {options.map(({ name: optionName, values: optionValues }, sizeRowIndex) => {
        const { [optionName]: remove, ...otherSelectedOption } = selectedOptions || {}

        return (
          <fieldset key={`${product._id}-${optionName}-options`} className='contents'>
            <div className='col-span-2 grid grid-cols-subgrid'>
              {showOptionLabel && (
                <div className='flex w-min select-none items-center justify-center gap-1'>
                  <OptionIcon aria-hidden className='size-6 lg:size-7' option={optionName} />
                  <span className='text-nowrap text-body-sm capitalize'>{translate(`option${optionName.replace(' ', '')}`, optionName)}</span>
                </div>
              )}

              <div
                className={cn(
                  'text-btn-sm font-semibold',
                  showOptionLabel ? 'flex flex-wrap justify-start gap-1' : optionValues.length > 6 ? `grid grid-cols-7 gap-y-4` : 'col-span-2 flex justify-between',
                )}
              >
                {optionValues.map((optionValue, sizeColIndex) => {
                  const id = `${product._id}-${optionName}-${optionValue}-option-value`.toLowerCase()
                  const optionIsAvailable = availableOptions
                    .filter((option) => Object.keys(otherSelectedOption).length === 0 || Object.entries(otherSelectedOption).every(([key, v]) => option[key] === v))
                    .find((option) => option[optionName] === optionValue)

                  return (
                    <div className='relative flex flex-col items-center' data-testid='size-select' key={id}>
                      <input
                        id={id}
                        name={optionName}
                        onChange={(e) => {
                          const newSelectedOptions = { ...selectedOptions, [optionName]: e.target.value }
                          const newSelectedVariant = product.variants.find(({ options: s }) => s?.every(({ name, value }) => newSelectedOptions?.[name] === value))
                          setSelectedVariant(newSelectedVariant || null)
                          setSelectedOptions(newSelectedOptions)
                        }}
                        type='radio'
                        value={optionValue}
                        checked={selectedOptions?.[optionName] === optionValue}
                        className='peer hidden'
                      />
                      <label
                        role='button'
                        htmlFor={id}
                        className={cn(
                          'flex size-6 items-center justify-center whitespace-nowrap rounded-sm text-center text-body-sm tracking-tight transition-colors duration-100 hover:bg-brand-beige active:ring-1 active:ring-brand-blue-action peer-checked:bg-brand-blue-light peer-disabled:cursor-default peer-disabled:text-brand-blue-dark-disabled sm:size-8 xl:size-7',
                          !optionIsAvailable && 'pointer-events-none text-brand-muted peer-checked:bg-brand-muted peer-checked:text-white',
                        )}
                      >
                        {optionValue}
                      </label>

                      <LowStockWarning product={product} options={options} sizeColIndex={sizeColIndex} sizeRowIndex={sizeRowIndex} selectedVariant={selectedVariant} />
                    </div>
                  )
                })}
              </div>
            </div>
          </fieldset>
        )
      })}
    </div>
  )
}

export default SplitOptionSelect
