import { DefaultSeoProps, NextSeoProps } from 'next-seo/lib/types'

import { DEFAULT_LOCALE, hreflangMapping, locales, localesWithFallbackForCoalesceQuery, SupportedLocale } from 'data-access/domain/constants'
import { sanityCdnClient } from 'data-access/sanity/clients/sanityClient'
import { Image } from 'data-access/sanity/fragments/common/getImage.fragment'
import { Seo } from 'data-access/sanity/fragments/components/seo.fragment'
import { Slug } from 'data-access/sanity/types'
import { getBaseUrl } from 'utilities/string/url'
import { buildUrl } from 'utilities/string/url/buildUrl'
import { SanityDocument } from 'utilities/string/url/types'

import { imageQuality } from './image.util'

const defaultTitle: string = 'MR MARVIS'
const defaultDescription: string = 'MR MARVIS'
const baseUrl = getBaseUrl()

// Default SEO configuration
// @see https://github.com/garmeeh/next-seo
export const seo: DefaultSeoProps = {
  titleTemplate: `%s | ${defaultTitle}`,
  defaultTitle: defaultTitle,
  description: defaultDescription,
  openGraph: {
    type: 'website',
    locale: 'en_IE', // EN with €
    url: baseUrl,
    siteName: defaultTitle,
    description: defaultDescription,
  },
}

/**
 * Extract SEO info out of Sanity data object to hydrate next-seo component.
 */

export const seoData = (seo?: Seo, titleFallback?: string, additionalImages: Image[] = []): NextSeoProps => {
  const defaultImageWidth = 1200
  const defaultImageHeight = 627

  return {
    title: seo?.seoTitle || titleFallback,
    description: seo?.seoDescription,
    noindex: seo?.noIndex || false,
    ...(seo?.languageAlternates ? { languageAlternates: seo.languageAlternates } : {}),
    openGraph: {
      images: [
        ...(seo?.seoImage?.url
          ? [
              {
                // Resized on the fly
                // @see https://www.sanity.io/docs/image-urls
                url: `${seo?.seoImage?.url}?fit=clip&h=${defaultImageWidth}&w=${defaultImageHeight}&q=${imageQuality}`,
                type: seo?.seoImage?.url?.endsWith('.png') ? 'image/png' : 'image/jpeg',
                width: defaultImageWidth,
                height: defaultImageHeight,
                alt: seo?.seoTitle || titleFallback,
              },
            ]
          : []),
        ...(additionalImages
          ?.filter((image) => image._type === 'image')
          ?.map((image) => {
            return {
              url: `${image.url}?q=${imageQuality}`,
              type: image.url?.endsWith('.png') ? 'image/png' : 'image/jpeg',
              width: image.width,
              height: image.height,
              alt: seo?.seoTitle || titleFallback,
            }
          }) || []),
      ],
    },
  }
}

type HreflangLink = {
  rel: 'alternate'
  hrefLang: string | 'x-default'
  href: string
}

type LocalizedSlug = Record<SupportedLocale, Slug>

export const generateHreflangLinks = async (document: SanityDocument): Promise<HreflangLink[]> => {
  const slugData = await fetchLocalizedSlugs(document)

  const links: HreflangLink[] = locales.flatMap((locale) => {
    const slug = coalesceSlug(slugData, locale)
    const url = buildUrl({ ...document, slug: { current: slug } }, { locale })
    return hreflangMapping[locale].map((hrefLang) => ({
      rel: 'alternate',
      hrefLang,
      href: url,
    }))
  })

  // Add x-default link
  const defaultSlug = coalesceSlug(slugData, DEFAULT_LOCALE)
  const defaultUrl = buildUrl({ ...document, slug: { current: defaultSlug } }, { locale: DEFAULT_LOCALE })
  links.push({
    rel: 'alternate',
    hrefLang: 'x-default',
    href: defaultUrl,
  })

  return links
}

const fetchLocalizedSlugs = async (document: SanityDocument): Promise<Slug | LocalizedSlug> => {
  const query = `*[_id == "${document._id}"] { slug }[0]`
  const result = await sanityCdnClient.fetch(query)
  return result?.slug || {}
}

const coalesceSlug = (slugData: Slug | LocalizedSlug, locale: SupportedLocale): string => {
  if ('current' in slugData) {
    // Non-localized slug, use the same slug for all locales
    return slugData.current
  }

  // Handle localized slugs with fallback logic
  const fallbackLocales = localesWithFallbackForCoalesceQuery[locale] || [locale]
  for (const fallbackLocale of fallbackLocales) {
    if (slugData[fallbackLocale as keyof typeof slugData]?.current) {
      return slugData[fallbackLocale as keyof typeof slugData].current
    }
  }
  return ''
}
