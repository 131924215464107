import { FunctionComponent, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Configure, InstantSearch, InstantSearchProps } from 'react-instantsearch'
import { useCookie } from 'react-use'

import { algoliaLiteClient, getAlgoliaIndexName } from 'data-access/algolia/utils'
import { SupportedLocale } from 'data-access/domain/constants'
import { SectionSearchFiltersGridData } from 'data-access/sanity/fragments/sections/sectionSearchFiltersGrid.fragment'
import { cn } from '@ui/lib/utils'
import { buildUrl } from 'utilities/string/url'

import SearchFiltersSkeleton from 'src/components/shared/loader/SearchFiltersSkeleton'
import SearchResultsSkeleton from 'src/components/shared/loader/SearchResultsSkeleton'
import { ELEVAR_USER_ID_COOKIE } from 'src/middleware'
import { algoliaInsightsClient, customRouter, customStateMapping } from '../../../utils/algolia.util'
import SearchResultsGrid from './SearchResultsGrid'

interface CollectionSearchFiltersProps {
  data: SectionSearchFiltersGridData
}

const CollectionSearchFilters: FunctionComponent<CollectionSearchFiltersProps> = ({ data }) => {
  const { slug, filterConfiguration } = data
  const { locale } = useRouter()
  const [isHydrated, setIsHydrated] = useState(false)
  const [customUserId] = useCookie(ELEVAR_USER_ID_COOKIE)
  let serverUrl = buildUrl({ _type: 'collection', slug: { current: slug.current } }, { locale: locale as SupportedLocale })
  const defaultSorting = filterConfiguration.defaultSorting
  const currentIndexName = getAlgoliaIndexName(String(locale), defaultSorting)

  if (typeof window !== 'undefined') {
    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.toString()) {
      serverUrl += `?${queryParams.toString()}`
    }
  }

  const instantSearchProps: InstantSearchProps = {
    future: { preserveSharedStateOnUnmount: true },
    searchClient: algoliaLiteClient,
    indexName: currentIndexName,
    routing: {
      router: customRouter(serverUrl),
      stateMapping: customStateMapping(serverUrl, currentIndexName, defaultSorting),
    },
    insights: {
      insightsClient: algoliaInsightsClient,
    },
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsHydrated(true)
    }, 500)

    return () => {
      clearTimeout(timer)
      setIsHydrated(false)
    }
  }, [])

  const searchKey = `${slug.current}-${defaultSorting}`

  return (
    <div className='grid min-h-[800px] grid-cols-1 grid-rows-1'>
      <div className={cn('col-start-1 col-end-2 row-start-1 row-end-2', 'transition-opacity duration-300', isHydrated ? 'hidden opacity-0' : 'opacity-100')}>
        <div className='group/grid grid w-full lg:grid-cols-25-75'>
          <div>
            <SearchFiltersSkeleton className='max-lg:hidden' />
          </div>
          <div className='@container/product-grid [container-type:inline-size]'>
            <SearchResultsSkeleton />
          </div>
        </div>
      </div>

      <div className={cn('col-start-1 col-end-2 row-start-1 row-end-2', 'transition-opacity duration-300', isHydrated ? 'opacity-100' : 'hidden opacity-0')}>
        <InstantSearch {...instantSearchProps} key={`instant-search-${searchKey}`}>
          <Configure
            key={`algolia-configure-${searchKey}`}
            index={currentIndexName}
            analytics={true}
            clickAnalytics={true}
            userToken={customUserId ?? undefined}
            filters={`parentCollectionsSlugs:${slug.current}`}
            hitsPerPage={filterConfiguration.pageSize || 1000}
            analyticsTags={['collection-search', slug.current]}
          />
          <SearchResultsGrid key={`results-grid-${searchKey}`} currentIndexName={currentIndexName} locale={String(locale)} data={data} />
        </InstantSearch>
      </div>
    </div>
  )
}

export default CollectionSearchFilters
