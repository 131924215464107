import React, { FunctionComponent } from 'react'
import Image from 'next/image'

import { Button } from '@ui/components/ui/button'

import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import { useTranslations } from 'src/contexts/Globals.context'
import { imageQuality } from 'src/utils/image.util'

export const AccountButton: FunctionComponent = () => {
  const translate = useTranslations()
  return (
    <Button asChild variant='ghost' size='icon' className='hidden lg:block'>
      <MisterSiteLink
        e2eSelector='account-link'
        className='items-center lg:flex'
        link={{ type: 'internal', page: { slug: { current: 'account' } } }}
        title={translate('account', 'Account')}
      >
        <Image src='/img/my-account.svg' alt={translate('myAccount', 'My account')} priority={false} quality={imageQuality} width={24} height={24} />
      </MisterSiteLink>
    </Button>
  )
}
