import { Multipass } from 'multipass-js'

import { CUSTOMER_EMAIL_MARKETING_CONSENT_MUTATION, UpdateCustomerEmailMarketingConsentDto } from './fragments/adminApi/customerEmailMarketingConsentMutation.fragment'
import { CreateCustomerFromEmailDto, CUSTOMER_FROM_EMAIL_MUTATION } from './fragments/adminApi/customerFromEmailMutation.fragment'
import { GET_CUSTOMER_BY_EMAIL_QUERY, GetCustomerIdByEmailDto } from './fragments/adminApi/customersByEmailQuery.fragment'
import { CUSTOMER_WISHLIST_METAFIELD_MUTATION, SetCustomerWishListDto } from './fragments/adminApi/customerWishlistMetafieldMutation.fragment'
import { AddTagsDto, TAGS_ADD_MUTATION } from './fragments/adminApi/tagsAddMutation.fragment'
import { ADD_DISCOUNT_CODE_MUTATION, ApplyDiscountCodeDto } from './fragments/cart/addDiscountCodeMutation.fragment'
import { ADD_LINE_ITEMS_MUTATION, AddLineItemsDto } from './fragments/cart/addLineItemsMutation.fragment'
import { CART_ATTRIBUTES_MUTATION, UpdateCartAttributesDto } from './fragments/cart/cartAttributesMutation.fragment'
import { CREATE_CART_MUTATION, CreateCartDto } from './fragments/cart/createCartMutation.fragment'
import { GET_CART_QUERY, GetCartDto } from './fragments/cart/getCartQuery.fragment'
import { REMOVE_LINE_ITEMS_MUTATION, RemoveLineItemsDto } from './fragments/cart/removeLineItemsMutation.fragment'
import { REPLACE_LINE_ITEMS_MUTATION, ReplaceLineItemsDto } from './fragments/cart/replaceLineItemsMutation.fragment'
import { ACTIVATE_CUSTOMER_MUTATION, ActivateCustomerDto } from './fragments/customer/activateCustomerMutation.fragment'
import { AVAILABLE_COUNTRIES_QUERY, AvailableCountriesDto } from './fragments/customer/availableCountriesQuery.fragment'
import { CREATE_CUSTOMER_ADDRESS_MUTATION, CreateAddressDto } from './fragments/customer/createCustomerAddressMutation.fragment'
import { CREATE_CUSTOMER_MUTATION, CreateCustomerDto } from './fragments/customer/createCustomerMutation.fragment'
import { CREATE_CUSTOMER_TOKEN_MUTATION, getCustomerTokenDto } from './fragments/customer/createCustomerTokenMutation.fragment'
import { CUSTOMER_BY_TOKEN_QUERY, GetCustomerByTokenDto } from './fragments/customer/customerByTokenQuery.fragment'
import { CUSTOMER_WISHLIST_METAFIELD_QUERY, GetCustomerWishListDto } from './fragments/customer/customerWishlistMetafieldQuery.fragment'
import { DELETE_CUSTOMER_ADDRESS_MUTATION, DeleteAddressDto } from './fragments/customer/deleteCustomerAddressMutation.fragment'
import { DELETE_CUSTOMER_TOKEN_MUTATION, DeleteCustomerTokenDto } from './fragments/customer/deleteCustomerTokenMutation.fragment'
import { GetMinimalCustomerDto, MINIMAL_CUSTOMER_QUERY } from './fragments/customer/minimalCustomerQuery.fragment'
import { GetResetPasswordEmailDto, RECOVER_CUSTOMER_MUTATION } from './fragments/customer/recoverCustomerMutation.fragment'
import { RESET_CUSTOMER_MUTATION, ResetPasswordDto } from './fragments/customer/resetCustomerMutation.fragment'
import { UPDATE_CUSTOMER_ADDRESS_MUTATION, UpdateAddressDto } from './fragments/customer/updateCustomerAddressMutation.fragment'
import { UPDATE_CUSTOMER_DEFAULT_ADDRESS_MUTATION, UpdateDefaultAddressDto } from './fragments/customer/updateCustomerDefaultAddressMutation.fragment'
import { UPDATE_CUSTOMER_MUTATION, UpdateCustomerDto } from './fragments/customer/updateCustomerMutation.fragment'
import { GetRecommendedProductsDto, PRODUCT_RECOMMENDATIONS_QUERY } from './fragments/products/productRecommendationsQuery.fragment'
import { GetProductsAvailabilityDto, PRODUCTS_AVAILABILITY_QUERY } from './fragments/products/productsAvailabilityQuery.fragment'
import { GET_RETAIL_INVENTORY_QUERY, GetRetailInventoryByHandleDto } from './fragments/products/retailInventoryQuery.fragment'
import fetchShopify from './shopifyClient'
import { ShopifyAddress, ShopifyCustomerUpdateOptions, ShopifyCustomerUpdatePasswordOptions } from './types'
import { isStoreLocale, preparePayload, prepareStore } from './utils'

export const serverSideOnlyGetMultipass = async (locale: string | undefined | null, email: string, webUrl: string) => {
  if (!locale || !isStoreLocale(locale)) {
    throw new Error('Locale not found')
  }

  const store = prepareStore(locale)

  const secret = `${store?.storeLocale ? store?.storeLocale?.toUpperCase() : 'EN'}_MULTIPASS_SECRET`

  // uncomment to test on STAGING STORE
  // const multipass = new Multipass(process.env.STAGING_MULTIPASS_SECRET)

  const multipass = new Multipass(process.env[secret])

  if (multipass) {
    const url = multipass
      .withCustomerData({ email })
      .withDomain(store?.customStoreDomain || 'en')
      .withRedirect(webUrl)
      .url()

    return url
  }

  return webUrl
}

export const createCart = async (locale: string | undefined | null, preferredCountryCode?: string) => {
  const {
    data: { cartCreate },
  }: CreateCartDto = await fetchShopify({
    locale,
    payload: preparePayload(CREATE_CART_MUTATION, {
      input: {
        buyerIdentity: {
          countryCode: preferredCountryCode?.toUpperCase(),
        },
      },
    }),
    useCustomDomain: true,
  })

  return cartCreate
}

export const getCartById = async (locale: string | undefined | null, id?: string | null) => {
  if (!id) {
    return null
  }

  const {
    data: { cart },
  }: GetCartDto = await fetchShopify({
    locale,
    payload: preparePayload(GET_CART_QUERY, {
      id,
    }),
    useCustomDomain: true,
  })

  return cart
}

export const updateCartAttributes = async (
  locale: string | undefined | null,
  cartId: string,
  customAttributes: {
    key: string
    value: string
  }[],
) => {
  const {
    data: { cartAttributesUpdate },
  }: UpdateCartAttributesDto = await fetchShopify({
    locale,
    payload: preparePayload(CART_ATTRIBUTES_MUTATION, {
      cartId,
      attributes: customAttributes,
    }),
  })

  return cartAttributesUpdate
}

export const applyDiscountCode = async (locale: string | undefined | null, cartId: string, discountCodes: string[]) => {
  const {
    data: { cartDiscountCodesUpdate },
  }: ApplyDiscountCodeDto = await fetchShopify({
    locale,
    payload: preparePayload(ADD_DISCOUNT_CODE_MUTATION, {
      cartId,
      discountCodes,
    }),
  })

  return cartDiscountCodesUpdate
}

export const addLineItems = async (
  locale: string | undefined | null,
  cartId: string,
  lines: {
    merchandiseId: string
    quantity?: number
    sellingPlanId?: string
    attributes?: {
      key: string
      value: string
    }[]
  }[],
) => {
  const {
    data: { cartLinesAdd },
  }: AddLineItemsDto = await fetchShopify({
    locale,
    payload: preparePayload(ADD_LINE_ITEMS_MUTATION, {
      cartId,
      lines,
    }),
  })

  return cartLinesAdd
}

export const removeLineItems = async (locale: string | undefined | null, cartId: string, lineIds: string[]) => {
  const {
    data: { cartLinesRemove },
  }: RemoveLineItemsDto = await fetchShopify({
    locale,
    payload: preparePayload(REMOVE_LINE_ITEMS_MUTATION, {
      cartId,
      lineIds,
    }),
  })

  return cartLinesRemove
}

export const replaceLineItems = async (
  locale: string | undefined | null,
  cartId: string,
  lines: {
    id: string
    quantity: number
  }[],
) => {
  const {
    data: { cartLinesUpdate },
  }: ReplaceLineItemsDto = await fetchShopify({
    locale,
    payload: preparePayload(REPLACE_LINE_ITEMS_MUTATION, {
      cartId,
      lines,
    }),
  })

  return cartLinesUpdate
}

export const createCustomer = async (locale: string | undefined | null, email: string, password: string, firstName: string, lastName: string) => {
  const {
    data: { customerCreate },
  }: CreateCustomerDto = await fetchShopify({
    locale,
    payload: preparePayload(CREATE_CUSTOMER_MUTATION, {
      input: { email, password, firstName, lastName },
    }),
  })

  return customerCreate
}

export const serverSideOnlyCreateCustomerFromEmail = async (locale: string | undefined | null, email: string) => {
  const {
    data: { customerCreate },
  }: CreateCustomerFromEmailDto = await fetchShopify({
    locale,
    payload: preparePayload(CUSTOMER_FROM_EMAIL_MUTATION, {
      input: { email },
    }),
    withAdmin: true,
  })

  return customerCreate
}

export const activateCustomer = async (locale: string | undefined | null, id: string, activationToken: string, password: string) => {
  const {
    data: { customerActivate },
  }: ActivateCustomerDto = await fetchShopify({
    locale,
    payload: preparePayload(ACTIVATE_CUSTOMER_MUTATION, {
      id,
      input: { activationToken, password },
    }),
  })

  return customerActivate
}

export const getCustomerToken = async (locale: string | undefined | null, email: string, password: string) => {
  const {
    data: { customerAccessTokenCreate },
  }: getCustomerTokenDto = await fetchShopify({
    locale,
    payload: preparePayload(CREATE_CUSTOMER_TOKEN_MUTATION, {
      input: {
        email,
        password,
      },
    }),
  })

  return customerAccessTokenCreate
}

export const deleteCustomerToken = async (locale: string | undefined | null, customerAccessToken: string) => {
  const {
    data: { customerAccessTokenDelete },
  }: DeleteCustomerTokenDto = await fetchShopify({
    locale,
    payload: preparePayload(DELETE_CUSTOMER_TOKEN_MUTATION, {
      customerAccessToken,
    }),
  })

  return customerAccessTokenDelete
}

export const getMinimalCustomer = async (locale: string | undefined | null, customerAccessToken: string) => {
  const {
    data: { customer },
  }: GetMinimalCustomerDto = await fetchShopify({
    locale,
    payload: preparePayload(MINIMAL_CUSTOMER_QUERY, {
      customerAccessToken,
    }),
  })

  return customer
}

export const serverSideOnlyGetCustomerIdByEmail = async (locale: string | undefined | null, email: string) => {
  const {
    data: { customers },
  }: GetCustomerIdByEmailDto = await fetchShopify({
    locale,
    payload: preparePayload(GET_CUSTOMER_BY_EMAIL_QUERY, {
      email: `email:${email}`,
    }),
    withAdmin: true,
  })

  const customerId = customers?.nodes?.[0]?.id

  return customerId
}

export const getCustomerWishList = async (locale: string | undefined | null, customerAccessToken: string) => {
  const {
    data: { customer },
  }: GetCustomerWishListDto = await fetchShopify({
    locale,
    payload: preparePayload(CUSTOMER_WISHLIST_METAFIELD_QUERY, {
      customerAccessToken,
    }),
  })

  return customer?.metafield?.value
}

export const serverSideOnlySetCustomerWishList = async (locale: string | undefined | null, customerId: string, wishlist: string) => {
  const {
    data: { metafieldsSet },
  }: SetCustomerWishListDto = await fetchShopify({
    locale,
    payload: preparePayload(CUSTOMER_WISHLIST_METAFIELD_MUTATION, {
      metafields: [
        {
          key: 'customer_wishlist',
          namespace: 'customer',
          type: 'json',
          ownerId: customerId,
          value: wishlist,
        },
      ],
    }),
    withAdmin: true,
  })

  return metafieldsSet
}

export const serverSideOnlyUpdateCustomerEmailMarketingConsent = async (locale: string | undefined | null, customerId: number, marketingState: 'SUBSCRIBED' | 'UNSUBSCRIBED') => {
  const {
    data: { customerEmailMarketingConsentUpdate },
  }: UpdateCustomerEmailMarketingConsentDto = await fetchShopify({
    locale,
    payload: preparePayload(CUSTOMER_EMAIL_MARKETING_CONSENT_MUTATION, {
      input: {
        customerId: `gid://shopify/Customer/${customerId}`,
        emailMarketingConsent: {
          marketingState: marketingState,
          marketingOptInLevel: 'SINGLE_OPT_IN',
        },
      },
    }),
    withAdmin: true,
  })

  return customerEmailMarketingConsentUpdate
}

export const serverSideOnlyAddTags = async (locale: string | undefined | null, gid: string, tags: string[]) => {
  const {
    data: { tagsAdd },
  }: AddTagsDto = await fetchShopify({
    locale,
    payload: preparePayload(TAGS_ADD_MUTATION, {
      id: gid, // e.g. 'gid://shopify/Customer/544365967'
      tags: tags.join(','),
    }),
    withAdmin: true,
  })

  return tagsAdd
}

export const getCustomerByToken = async (locale: string | undefined | null, customerAccessToken: string) => {
  const {
    data: { customer },
  }: GetCustomerByTokenDto = await fetchShopify({
    locale,
    payload: preparePayload(CUSTOMER_BY_TOKEN_QUERY, {
      customerAccessToken,
    }),
  })

  return customer
}

export const updateCustomer = async (locale: string | undefined | null, customerAccessToken: string, customer: ShopifyCustomerUpdateOptions) => {
  const {
    data: { customerUpdate },
  }: UpdateCustomerDto = await fetchShopify({
    locale,
    payload: preparePayload(UPDATE_CUSTOMER_MUTATION, {
      customerAccessToken,
      customer,
    }),
  })

  return customerUpdate
}

export const updateCustomerPassword = async (locale: string | undefined | null, customerAccessToken: string, customer: ShopifyCustomerUpdatePasswordOptions) => {
  const {
    data: { customerUpdate },
  }: UpdateCustomerDto = await fetchShopify({
    locale,
    payload: preparePayload(UPDATE_CUSTOMER_MUTATION, {
      customerAccessToken,
      customer,
    }),
  })

  return customerUpdate
}

export const createAddress = async (locale: string | undefined | null, customerAccessToken: string, address: ShopifyAddress) => {
  const {
    data: { customerAddressCreate },
  }: CreateAddressDto = await fetchShopify({
    locale,
    payload: preparePayload(CREATE_CUSTOMER_ADDRESS_MUTATION, {
      customerAccessToken,
      address,
    }),
  })

  return customerAddressCreate
}

export const updateDefaultAddress = async (locale: string | undefined | null, customerAccessToken: string, addressId: string) => {
  const {
    data: { customerDefaultAddressUpdate },
  }: UpdateDefaultAddressDto = await fetchShopify({
    locale,
    payload: preparePayload(UPDATE_CUSTOMER_DEFAULT_ADDRESS_MUTATION, {
      customerAccessToken,
      addressId,
    }),
  })

  return customerDefaultAddressUpdate
}

export const updateAddress = async (locale: string | undefined | null, customerAccessToken: string, id: string, address: ShopifyAddress) => {
  const {
    data: { customerAddressUpdate },
  }: UpdateAddressDto = await fetchShopify({
    locale,
    payload: preparePayload(UPDATE_CUSTOMER_ADDRESS_MUTATION, {
      customerAccessToken,
      id,
      address,
    }),
  })

  return customerAddressUpdate
}

export const deleteAddress = async (locale: string | undefined | null, customerAccessToken: string, id: string) => {
  const {
    data: { customerAddressDelete },
  }: DeleteAddressDto = await fetchShopify({
    locale,
    payload: preparePayload(DELETE_CUSTOMER_ADDRESS_MUTATION, {
      customerAccessToken,
      id,
    }),
  })

  return customerAddressDelete
}

export const getResetPasswordEmail = async (locale: string | undefined | null, email: string) => {
  const {
    data: { customerRecover },
  }: GetResetPasswordEmailDto = await fetchShopify({
    locale,
    payload: preparePayload(RECOVER_CUSTOMER_MUTATION, {
      email,
    }),
  })

  return customerRecover
}

export const resetPassword = async (locale: string | undefined | null, id: string, password: string, resetToken: string) => {
  const {
    data: { customerReset },
  }: ResetPasswordDto = await fetchShopify({
    locale,
    payload: preparePayload(RESET_CUSTOMER_MUTATION, {
      id,
      input: {
        password,
        resetToken,
      },
    }),
  })

  return customerReset
}

export const getProductsAvailability = async (locale: string | undefined | null, id?: string) => {
  const {
    data: { nodes },
  }: GetProductsAvailabilityDto = await fetchShopify({
    locale,
    payload: preparePayload(PRODUCTS_AVAILABILITY_QUERY, {
      ids: [id],
    }),
  })

  return nodes
}

/**
 * Fetch product recommendations from Shopify. Shopify's 'Shopify Search & Discovery'
 * app can theoretically influence the results. Remember, per API limitations, we
 * can only fetch 10 recommendations.
 *
 * @see https://shopify.dev/docs/api/storefront/2024-01/queries/productRecommendations
 */
export const getRecommendedProducts = async (locale: string | undefined | null, id: string) => {
  const {
    data: { productRecommendations },
  }: GetRecommendedProductsDto = await fetchShopify({
    locale,
    payload: preparePayload(PRODUCT_RECOMMENDATIONS_QUERY, {
      productId: id,
    }),
  })

  return productRecommendations
}

export const getRetailInventoryByHandle = async (handle: string) => {
  const {
    data: {
      product: {
        variants: { edges },
      },
    },
  }: GetRetailInventoryByHandleDto = await fetchShopify({
    locale: 'retail',
    payload: preparePayload(GET_RETAIL_INVENTORY_QUERY, {
      handle,
    }),
  })

  return edges
}

export const getAvailableCountries = async (locale: string | undefined | null) => {
  const {
    data: { localization },
  }: AvailableCountriesDto = await fetchShopify({
    locale,
    payload: preparePayload(AVAILABLE_COUNTRIES_QUERY),
  })

  return localization
}
