import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { SlugSchema } from '../../types'
import { coalesceQuery } from '../../utils'
import { LINK_FRAGMENT, LinkSchema } from '../components/link.fragment'
import { MEDIA_FRAGMENT, MediaSchema } from '../components/media.fragment'

export const SliderCardItemDataSchema = z.object({
  _id: z.string(),
  _type: z.literal('sliderCard'),
  new: z.boolean().optional(),
  title: z.string().optional(),
  subtitle: z.string().optional(),
  link: z.union([LinkSchema, SlugSchema]).optional(),
  image: MediaSchema.optional(),
})

export type SliderCardItemData = z.infer<typeof SliderCardItemDataSchema>

export const SLIDER_CARD_ITEM_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  ...{
    _id,
    _type,
    defined(title) => {
      ${coalesceQuery('title', locale)},
    },
    defined(subtitle) => {
      ${coalesceQuery('subtitle', locale)},
    },
    defined(image) => {
      image-> {
        ${MEDIA_FRAGMENT()}
      }
    },
    defined(link) => {
      link-> {
        ${LINK_FRAGMENT(locale)}
      }
    },
    defined(collection) => {
      collection-> {
        new,
        ${coalesceQuery('slug', locale)},
        ${coalesceQuery('title', locale)},
        ${coalesceQuery('collectionDescription', locale, 'subTitle')},
        defined(collectionCardImage) => {
        "image": collectionCardImage-> {
            ${MEDIA_FRAGMENT()}
          }
        },
      }
    }
  } {
    _id,
    _type,
    defined(collection) => {
      'new': collection.new,
    },
    'title': coalesce(title, collection.title),
    'subtitle': coalesce(subtitle, collection.subTitle),
    'image': coalesce(image, collection.image),
    'link': coalesce(link, collection.slug),
  }
`)

export const SectionSliderSchema = z.object({
  _type: z.literal('sectionSlider'),
  _id: z.string(),
  title: z.string().optional(),
  items: z.array(SliderCardItemDataSchema),
})

export type SectionSliderData = z.infer<typeof SectionSliderSchema>

// TODO: Remove the check for showInLocales as this is legacy
export const SECTION_SLIDER_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  _type == 'sectionSlider' => {
    _id,
    _type,
    defined(title) => {
      ${coalesceQuery('title', locale)}
    },
    items[@->showInLocales[$locale] != false && (!defined(@->collection) || @->collection->showInLocales[$locale] != false) && @->_type == 'sliderCard']-> {
      ${SLIDER_CARD_ITEM_FRAGMENT(locale)}
    },
  }
`)
