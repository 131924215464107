import { FunctionComponent } from 'react'
import Image from 'next/image'

import { AverageRating } from 'data-access/database/types'
import { Link } from 'data-access/sanity/fragments/components/link.fragment'

import CountryPicker from 'src/components/shared/CountryPicker'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import { imageQuality } from 'src/utils/image.util'
import MisterFooterReviewRating from './MisterFooterReviewRating'

interface Props {
  averageRating?: AverageRating
  reviewExplanationTooltip?: string
  bCorpLink?: Link
  bCorpNoticeLink?: Link
}

export const MisterFooterSocial: FunctionComponent<Props> = ({ averageRating, reviewExplanationTooltip, bCorpLink, bCorpNoticeLink }) => {
  const BCorp = bCorpLink ? MisterSiteLink : 'div'

  return (
    <div className='flex flex-col gap-10'>
      <CountryPicker type='footerMenu' />
      <div className='flex justify-between gap-3'>
        <div className='flex flex-col gap-6'>
          <div className='flex gap-3'>
            <MisterSiteLink className='transition-all hover:scale-110' link={{ type: 'external', newTab: true, externalLink: 'https://www.facebook.com/MRMARVIS.official' }}>
              <Image src={`/img/social/facebook-footer.svg`} width={32} height={32} alt='Facebook' priority={false} quality={imageQuality} />
            </MisterSiteLink>
            <MisterSiteLink className='transition-all hover:scale-110' link={{ type: 'external', newTab: true, externalLink: 'https://www.instagram.com/mrmarvis' }}>
              <Image src={`/img/social/instagram-footer.svg`} width={32} height={32} alt='Instagram' priority={false} quality={imageQuality} />
            </MisterSiteLink>
            <MisterSiteLink className='transition-all hover:scale-110' link={{ type: 'external', newTab: true, externalLink: 'https://www.tiktok.com/@mrmarvis_' }}>
              <Image src={`/img/social/tiktok-footer.svg`} width={32} height={32} alt='TikTok' priority={false} quality={imageQuality} />
            </MisterSiteLink>
          </div>
          {averageRating && <MisterFooterReviewRating reviewRating={averageRating.rating} reviewCount={averageRating.count} reviewExplanationTooltip={reviewExplanationTooltip} />}
        </div>
        <div className='flex flex-col items-end gap-2'>
          <BCorp link={bCorpLink} className='relative flex min-h-16 min-w-10 flex-col'>
            <Image src='/img/b-corp/b-corp-logo.svg' fill alt='Certified B corporation' priority={false} quality={imageQuality} />
          </BCorp>
          {bCorpNoticeLink && (
            <MisterSiteLink title={bCorpNoticeLink.linkText || 'BCorp'} className='text-body-sm' link={bCorpNoticeLink}>
              {bCorpNoticeLink.linkText || 'BCorp'}
            </MisterSiteLink>
          )}
        </div>
      </div>
    </div>
  )
}
