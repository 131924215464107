import { defineQuery } from 'next-sanity'

import { SupportedLocale } from '../../../domain/constants'
import { coalesceQuery } from '../../utils'
import { COOKIE_TYPE_FRAGMENT, CookieType } from '../components/cookieType.fragment'
import { Link, LINK_FRAGMENT } from '../components/link.fragment'
import { RICH_TEXT_FRAGMENT, RichText } from '../components/richText.fragment'

export interface CookieBanner extends CookieCustomiser {
  cookieBannerTitle: string
  cookieBannerDescription: RichText
  cookieBannerButtonLabelAcceptAll: string
  cookieBannerButtonLabelCustomise: string
  cookieBannerCustomisePage?: Link
}

export interface CookieCustomiser {
  customiserTitle: string
  customiseCopy?: RichText
  cookieTypes?: CookieType[]
  closeCustomiserLabel?: string
}

export const COOKIE_BANNER_FRAGMENT = (locale: SupportedLocale = 'en') =>
  defineQuery(`
  ${coalesceQuery('cookieBannerTitle', locale)},
  ${RICH_TEXT_FRAGMENT(locale, 'cookieBannerDescription')},
  ${coalesceQuery('cookieBannerButtonLabelCustomise', locale)},
  ${coalesceQuery('cookieBannerCustomisePage', locale)},
  ${coalesceQuery('cookieBannerButtonLabelAcceptAll', locale)},
  defined(cookieBannerCustomisePage) => {
    cookieBannerCustomisePage-> {
      ${LINK_FRAGMENT(locale)}
    }
  },
  ${coalesceQuery('customiserTitle', locale)},
  cookieTypes[]-> {
    ${COOKIE_TYPE_FRAGMENT(locale)}
  },
  defined(customiseCopy) => {
    ${RICH_TEXT_FRAGMENT(locale, 'customiseCopy')}
  },
  defined(closeCustomiserLabel) => {
    ${coalesceQuery('closeCustomiserLabel', locale)}
  },
`)
