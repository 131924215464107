import { FunctionComponent, useEffect, useState } from 'react'
import { Info } from 'lucide-react'
import { useRouter } from 'next/router'

import { SupportedLocale } from 'data-access/domain/constants'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@ui/components/ui/tooltip'

import { getTrustpilotUrl } from '../../../utils/reviews.util'
import MisterIcon from '../../shared/MisterIcon'
import MisterRating from '../../shared/review/MisterRating'
import MisterSiteLink from '../../shared/site-link/MisterSiteLink'

interface Props {
  reviewRating: number | null
  reviewCount: number | null
  reviewExplanationTooltip?: string
  hideLogo?: boolean
}

const MisterFooterReviewRating: FunctionComponent<Props> = ({ reviewRating, reviewCount, reviewExplanationTooltip, hideLogo = false }) => {
  const { locale } = useRouter()
  const trustPilotLink = getTrustpilotUrl(locale as SupportedLocale)
  const [overAllRatingFormatted, setOverAllRatingFormatted] = useState<string>('')
  const reviewExplanationTooltipWithCount = reviewExplanationTooltip?.replace('{count}', String(reviewCount ?? 0))

  useEffect(() => {
    setOverAllRatingFormatted(new Intl.NumberFormat(locale, { maximumSignificantDigits: 2 }).format(reviewRating ?? 0))
  }, [locale, reviewRating])

  if (!reviewRating || !reviewCount || !trustPilotLink) {
    return null
  }

  return (
    <div
      className='grid auto-cols-max grid-flow-col items-center justify-start gap-2 hover:cursor-pointer'
      title={reviewExplanationTooltipWithCount}
      aria-label={reviewExplanationTooltipWithCount}
    >
      {!hideLogo && (
        <MisterSiteLink link={{ type: 'external', externalLink: trustPilotLink }} title='Trustpilot'>
          <MisterIcon type='trustpilot' className='-mt-0.5 size-6' ariaLabel='Trustpilot' />
        </MisterSiteLink>
      )}
      <MisterSiteLink link={{ type: 'external', externalLink: trustPilotLink }} title='Trustpilot'>
        <MisterRating rating={reviewRating} max={5} size='medium' setMicrodata={false} />
      </MisterSiteLink>
      <span className='relative top-px text-body-sm font-semibold'>
        ({overAllRatingFormatted}/{5})
      </span>
      {reviewExplanationTooltip && (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger className='hidden cursor-help pt-0.5 lg:block'>
              <Info className='size-4 opacity-70' />
            </TooltipTrigger>
            <TooltipContent>{reviewExplanationTooltipWithCount}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </div>
  )
}

export default MisterFooterReviewRating
