import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { LINK_FRAGMENT, LinkSchema } from '../components/link.fragment'
import { RICH_TEXT_FRAGMENT, RichTextSchema } from '../components/richText.fragment'

export const AnnouncementBarItemSchema = z.object({
  body: RichTextSchema,
  link: LinkSchema,
})

export type AnnouncementBarItem = z.infer<typeof AnnouncementBarItemSchema>

export const AnnouncementBarSchema = z.object({
  announcementBarItems: z.array(AnnouncementBarItemSchema),
})

export type AnnouncementBar = z.infer<typeof AnnouncementBarSchema>

export const ANNOUNCEMENT_BAR_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  announcementBarItems[]-> {
    ${RICH_TEXT_FRAGMENT(locale, 'text', 'body')},
    link-> {
      ${LINK_FRAGMENT(locale)}
    },
  }
`)
