import { ElementType, FunctionComponent } from 'react'

import { SectionBannerCtaData } from 'data-access/sanity/fragments/sections/sectionBannerCta.fragment'
import { cn } from 'ui/lib/utils'

import MisterCtaButton from 'src/components/shared/MisterCtaButton'
import { easiesFont, festiveFont } from 'src/utils/font.util'
import MisterMedia from '../../shared/media/MisterMedia'
import MisterContainer from '../../shared/MisterContainer'

// TODO: Removing logic in favour of templated design

interface Props {
  data: SectionBannerCtaData
  index: number
  container?: ElementType
  priority?: boolean
  fixedAspectRatio?: boolean
}

const SectionBannerCta: FunctionComponent<Props> = ({
  data: {
    _id,
    copyPosition = 'middle',
    copyPositionDesktop = 'middle',
    copyPositionVertical,
    copyPositionVerticalDesktop,
    ctaToBottom,
    cta,
    media,
    padding,
    subtitle,
    title,
    titleCase,
    titleFont,
    textShadowTitle,
    textShadowSubtitle,
    hideTitle,
    darkTitle,
    darkSubtitle,
  },
  index,
  container,
  priority,
  fixedAspectRatio = false,
}) => {
  const TitleTag = index === 0 ? 'h1' : 'p'
  const Element = index === 0 && container === undefined ? 'header' : container || 'section'
  const isAW24Banner = titleFont === 'aw24'
  const isFestiveBanner = titleFont === 'festive'
  const isEasiesBanner = titleFont === 'easies'

  return (
    <MisterContainer type='fluid' as={Element} dataLabel='section-banner-cta' padding={false} className={cn('relative bg-brand-blue', padding && 'm-6 md:m-12')} data-id={_id}>
      <div className='relative after:absolute after:inset-0 after:bg-black/10'>
        <MisterMedia
          media={media}
          sectionIndex={index}
          priority={priority}
          className={cn('object-cover object-center', fixedAspectRatio && 'aspect-square md:aspect-[3/1]')}
          mobileSizes={padding ? '95vw' : '100vw'}
          desktopSizes={padding ? '95vw' : '100vw'}
        />
      </div>
      <div
        className={cn(
          'absolute bottom-2 left-1/2 top-2 grid w-full max-w-[1330px] -translate-x-1/2 grid-cols-12 gap-3',
          padding && 'md:px-12',
          copyPositionVertical === 'bottom' ? 'bottom-[10%] content-end' : 'bottom-0 content-center',
          copyPositionVerticalDesktop === 'bottom' ? 'md:bottom-[20%] md:content-end' : 'md:bottom-0 md:content-center',
        )}
      >
        <div
          className={cn(
            copyPosition === 'left' ? 'items-start' : '',
            copyPosition === 'middle' ? 'items-center' : '',
            copyPosition === 'right' ? 'items-end' : '',
            copyPositionDesktop === 'left' ? 'md:items-start' : '',
            copyPositionDesktop === 'middle' ? 'md:items-center' : '',
            copyPositionDesktop === 'right' ? 'md:items-end' : '',
            'isolate col-span-10 col-start-2 flex flex-col gap-8',
          )}
        >
          <div
            className={cn(
              copyPosition === 'left' ? 'items-start' : '',
              copyPosition === 'middle' ? 'items-center text-center' : '',
              copyPosition === 'right' ? 'items-end text-right' : '',
              copyPositionDesktop === 'left' ? 'md:items-start md:text-left' : '',
              copyPositionDesktop === 'middle' ? 'md:items-center md:text-center' : '',
              copyPositionDesktop === 'right' ? 'md:items-end md:text-right' : '',
              'flex max-w-[80%] flex-col gap-2 md:max-w-[100%] lg:max-w-[80%] xl:max-w-[60%]',
            )}
          >
            <TitleTag
              className={cn(
                'font-bold',
                isFestiveBanner && `font-festive text-heading-3-festive font-normal md:text-heading-1-festive`,
                isEasiesBanner && `font-easies text-heading-3-easies font-normal md:text-heading-1-easies`,
                isAW24Banner && 'text-heading-3-aw24 md:text-heading-1-aw24',
                !isAW24Banner && !isFestiveBanner && !isEasiesBanner && 'text-heading-3 md:text-heading-1',
                textShadowTitle === 'large' && 'drop-shadow-brand-lg md:drop-shadow-brand-lg',
                textShadowTitle === 'default' && 'drop-shadow-brand-md md:drop-shadow-brand-md',
                darkTitle?.mobile ? 'text-brand-blue !drop-shadow-none' : 'text-white',
                darkTitle?.desktop ? 'md:text-brand-blue md:!drop-shadow-none' : 'md:text-white',
                hideTitle && 'sr-only',
              )}
            >
              {title}
            </TitleTag>
            {subtitle && (
              <p
                className={cn(
                  'text-body-lg-bold md:text-body-xl-bold',
                  !darkSubtitle?.mobile && textShadowSubtitle === 'large' && 'drop-shadow-brand-lg',
                  !darkSubtitle?.mobile && textShadowSubtitle === 'default' && 'drop-shadow-brand-md',
                  !darkSubtitle?.desktop && textShadowSubtitle === 'large'
                    ? 'md:drop-shadow-brand-lg'
                    : !darkSubtitle?.desktop && textShadowSubtitle === 'default'
                      ? 'md:drop-shadow-brand-md'
                      : 'md:drop-shadow-none',
                  darkSubtitle?.mobile ? 'text-brand-blue' : 'text-white',
                  darkSubtitle?.desktop ? 'md:text-brand-blue' : 'md:text-white',
                )}
              >
                {subtitle}
              </p>
            )}
          </div>
          {cta && (
            <div className={cn(ctaToBottom?.mobile && 'absolute bottom-[10%]', ctaToBottom?.desktop ? 'md:absolute md:bottom-[20%]' : 'md:static md:bottom-[unset]')}>
              <MisterCtaButton cta={{ ...cta, size: 'large' }} />
            </div>
          )}
        </div>
      </div>
    </MisterContainer>
  )
}

export default SectionBannerCta
