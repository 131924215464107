import { FunctionComponent } from 'react'

import { Section as SectionType } from 'data-access/sanity/fragments/pages/sections.fragment'

import SectionAbTester from './section-ab-tester/sectionAbTester'
import SectionActivateAccount from './section-activate-account/section-activate-account'
import SectionAftership from './section-aftership/section-aftership'
import SectionArticleOverview from './section-article-overview/section-article-overview'
import SectionArticle from './section-article/section-article'
import SectionBannerCarousel from './section-banner-carousel/section-banner-carousel'
import SectionBannerCta from './section-banner-cta/section-banner-cta'
import SectionBulletPoints from './section-bullet-points/section-bullet-points'
import SectionBusinessReviews from './section-business-reviews/section-business-reviews'
import SectionCare from './section-care/section-care'
import SectionContact from './section-contact/section-contact'
import SectionCookieSettings from './section-cookie-settings/section-cookie-settings'
import SectionError from './section-error/section-error'
import SectionFaq from './section-faq/section-faq'
import SectionForgotPassword from './section-forgot-password/section-forgot-password'
import SectionHalfAndHalf from './section-half-and-half/section-half-and-half'
import SectionHighlights from './section-highlights/section-highlights'
import SectionImageLink from './section-image-link/SectionImageLink'
import SectionImageTextList from './section-image-text-list/section-image-text-list'
import SectionInvalidToken from './section-invalid-token/section-invalid-token'
import SectionLayout from './section-layout/sectionLayout'
import SectionLocalizer from './section-localizer/sectionLocalizer'
import SectionLogin from './section-login/section-login'
import SectionMedia from './section-media/section-media'
import SectionNavigation from './section-navigation/section-navigation'
import SectionOrdersOverview from './section-orders-overview/section-orders-overview'
import SectionOverviewCards from './section-overview-cards/section-overview-cards'
import SectionPadding from './section-padding/section-padding'
import SectionPersonalInfo from './section-personal-info/section-personal-info'
import SectionProductCard from './section-prodict-card/section-product-card'
import SectionPromo from './section-promo/section-promo'
import SectionRegistration from './section-registration/section-registration'
import SectionResetPassword from './section-reset-password/section-reset-password'
import SectionReturnForm from './section-return-form/section-return-form'
import SectionSearchFiltersGrid from './section-search-filter-grid/section-search-filters-grid'
import SectionSearchResults from './section-search-results/section-search-results'
import SectionShippingInformation from './section-shipping-information/section-shipping-information'
import SectionSizeCharts from './section-size-charts/section-size-charts'
import SectionSlider from './section-slider/section-slider'
import SectionStoreIndex from './section-store-index/sectionStoreIndex'
import SectionStore from './section-store/section-store'
import SectionStories from './section-stories/section-stories'
import SectionSupportInfo from './section-support-info/section-support-info'
import SectionSupportOverview from './section-support-overview/section-support-overview'
import SectionTaggedImage from './section-tagged-image/SectionTaggedImage'
import SectionTextMediaCta from './section-text-media-cta/section-text-media-cta'
import SectionText from './section-text/section-text'
import SectionTwoColumns from './section-two-columns/section-two-columns'
import SectionWishlist from './section-wishlist/section-wishlist'

interface Props<S = SectionType> {
  index: number
  section: S
  // rest
  [key: string]: any
}

interface SectionProps<S = any> {
  data: Props<S>['section']
  index: number
  // rest
  [key: string]: any
}

const Section: FunctionComponent<Props> = ({ index, section, ...rest }) => {
  const type = section._type

  const commonProps: SectionProps = {
    data: section,
    index,
    ...rest,
  }

  // Key has to match section._type from Sanity
  const sectionMapper: Record<string, JSX.Element> = {
    sectionBannerCta: <SectionBannerCta {...commonProps} />,
    sectionText: <SectionText {...commonProps} />,
    sectionTwoColumns: <SectionTwoColumns {...commonProps} />,
    sectionTextMediaCta: <SectionTextMediaCta {...commonProps} />,
    sectionImageTextList: <SectionImageTextList {...commonProps} />,
    sectionMedia: <SectionMedia {...commonProps} />,
    sectionContact: <SectionContact {...commonProps} />,
    sectionSlider: <SectionSlider {...commonProps} />,
    sectionArticleOverview: <SectionArticleOverview {...commonProps} />,
    sectionStore: <SectionStore {...commonProps} />,
    sectionSizeCharts: <SectionSizeCharts />,
    sectionPadding: <SectionPadding {...commonProps} />,
    sectionCookieSettings: <SectionCookieSettings {...commonProps} />,
    sectionNavigation: <SectionNavigation {...commonProps} />,
    sectionReturnForm: <SectionReturnForm {...commonProps} />,
    sectionAftership: <SectionAftership {...commonProps} />,
    sectionStories: <SectionStories {...commonProps} />,
    sectionBannerCarousel: <SectionBannerCarousel {...commonProps} />,
    sectionWishlist: <SectionWishlist {...commonProps} />,
    sectionSearchResults: <SectionSearchResults {...commonProps} />,
    sectionError: <SectionError {...commonProps} />,
    sectionLogin: <SectionLogin {...commonProps} />,
    sectionForgotPassword: <SectionForgotPassword {...commonProps} />,
    sectionRegistration: <SectionRegistration {...commonProps} />,
    sectionActivateAccount: <SectionActivateAccount {...commonProps} />,
    sectionResetPassword: <SectionResetPassword {...commonProps} />,
    sectionInvalidToken: <SectionInvalidToken {...commonProps} />,
    sectionOrdersOverview: <SectionOrdersOverview />,
    sectionPersonalInfo: <SectionPersonalInfo />,
    sectionBusinessReviews: <SectionBusinessReviews {...commonProps} />,
    sectionOverviewCards: <SectionOverviewCards {...commonProps} />,
    sectionSearchFiltersGrid: <SectionSearchFiltersGrid {...commonProps} />,
    sectionHighlights: <SectionHighlights {...commonProps} />,
    sectionStoreIndex: <SectionStoreIndex {...commonProps} />,
    sectionLocalizer: <SectionLocalizer {...commonProps} />,
    pagePressRelease: <SectionArticle {...commonProps} />,
    pageArticle: <SectionArticle {...commonProps} />,
    sectionFaq: <SectionFaq {...commonProps} />,
    sectionShippingInformation: <SectionShippingInformation {...commonProps} />,
    sectionCare: <SectionCare {...commonProps} />,
    sectionBulletPoints: <SectionBulletPoints {...commonProps} />,
    sectionHalfAndHalf: <SectionHalfAndHalf {...commonProps} />,
    sectionSupportOverview: <SectionSupportOverview {...commonProps} />,
    sectionSupportInfo: <SectionSupportInfo {...commonProps} />,
    sectionLayout: <SectionLayout {...commonProps} />,
    sectionAbTester: <SectionAbTester {...commonProps} />,
    taggedImage: <SectionTaggedImage {...commonProps} />,
    shopTheLookImage: <SectionTaggedImage {...commonProps} />,
    sectionProductCard: <SectionProductCard {...commonProps} />,
    sectionPromo: <SectionPromo {...commonProps} />,
    sectionImageLink: <SectionImageLink {...commonProps} />,
  }

  if (process.env.NODE_ENV !== 'production' && sectionMapper?.[type] === undefined) {
    console.error(`No functional component found to render Sanity section ${type}.`)

    return (
      <p className='bg-brand-red text-body-2 mx-6 my-4 rounded px-10 py-3 text-center text-white'>
        No functional component found to render Sanity section <span className='font-mono text-[0.9em]'>{type}</span>.
      </p>
    )
  }

  return sectionMapper[type]
}

export default Section
