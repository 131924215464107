import { CSSProperties, FunctionComponent, HTMLAttributes } from 'react'
import Image from 'next/image'
import { useMedia } from 'react-use'

import { sanityImageLoader, urlForImage } from 'data-access/sanity/clients/sanityImageUrlBuilder'
import { Image as ImageType } from 'data-access/sanity/fragments/common/getImage.fragment'
import { cn } from 'ui/lib/utils'

export interface MisterImageProps {
  desktop?: ImageType
  mobile?: ImageType
  priority?: boolean
  alt?: string
  className?: string | null
  desktopSizes?: string
  mobileSizes?: string
  itemProp?: HTMLAttributes<HTMLImageElement>['itemProp']
}

const MisterImage: FunctionComponent<MisterImageProps> = ({
  desktop = null,
  mobile = null,
  priority = false,
  alt = '',
  className,
  desktopSizes = '(max-width: 480px) 480px, (max-width: 768px) 768px, (max-width: 1281px) 1281px, 1920px',
  mobileSizes = '(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw',
  itemProp,
}) => {
  const imageDesktop = urlForImage(desktop || undefined)
  const imageMobile = urlForImage(mobile || undefined)
  const isDesktop = useMedia('(min-width: 768px)', false)

  // Used to be a default Sanity value that is now saved :(
  // todo: remove one day when all images have alt values in Sanity
  const sensibleAlt = alt === 'Image Alt Text' ? '' : alt

  // Only use priority loading on desktop if it doesn't happen on mobile to not load image twice.
  const desktopPriority = priority && !mobile

  if (imageDesktop === null && imageMobile === null) {
    return null
  }

  return (
    <>
      {desktop && imageDesktop && (
        <Image
          {...imageDesktop}
          style={desktop.hotspot && ({ '--x': `${desktop.hotspot?.x * 100}%`, '--y': `${desktop.hotspot?.y * 100}%` } as CSSProperties)}
          className={cn('relative hidden md:block md:size-full', desktop.hotspot && 'object-[var(--x),var(--y)]', className)}
          sizes={desktopSizes}
          priority={desktopPriority}
          alt={sensibleAlt}
          loader={sanityImageLoader}
          blurDataURL={desktop.lqip}
          placeholder={desktop.lqip && desktop?.isOpaque ? 'blur' : undefined}
          itemProp={itemProp}
        />
      )}
      {mobile && imageMobile && (
        <Image
          {...imageMobile}
          style={mobile.hotspot && ({ '--x': `${mobile.hotspot?.x * 100}%`, '--y': `${mobile.hotspot?.y * 100}%` } as CSSProperties)}
          className={cn(desktop && 'relative w-full md:hidden', mobile.hotspot && 'object-[var(--x),var(--y)]', className)}
          sizes={isDesktop && !desktop ? desktopSizes : mobileSizes}
          priority={priority}
          alt={sensibleAlt}
          loader={sanityImageLoader}
          blurDataURL={mobile.lqip}
          placeholder={mobile.lqip && mobile?.isOpaque ? 'blur' : undefined}
          itemProp={itemProp}
        />
      )}
    </>
  )
}

export default MisterImage
