import { title } from 'process'

import { FunctionComponent } from 'react'

import { SectionSliderData, SliderCardItemData } from 'data-access/sanity/fragments/sections/sectionSlider.fragment'
import { Badge } from '@ui/components/ui/badge'
import { cn } from '@ui/lib/utils'
import { convertSlug } from 'utilities/string/url'

import MisterMedia from 'src/components/shared/media/MisterMedia'
import MisterContainer from 'src/components/shared/MisterContainer'
import MisterSwiper from 'src/components/shared/MisterSwiper'
import MisterSiteLink from 'src/components/shared/site-link/MisterSiteLink'
import { useTranslations } from 'src/contexts/Globals.context'
import { useExperimentFeatureFlag } from 'src/hooks/posthog/useExperimentFeatureFlag'

interface SliderCardProps {
  item: SliderCardItemData
  index: number
  isLegacy: boolean
}

const SliderCard: FunctionComponent<SliderCardProps> = ({ item, index, isLegacy }) => {
  const translate = useTranslations()
  const { title, subtitle, link, image } = item
  const adaptedLink = link?._type === 'link' ? link : convertSlug(link, 'collection')

  return isLegacy ? (
    <MisterSiteLink
      className='flex w-[16rem] min-w-[16rem] select-none snap-center snap-always flex-col text-center md:w-[16rem] md:min-w-[16rem]'
      link={adaptedLink}
      title={`${title} | ${subtitle} | MR MARVIS`}
    >
      <div className='relative overflow-hidden bg-brand-grey'>
        <MisterMedia
          priority={index <= 1}
          className='aspect-[4/5] transition-transform duration-75 ease-in-out md:hover:scale-105'
          media={image}
          desktopSizes='16rem'
          mobileSizes='16rem'
        />
      </div>
      <div className='flex flex-col !bg-brand-beige-light bg-white px-1 py-4'>
        <p className='mb-1 text-body-sm-bold md:text-body-md-bold'>{title}</p>
        <p className='truncate text-body-sm'>{subtitle}</p>
      </div>
    </MisterSiteLink>
  ) : (
    <MisterSiteLink link={adaptedLink} title={`${title} | ${subtitle} | MR MARVIS`} className='flex w-auto flex-shrink-0 snap-center flex-col gap-4 lg:w-60'>
      <div className='relative overflow-hidden rounded-md'>
        <MisterMedia
          priority={index <= 1}
          className='h-full min-h-[320px] w-full min-w-[240px] transform-gpu object-cover transition-transform duration-300 hover:scale-105'
          media={image}
          desktopSizes='16rem'
          mobileSizes='16rem'
        />
      </div>
      <div className='flex flex-col gap-1'>
        <div>
          {item?.new && (
            <Badge size='lg' className='float-right mx-1'>
              {translate('new', 'New')}
            </Badge>
          )}
          <span className='text-body-xl-bold text-brand-blue'>{title}</span>
        </div>
        <span className='text-pretty pr-4 text-body-md text-brand-blue'>{subtitle}</span>
      </div>
    </MisterSiteLink>
  )
}

interface SectionSliderProps {
  data: SectionSliderData
}

const SectionSlider: FunctionComponent<SectionSliderProps> = ({ data }) => {
  const { title, items } = data || {}

  // A/B test Section Slider Redesign
  const flag = 'section-slider-redesign'
  const variant: 'control' | 'legacy' | string | boolean | undefined = useExperimentFeatureFlag(flag)
  const isLegacy = variant === 'legacy'

  if (items.length === 0) {
    return <ul />
  }

  return (
    <MisterContainer type='fluid' dataLabel='section-slider' data-id={data._id} padding={false}>
      {title && <h2 className={cn(isLegacy && 'text-center', 'mb-8 px-6 text-heading-5 lg:text-heading-3')}>{title}</h2>}
      <MisterSwiper slideClassName={isLegacy ? 'w-64' : '!w-[240px]'} containerClassName='!px-4 lg:!px-8'>
        {items.map((item, index) => (
          <SliderCard key={index} index={index} item={item} isLegacy={isLegacy} />
        ))}
      </MisterSwiper>
    </MisterContainer>
  )
}

export default SectionSlider
