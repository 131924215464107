import { geolocation, ipAddress as ipAddress1 } from '@vercel/functions'
import { ResponseCookie } from 'next/dist/compiled/@edge-runtime/cookies'
import type { NextRequest } from 'next/server'
import { NextResponse, userAgent } from 'next/server'

import { SupportedLocale } from 'data-access/domain/constants'
import { sanityCdnClient } from 'data-access/sanity/clients/sanityClient'
import { coalesceFilter } from 'data-access/sanity/utils'
import { getErrorMessage } from 'utilities/errors/errorMessage'

import { defaultCookieConfig as baseCookieConfig } from './utils/cookie.util'

// Cloudflare country code
// @see https://developers.cloudflare.com/fundamentals/reference/http-request-headers/#cf-ipcountry
export const I18N_COUNTRY_CODE_RAW: string = 'i18nCountryCodeRaw'

// Used for geolocation specific information in the Announcement Bar and Service Usps
export const I18N_COUNTRY_CODE: string = 'i18nCountryCode'

// Used for automatic redirecting to user's locale preference
export const I18N_COUNTRY_CODE_PREFERENCE: string = 'i18nCountryCodePreference'

// Used for keeping track of how many pages were viewed with the redirection
// notice visible so we can hide it automatically on the third page view
export const I18N_PAGE_VIEW_COUNT_WITH_NOTICE: string = 'i18nPageViewCountWithNotice'

// MR MARVIS office IP to keep track of office website visits
export const OFFICE_IP_ADDRESSES = ['89.98.162.50', '89.98.157.157']

// Custom Elevar user id (having 1st party cookie improves cookie lifetime since tracking prevent is not removing it)
// @see https://docs.getelevar.com/docs/how-to-pass-my-own-user-identifier
export const ELEVAR_USER_ID_COOKIE = 'customUserId'

const verbose = process.env.NODE_ENV === 'development'
const supportedLocales = ['en', 'nl', 'gb', 'dk', 'it', 'de', 'fr', 'es', 'ch']

const log = (message?: any, ...optionalParams: any[]) => {
  if (verbose) {
    console.log(`\x1b[45mmiddleware - ${message}`, ...optionalParams, '\x1b[0m')
  }
}

const findPageType = (pathname: string): string => {
  if (pathname.includes('/collections/')) {
    return '/collections'
  }

  if (pathname.includes('/products/')) {
    return '/products'
  }

  if (pathname.includes('/blog/')) {
    return '/blog'
  }
  if (pathname.includes('/press-releases/')) {
    return '/press-releases'
  }

  if (pathname.includes('/account/') || pathname.includes('/account')) {
    return '/account'
  }

  return ''
}

const defaultCookieConfig: Partial<ResponseCookie> = {
  ...baseCookieConfig,
  sameSite: 'strict',
  expires: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000),
}

export async function middleware(req: NextRequest) {
  const res = NextResponse.next()
  const { isBot } = userAgent(req)

  // Exclude bots from middleware
  if (isBot || userAgent(req).ua.includes('AhrefsBot')) {
    return res
  }

  log('init', req.nextUrl.pathname)

  const { cookies, nextUrl, url } = req || {}
  const geo = geolocation(req)
  const ipAddress = req.headers.get('cf-connecting-ip') ?? ipAddress1(req)
  const customUserId = cookies.get(ELEVAR_USER_ID_COOKIE)?.value

  // Custom user ID for Elevar
  // @see https://docs.getelevar.com/docs/how-to-pass-my-own-user-identifier
  if (!customUserId) {
    res.cookies.set(ELEVAR_USER_ID_COOKIE, crypto.randomUUID(), defaultCookieConfig)
  }

  // Use Cloudflare header to determine if the request is coming from the MR MARVIS office
  res.cookies.set('isOfficeRequest', String(OFFICE_IP_ADDRESSES.includes(ipAddress || '')), {
    ...defaultCookieConfig,
    expires: undefined,
  })

  // Cloudflare county code header, passing along to the frontend in raw format
  // so that we can, for instance, pass this along to Shopify when creating a cart
  // @see https://developers.cloudflare.com/fundamentals/reference/http-request-headers/#cf-ipcountry
  const cloudflareCountryCode = req.headers.get('cf-ipcountry')

  if (
    // If coming from Shopify via a 'special' link such as a coupon or account activation or reset then skip redirects
    nextUrl.search.includes('coupon') ||
    nextUrl.pathname.includes('/activate') ||
    nextUrl.pathname.includes('/reset') ||
    // If destination URL is from an email they received in their specific
    // language, the user expects to end on that website, regardless of where
    // they are currently located.
    url.includes('forceLocale') ||
    // Per request from marketing, we should not redirect users when they are from Luxembourg
    // or Belgium since we want to force-link them to our French locale for instance
    ['lu', 'be'].includes(cloudflareCountryCode || '')
  ) {
    const newLocale = nextUrl.locale ? nextUrl.locale.toLowerCase() : 'en'

    // Set cookie to persist the locale from the URL, so the user is not
    // hit with automated redirects on follow-up page views
    res.cookies.set(I18N_COUNTRY_CODE_PREFERENCE, newLocale, defaultCookieConfig)
    log('prevent locale detection, go to', newLocale)

    return res
  }

  if (cloudflareCountryCode) {
    res.cookies.set(I18N_COUNTRY_CODE_RAW, cloudflareCountryCode, defaultCookieConfig)
  }

  // We take the country from Cloudflare's header and if that isn't present we fallback
  // to Vercel on preview links, then the requested locale, and finally to en
  const geoInformation =
    cloudflareCountryCode && supportedLocales.includes(cloudflareCountryCode?.toLowerCase() || '')
      ? cloudflareCountryCode.toLowerCase()
      : process.env?.NEXT_PUBLIC_VERCEL_ENV === 'preview' && geo?.country && supportedLocales.includes(geo.country.toLowerCase())
        ? geo.country.toLowerCase()
        : nextUrl.locale
          ? nextUrl.locale.toLowerCase()
          : 'en'

  // If no previous preference cookie is present assign geo information as preference
  if (!cookies.has(I18N_COUNTRY_CODE_PREFERENCE)) {
    log('set initial locale preference', geoInformation)

    res.cookies.set(I18N_COUNTRY_CODE_PREFERENCE, geoInformation, defaultCookieConfig)
  }

  // If no previous geo cookie is present or the latest geo information does
  // not match previous geo cookie then assign geo information as geo cookie
  if (!cookies.has(I18N_COUNTRY_CODE) || cookies.get(I18N_COUNTRY_CODE)?.value !== geoInformation) {
    log('change locale preference', geoInformation)

    res.cookies.set(I18N_COUNTRY_CODE, geoInformation, defaultCookieConfig)
  }

  // As pref cookie may not yet be set variable to either the cookie value or the geoInformation
  const prefCountry = cookies.has(I18N_COUNTRY_CODE_PREFERENCE) ? cookies.get(I18N_COUNTRY_CODE_PREFERENCE)?.value : geoInformation

  const pageViewCount = parseInt(cookies.get(I18N_PAGE_VIEW_COUNT_WITH_NOTICE)?.value || '0')

  if (cookies.get('showRedirectNotification2')?.value === 'true') {
    if (pageViewCount >= 3) {
      res.cookies.set('showRedirectNotification2', 'false', defaultCookieConfig)
    } else {
      res.cookies.set(I18N_PAGE_VIEW_COUNT_WITH_NOTICE, String(pageViewCount + 1), defaultCookieConfig)
    }
  } else if (!cookies.get(I18N_PAGE_VIEW_COUNT_WITH_NOTICE) || pageViewCount === 0) {
    res.cookies.set(I18N_PAGE_VIEW_COUNT_WITH_NOTICE, '0', defaultCookieConfig)
  }

  // Check if the country picker was used so we know whether to show the redirection notification
  if (nextUrl.searchParams.get('redirected') && cookies.get('usedCountryPicker')?.value !== 'true') {
    res.cookies.set('showRedirectNotification2', 'true', defaultCookieConfig)
    res.cookies.set(I18N_PAGE_VIEW_COUNT_WITH_NOTICE, '0', defaultCookieConfig)
  }

  // If the country picker is used when the redirection notice is showing then hide the redirection notice
  if (cookies.get('usedCountryPicker')?.value === 'true' && cookies.get('showRedirectNotification2')) {
    res.cookies.set('showRedirectNotification2', 'false', defaultCookieConfig)
    res.cookies.set(I18N_PAGE_VIEW_COUNT_WITH_NOTICE, '0', defaultCookieConfig)
  }

  // If the request locale does not match the preference redirect to preference
  if (nextUrl.locale?.toLowerCase() !== prefCountry) {
    // Decode the pathname to handle encoded characters like the "ß" in German
    const decodedPathname = decodeURIComponent(nextUrl.pathname)
    const pageType = findPageType(decodedPathname)
    const newLocale = prefCountry === 'en' ? '' : `/${prefCountry}`
    const abortController = new AbortController()
    const sanityFetch = sanityCdnClient.fetch(
      `*[_type in ['page', 'pageSearch', 'page404', 'page500', 'pageWishlist', 'pageArticle', 'pagePressRelease', 'homePage', 'collection', 'pageCollection', 'pageWithRequirements', 'pageAccount'] && showInLocales.['${prefCountry}'] != false && ${coalesceFilter(
        'slug',
        nextUrl.locale as SupportedLocale,
      )}.current == "${decodedPathname.split('/').pop()?.split('?')[0]}"][0] {'slug': ${coalesceFilter('slug', prefCountry as SupportedLocale)}.current}.slug`,
      // todo, fix issue with TS 5.3.3
      // @ts-ignore
      { signal: abortController.signal },
    )

    setTimeout(() => abortController.abort(), 200)

    try {
      const newSlug = pageType === '/products' ? decodedPathname : (await sanityFetch) || ''
      const newUrl = new URL(`${newLocale}${pageType === '/products' ? newSlug : `${pageType}/${newSlug}`}`, url)

      return NextResponse.redirect(`${newUrl}${nextUrl.search === '' ? '?' : `${nextUrl.search}&`}${cookies.get('usedCountryPicker')?.value === 'true' ? '' : 'redirected=true'}`)
    } catch (error) {
      console.error(getErrorMessage(error))
    }
  }

  // Set usedCountryPicker to false only if we don't redirect
  if (cookies.get('usedCountryPicker')) {
    res.cookies.set('usedCountryPicker', 'false', defaultCookieConfig)
  }

  return res
}

export const config = {
  matcher: [
    // Exclusions:
    // - /api routes
    // - URLs with a dot (e.g. sitemap.xml, logo.svg, apple-touch-icon.png)
    // - Next.js static resources
    // - Vercel Speed Insights
    // - Language update page (via Klaviyo emails)
    // - Sitemaps
    // - No source
    '/((?!api|.*\\.|_next/static|_next/image|_vercel/speed-insights|img|cert|language-update|sitemaps|%3Cno%20source%3E).*)',
    '/',
  ],
}
