import React, { FunctionComponent } from 'react'
import Image from 'next/image'

import { Button } from '@ui/components/ui/button'
import { cn } from '@ui/lib/utils'

import { useTranslations } from 'src/contexts/Globals.context'
import { useUI } from 'src/contexts/UI.context'
import useCart from 'src/hooks/shop/useCart'
import { imageQuality } from 'src/utils/image.util'

export const CartButton: FunctionComponent = () => {
  const { totalItems } = useCart()
  const { openCart, closeCart, displayCart, displayMobileMenu, displaySearchUI } = useUI()
  const translate = useTranslations()
  const showIcon = displayMobileMenu || displaySearchUI

  return (
    <Button
      asChild
      variant='ghost'
      size='icon'
      aria-label={translate('cart', 'Cart')}
      className={cn(
        'flex cursor-pointer items-center gap-1 transition-[transform,opacity] duration-500 ease-out lg:translate-x-0 lg:translate-y-0 lg:opacity-100',
        showIcon && '-translate-y-5 translate-x-10 opacity-0',
      )}
      onClick={displayCart ? closeCart : openCart}
    >
      <div className='relative'>
        <Image src='/img/shopping-cart.svg' alt={translate('cart', 'Cart')} priority={false} quality={imageQuality} width={24} height={24} />
        <div
          className={cn(
            'absolute right-0 top-0 flex size-4 items-center justify-center rounded-full bg-brand-warning text-center text-body-xs font-semibold text-white transition-all duration-75',
            totalItems > 0 ? 'scale-100 opacity-100' : 'scale-0 opacity-0',
          )}
        >
          {totalItems}
        </div>
      </div>
    </Button>
  )
}
