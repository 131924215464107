import { FunctionComponent, useEffect, useState } from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import { CookieConsentProvider } from '@use-cookie-consent/react'
import { SpeedInsights } from '@vercel/speed-insights/next'
import { DefaultSeo } from 'next-seo'
import { AppProps } from 'next/app'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { SWRConfig } from 'swr'

import MisterScrollMargin from 'src/components/globalState/MisterScrollMargin'
import RecentlyViewedContextProvider from 'src/contexts/RecentlyViewed.context'
import MisterHead from '../components/head/MisterHead'
import MisterAccessibility from '../components/scripts/MisterAccessibility'
import MisterPostHog from '../components/scripts/MisterPostHog'
import MisterScripts from '../components/scripts/MisterScripts'
import MisterRouterProgress from '../components/shared/loader/MisterRouterProgress'
import { MisterCookieConsentProvider } from '../contexts/MisterCookieConsent.context'
import TelemetryContextProvider from '../contexts/Telemetry.context'
import { UIContextProvider } from '../contexts/UI.context'
import { WishlistContextProvider } from '../contexts/Wishlist.context'
import { defaultCookieConfig } from '../utils/cookie.util'
import { easiesFont, festiveFont, misterFont } from '../utils/font.util'
import fetcher, { queryClient } from '../utils/request.util'
import { seo } from '../utils/seo.util'

import 'ui/globals.css'

/**
 * Next.js font loading works all fine, except when elements are injected
 * into the DOM outside the main element (e.g. Cookiebot modal). Hence,
 * set font var manually to root.
 *
 * @see https://nextjs.org/docs/basic-features/font-optimization
 */
const MisterCustomFont: FunctionComponent = () => {
  return (
    <style jsx global>{`
      :root {
        --mm-font-default: ${misterFont.style.fontFamily};
        --mm-font-festive: ${festiveFont.style.fontFamily};
        --mm-font-easies: ${easiesFont.style.fontFamily};
      }
    `}</style>
  )
}

const MisterApp = ({ Component, pageProps, router }: AppProps) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.history.scrollRestoration = 'auto'
    }
  }, [])

  const [callbackExecuted, setCallbackExecuted] = useState({
    forceLocale: false,
    redirected: false,
  })

  useEffect(() => {
    if (router.query.forceLocale && !callbackExecuted.forceLocale) {
      const { forceLocale, ...queryWithoutParam } = router.query
      router.replace({ query: queryWithoutParam }, undefined, { shallow: true })
      setCallbackExecuted({ ...callbackExecuted, forceLocale: true })
    }

    if (router.query.redirected && !callbackExecuted.redirected) {
      const { redirected, ...queryWithoutParam } = router.query
      router.replace({ query: queryWithoutParam }, undefined, { shallow: true })
      setCallbackExecuted({ ...callbackExecuted, redirected: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, callbackExecuted])

  return (
    <>
      <MisterHead />
      <DefaultSeo {...seo} />
      <MisterCustomFont />
      <MisterRouterProgress />
      <MisterScrollMargin />
      <QueryClientProvider client={queryClient}>
        <SWRConfig value={{ fetcher }}>
          <CookieConsentProvider useCookieConsentHooksOptions={{ consentCookieAttributes: defaultCookieConfig }}>
            <MisterCookieConsentProvider>
              <PostHogProvider client={posthog}>
                <TelemetryContextProvider>
                  <RecentlyViewedContextProvider>
                    <MisterScripts />
                    <MisterPostHog />
                    <UIContextProvider>
                      <WishlistContextProvider>
                        <div className={`${misterFont.variable} relative flex min-h-screen flex-col`}>
                          <Component {...pageProps} />
                          <SpeedInsights />
                        </div>
                      </WishlistContextProvider>
                    </UIContextProvider>
                    <MisterAccessibility />
                  </RecentlyViewedContextProvider>
                </TelemetryContextProvider>
              </PostHogProvider>
            </MisterCookieConsentProvider>
          </CookieConsentProvider>
        </SWRConfig>
      </QueryClientProvider>
    </>
  )
}

export default MisterApp
