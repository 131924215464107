import { defineQuery } from 'next-sanity'
import { z } from 'zod'

import { DEFAULT_LOCALE } from '../../../domain/constants'
import { RICH_TEXT_FRAGMENT, RichTextSchema } from './richText.fragment'

export const ProductAnnouncementBarSchema = z.object({
  backgroundColor: z
    .object({
      hex: z.string(),
    })
    .optional(),
  textColor: z
    .object({
      hex: z.string(),
    })
    .optional(),
  content: RichTextSchema.optional(),
  showInLocales: z.boolean().optional(),
})

export type ProductAnnouncementBar = z.infer<typeof ProductAnnouncementBarSchema>

export const PRODUCT_ANNOUNCEMENT_BAR_FRAGMENT = (locale = DEFAULT_LOCALE) =>
  defineQuery(`
  backgroundColor {
    hex
  },
  textColor {
    hex
  },
  ${RICH_TEXT_FRAGMENT(locale, 'content')},
  "showInLocales": showInLocales[$locale],
`)
