import { useMemo } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { z } from 'zod'

import { addLineItems, applyDiscountCode, removeLineItems, replaceLineItems, updateCartAttributes } from 'data-access'
import { equals } from 'utilities/array/compareStringArrays'

import { getCart } from 'src/domain/cart.domain'
import { getCartLocaleKey } from '../../utils/cart.util'
import { get, keys, set } from '../../utils/localStorage.util'

const CartAttributeSchema = z.object({
  key: z.string(),
  value: z.string(),
})

type CartAttribute = z.infer<typeof CartAttributeSchema>
type TrackingData = { queryID?: string; handle?: string }
type LineItem = { merchandiseId: string; quantity: number }

const logError = (context: string, error: unknown) => {
  console.error(`[Cart Error - ${context}]:`, error)
}

const determineAttributeUpdate = (newValue: string, existingAttributes: CartAttribute[], key: string): { attributes: CartAttribute[]; needsUpdate: boolean } => {
  const attributeIndex = existingAttributes.findIndex((attr) => attr.key === key)

  if (attributeIndex !== -1) {
    // If value is the same, no update needed
    if (existingAttributes?.[attributeIndex]?.value === newValue) {
      return { attributes: existingAttributes, needsUpdate: false }
    }

    // Create new attributes array with updated value
    const updatedAttributes = [...existingAttributes.slice(0, attributeIndex), { key, value: newValue }, ...existingAttributes.slice(attributeIndex + 1)]

    return { attributes: updatedAttributes, needsUpdate: true }
  }

  // Append new attribute if not existing
  return {
    attributes: [...existingAttributes, { key, value: newValue }],
    needsUpdate: true,
  }
}

const manageLineItemOrdering = (existingAttributes: CartAttribute[], lines: LineItem[], reorderCart: boolean) => {
  if (!reorderCart) return { attributes: existingAttributes, needsUpdate: false }

  const newLineItems = lines.map((line) => line.merchandiseId)
  const previousLineItems = existingAttributes.find((attr) => attr.key === 'sortedLineItems')?.value.split(',') ?? []

  const combinedLineItems = [...new Set([...newLineItems, ...previousLineItems])]
  const newValue = combinedLineItems.toString()

  return determineAttributeUpdate(newValue, existingAttributes, 'sortedLineItems')
}

const manageAlgoliaTracking = (existingAttributes: CartAttribute[], trackingData?: TrackingData) => {
  if (!trackingData?.queryID || !trackingData?.handle) {
    return { attributes: existingAttributes, needsUpdate: false }
  }

  const newAlgoliaEntry = `${trackingData.handle}:${trackingData.queryID}`
  const previousAlgoliaData =
    existingAttributes
      .find((attr) => attr.key === '_algoliaData')
      ?.value.split(',')
      .filter((entry) => entry && entry.includes(':')) ?? []

  const combinedAlgoliaData = [...new Set([...previousAlgoliaData, newAlgoliaEntry])]
  const newValue = combinedAlgoliaData.toString()

  return determineAttributeUpdate(newValue, existingAttributes, '_algoliaData')
}

const useCart = () => {
  const queryClient = useQueryClient()
  const { locale } = useRouter()
  const cartLocaleKey = getCartLocaleKey(locale)

  const localStorageKey = keys[cartLocaleKey]
  if (!localStorageKey) {
    throw new Error('Unknown local storage key for cart.')
  }

  const {
    data: cart,
    error,
    isLoading,
    refetch: refreshCart,
  } = useQuery({
    queryKey: locale ? ['cart', locale] : ['cart'],
    queryFn: async () => {
      const fetchedCart = await getCart(locale, get(localStorageKey))

      if (!fetchedCart) {
        throw new Error('No cart data retrieved')
      }

      set(localStorageKey, fetchedCart.id)
      return fetchedCart
    },
    enabled: !!locale,
    staleTime: 1000 * 60, // 1 minute
    refetchOnWindowFocus: true,
  })

  if (error) {
    console.error('Error fetching cart:', error)
  }

  const cartId = cart?.id

  const computedCartValues = useMemo(() => {
    const lineItems = cart?.lines || []
    const totalItems = lineItems.reduce((sum, item) => sum + item.quantity, 0)

    return {
      isEmpty: totalItems === 0,
      totalItems,
      currencyCode: cart?.cost?.subtotalAmount?.currencyCode || (locale === 'gb' ? 'GBP' : 'EUR'),
    }
  }, [cart, locale])

  const lineItemVariantIdsInCart = (cart?.lines || []).map(({ merchandise }) => merchandise.id)
  const lineItemOrderingInfo =
    cart?.attributes
      ?.filter((attribute) => attribute?.key === 'sortedLineItems')?.[0]
      ?.value?.split(',')
      ?.filter((gid) => lineItemVariantIdsInCart.includes(gid)) || []

  const sortOrderInfoComplete =
    lineItemOrderingInfo.length > 0 && (cart?.lines || []).length === lineItemOrderingInfo.length && equals(lineItemOrderingInfo, lineItemVariantIdsInCart)

  // Cart Operations
  const cartOperations = useMemo(
    () => ({
      async setCartAttributes(customAttributes: CartAttribute[]) {
        if (!cartId) {
          logError('Set Cart Attributes', 'Cart ID not found')
          return { success: false, error: true }
        }

        try {
          const validAttributes = z.array(CartAttributeSchema).parse(customAttributes)
          const result = await updateCartAttributes(locale, cartId, validAttributes)

          if (process.env.NODE_ENV === 'development') {
            console.debug('Set checkout attributes', { customAttributes, result })
          }
          return { success: true, error: false }
        } catch (error) {
          logError('Set Cart Attributes', error)
          return { success: false, error: true }
        }
      },

      async addItems({ lines, reorderCart = true, trackingData }: { lines: LineItem[]; reorderCart?: boolean; trackingData?: TrackingData }) {
        if (!cartId || !lines.some((line) => line.quantity > 0)) {
          logError('Add Items', 'Cart ID not found or no items to add')
          return { success: false, error: true }
        }

        const { attributes: newAttributes, needsUpdate: sortingNeedsUpdate } = manageLineItemOrdering(cart?.attributes || [], lines, reorderCart)

        const { attributes, needsUpdate: algoliaNeedsUpdate } = manageAlgoliaTracking(newAttributes, trackingData)

        // Update attributes if needed
        if (sortingNeedsUpdate || algoliaNeedsUpdate) {
          const { error } = await cartOperations.setCartAttributes(attributes)
          if (error) {
            return { success: false, error: true }
          }
        }

        const { userErrors } = await addLineItems(locale, cart.id, lines)

        if (userErrors?.length) {
          logError('Add Line Items', userErrors)
          return { success: false, error: true }
        }

        // Optimistically update the query cache
        queryClient.invalidateQueries({ queryKey: ['cart', locale] })

        return { success: true, error: false }
      },

      async removeItems({ lineIds, productHandle }: { lineIds: string[]; productHandle: string }) {
        if (!cartId) {
          logError('Remove Items', 'Cart ID not found')
          return { success: false, error: true }
        }

        const existingAttributes = cart?.attributes || []
        const newAttributes = [...existingAttributes]

        const algoliaDataIndex = newAttributes.findIndex((attr) => attr.key === '_algoliaData')
        if (algoliaDataIndex !== -1) {
          const algoliaEntries = newAttributes[algoliaDataIndex]?.value
            ?.split(',')
            ?.filter((entry) => entry && entry.includes(':'))
            ?.filter((entry) => !entry.startsWith(`${productHandle}:`))

          if (algoliaEntries && algoliaEntries.length > 0) {
            newAttributes[algoliaDataIndex] = {
              key: '_algoliaData',
              value: algoliaEntries.toString(),
            }
          } else {
            // Remove attribute if no entries left
            newAttributes.splice(algoliaDataIndex, 1)
          }

          const { error } = await cartOperations.setCartAttributes(newAttributes)
          if (error) {
            return { success: false, error: true }
          }
        }

        const { userErrors } = await removeLineItems(locale, cart.id, lineIds)
        if (userErrors?.length) {
          logError('Remove Line Items', userErrors)
          return { success: false, error: true }
        }

        refreshCart()
        return { success: true, error: false }
      },

      async replaceItem(item: { id: string; quantity: number }) {
        if (!cartId) {
          logError('Replace Item', 'Cart ID not found')
          return { success: false, error: true }
        }

        const lineItemsToReplace = cart?.lines.reduce(
          (lines, { id, quantity, attributes }) => (id === item.id ? lines : [...lines, { id, quantity, attributes }]),
          item.quantity === 0 ? [] : [item],
        )

        const { userErrors } = await replaceLineItems(locale, cartId, lineItemsToReplace)

        if (userErrors?.length) {
          logError('Replace Item', userErrors)
          return { success: false, error: true }
        }

        refreshCart()
        return { success: true, error: false }
      },

      async addDiscountCode(discountCodes: string[]) {
        if (!cartId) {
          logError('Add Discount Code', 'Cart ID not found')
          return { success: false, error: true }
        }

        const {
          userErrors,
          cart: { discountCodes: discountCodesOnCart },
        } = await applyDiscountCode(locale, cartId, discountCodes)

        if (userErrors?.length) {
          logError('Add Discount Code', userErrors)
          return { success: false, error: true }
        }

        const discountCodeApplied = discountCodesOnCart.find((discountCode) => discountCode.code === discountCodes[0])?.applicable

        if (!discountCodeApplied) {
          return { success: false, error: true }
        }

        refreshCart()
        return { success: true, error: false }
      },
    }),
    [cartId, locale, queryClient, cart?.lines, cart?.attributes],
  )

  return {
    cartId,
    error,
    isLoading: isLoading || cart === undefined,
    cart: {
      ...cart,
      webUrl: `${cart?.checkoutUrl}&shop_pay_checkout_as_guest=true&skip_shop_pay=true`,
    },
    lineItems:
      (sortOrderInfoComplete ? (lineItemOrderingInfo ?? []).map((item) => (cart?.lines ?? []).find(({ merchandise }) => item === merchandise?.id)) : cart?.lines || []).filter(
        Boolean,
      ) || [],
    ...cartOperations,
    ...computedCartValues,
  }
}

export default useCart
