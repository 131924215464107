import { FormEvent, FunctionComponent, useContext } from 'react'
import { LoaderCircle } from 'lucide-react'

import { AccountData } from 'data-access/sanity/fragments/globals/account.fragment'
import { cn } from 'ui/lib/utils'
import { Button } from '@ui/components/ui/button'

import { AccountContext } from 'src/contexts/Account.context'
import { useTranslations } from '../../../../../contexts/Globals.context'
import useUpdateAccountInfo from '../../../../../hooks/customer/useUpdateAccountInfo'
import MisterInput from '../../../../shared/input/MisterInput'

interface UserInfoFormProps {
  inputFields: { [key: string]: any }
  accountData: AccountData
  updateFields: (e: any) => void
}

const UserInfoForm: FunctionComponent<UserInfoFormProps> = ({ inputFields, accountData, updateFields }) => {
  const { customer } = useContext(AccountContext)
  const translate = useTranslations()
  const { changeEmailMessage, saveInfo } = accountData || {}

  const {
    updateAccountInfo,
    state: { response, loading, error },
  } = useUpdateAccountInfo()

  if (!customer?.isLoggedIn) {
    return null
  }

  const handleSaveUserInfo = async (e: FormEvent) => {
    e.preventDefault()
    await updateAccountInfo(customer?.customerAccessToken, {
      firstName: inputFields?.firstName || customer?.firstName,
      lastName: inputFields?.lastName || customer?.lastName,
    })
  }

  return (
    <form onSubmit={(e) => handleSaveUserInfo(e)} className='mb-8 grid w-full max-w-2xl grid-cols-2 gap-x-4 lg:w-[57%]'>
      <MisterInput
        wrapperClassName='col-span-2'
        inputClassName='!bg-brand-grey'
        type='email'
        label={translate('email')}
        name='email'
        id='userInfoEmail'
        placeholder={translate('email')}
        onChangeHandler={updateFields}
        value={customer?.email}
        autoComplete='email'
        disabled={true}
        maskForAnalytics={true}
      />
      <p className='col-span-2 -mt-1 text-body-sm text-brand-medium-grey lg:-mt-2'>{changeEmailMessage}</p>
      <MisterInput
        wrapperClassName='col-span-2 lg:col-span-1 mt-6'
        inputClassName='!bg-brand-grey'
        type='text'
        label={translate('firstName')}
        name='firstName'
        id='firstName'
        placeholder={translate('firstName')}
        onChangeHandler={updateFields}
        value={inputFields?.firstName}
        autoComplete='given-name'
        required={true}
        maskForAnalytics={true}
      />
      <MisterInput
        wrapperClassName='col-span-2 lg:col-span-1 mt-6'
        inputClassName='!bg-brand-grey'
        type='text'
        label={translate('lastName')}
        name='lastName'
        id='lastName'
        placeholder={translate('lastName')}
        onChangeHandler={updateFields}
        value={inputFields?.lastName}
        autoComplete='family-name'
        required={true}
        maskForAnalytics={true}
      />
      <div className={cn(response ? 'block text-body-sm' : 'hidden')}>{accountData?.userInfoChangeSuccessMesssage}</div>
      {error && <div className='mt-4 block text-brand-warning lg:mt-0'>{error}</div>}
      <div className='col-span-2 mt-1 flex justify-end'>
        <Button
          size='md'
          disabled={
            (inputFields.firstName === customer?.firstName || inputFields.firstName === response?.firstName) &&
            (inputFields?.lastName === customer?.lastName || inputFields.lastName === response?.lastName)
          }
          type='submit'
        >
          {loading && <LoaderCircle className='size-4 animate-spin' />}
          {saveInfo}
        </Button>
      </div>
    </form>
  )
}

export default UserInfoForm
