import { FunctionComponent } from 'react'

import { CookieBanner } from 'data-access/sanity/fragments/globals/cookieBanner.fragment'
import { Button } from '@ui/components/ui/button'
import { cn } from '@ui/lib/utils'

import { useCookieConsentContext } from '../../hooks/context/useCookieConsentContext'
import RichText from '../shared/rich-text/rich-text'
import MisterCookieCustomiser from './MisterCookieCustomiser'

interface Props {
  content: CookieBanner
}

const MisterCookieBanner: FunctionComponent<Props> = ({ content }) => {
  const {
    cookieBannerTitle,
    cookieBannerDescription,
    cookieBannerButtonLabelCustomise,
    cookieBannerButtonLabelAcceptAll,
    customiseCopy,
    customiserTitle,
    cookieTypes,
    closeCustomiserLabel,
  } = content || {}

  const { showCookieBanner, acceptAllCookies, startCustomise } = useCookieConsentContext()

  return (
    <>
      <div className={cn(!showCookieBanner && 'hidden')}>
        <aside className='fixed inset-x-0 bottom-0 z-cookieBanner mx-auto px-4 pb-4 lg:ml-8 lg:max-w-3xl lg:px-0 lg:pb-8' aria-hidden={!showCookieBanner}>
          <div className='space-y-4 rounded-md bg-white p-4 drop-shadow-xl lg:px-6 lg:py-5'>
            <p className='pt-1 text-body-lg-bold'>{cookieBannerTitle}</p>
            <RichText className='text-body-md' data={cookieBannerDescription} />
            <div className='grid items-center gap-4 sm:auto-cols-max sm:grid-flow-col sm:justify-end'>
              <Button onClick={startCustomise} e2eSelector='customise-cookies' variant='outline' size='sm'>
                {cookieBannerButtonLabelCustomise || 'Customise'}
              </Button>
              <Button onClick={acceptAllCookies} e2eSelector='accept-cookies' className='border-none bg-brand-orange'>
                {cookieBannerButtonLabelAcceptAll || 'Accept all cookies'}
              </Button>
            </div>
          </div>
        </aside>
      </div>
      <MisterCookieCustomiser customiseCopy={customiseCopy} customiserTitle={customiserTitle} cookieTypes={cookieTypes} closeCustomiserLabel={closeCustomiserLabel} />
    </>
  )
}

export default MisterCookieBanner
