import { useState } from 'react'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import { useCookie, useToggle } from 'react-use'

import { getAlgoliaIndexName } from 'data-access/algolia/utils'

import { ShopifyVariantInSanity } from '@/types/product'

import { useRecommendations } from 'src/contexts/Recommendations.context'
import { ProductWithEnrichedAvailability } from 'src/domain/productCard.domain'
import { useTranslations } from '../../contexts/Globals.context'
import { useTelemetry } from '../../contexts/Telemetry.context'
import { useUI } from '../../contexts/UI.context'
import { ELEVAR_USER_ID_COOKIE } from '../../middleware'
import { algoliaInsightsClient } from '../../utils/algolia.util'
import { ecommerceInfoAddToCart } from '../../utils/elevar.util'
import { get } from '../../utils/localStorage.util'
import { ELEVAR_DATA_LAYER_EVENTS, trackEvent } from '../../utils/telemetry.util'
import useAuthenticatedUserToken from '../customer/useAuthenticatedUserToken'
import useCart from './useCart'

interface ProductFormProps {
  product: ProductWithEnrichedAvailability
  onAddToCartComplete?: () => void
}

const useProductForm = ({ product, onAddToCartComplete }: ProductFormProps) => {
  const { locale } = useRouter()
  const translate = useTranslations()
  const { addItems, currencyCode } = useCart()
  const { openCart, displayShopTheLook } = useUI()
  const { userProperties } = useTelemetry()
  const [customUserId] = useCookie(ELEVAR_USER_ID_COOKIE)
  const authenticatedUserToken = useAuthenticatedUserToken()
  const [algoliaLastIndexName] = useCookie('algoliaLastIndexName')
  const [algoliaLastQueryId] = useCookie('algoliaLastQueryId')
  const indexName = getAlgoliaIndexName(String(locale))
  const recommendations = useRecommendations()

  const [selectedVariant, setSelectedVariant] = useState<ShopifyVariantInSanity | null>(product.variants.length === 1 && product.variants[0] ? product.variants[0] : null)
  const [loadingAddToCart, setLoadingAddToCart] = useToggle(false)
  const [addToCartError, setAddToCartError] = useState<boolean | string>(false)

  const handleAddToCart = async (sideEffect: 'drawer' | 'toast' = 'drawer') => {
    setAddToCartError(false)
    setLoadingAddToCart(true)
    const productHandle = `product-${product.slug.current}-${selectedVariant?.sku}`

    if (!selectedVariant) {
      setLoadingAddToCart(false)
      return setAddToCartError(translate('noVariantError', 'Please select a size'))
    }

    const { error } = await addItems({
      lines: [{ quantity: 1, merchandiseId: selectedVariant.shopifyVariantId }],
      trackingData: {
        queryID: algoliaLastQueryId || undefined,
        handle: productHandle,
      },
    })

    if (error) {
      setLoadingAddToCart(false)
      return setAddToCartError(true)
    }

    // Artificial delay so that the cart panel doesn't open in
    // a loading state, but the new product is already there
    // todo: implement eager loading for cart
    setTimeout(
      () =>
        sideEffect === 'drawer'
          ? openCart()
          : toast(translate('addToCartToastMessage', `${product.productTitle?.split('*')[1]} added to cart`)?.replace('{productName}', product.productTitle?.split('*')[1])),
      500,
    )

    trackEvent(ELEVAR_DATA_LAYER_EVENTS.DL_ADD_TO_CART, {
      user_properties: userProperties,
      ecommerce: ecommerceInfoAddToCart({
        slug: product.slug.current,
        product,
        productMedia: product.images,
        locale,
        variant: selectedVariant,
        list: get('elevar-list') ?? '',
        context: recommendations?.type || (displayShopTheLook.open ? 'shop_the_look' : 'product_form'),
        currencyCode,
      }),
    })

    // Algolia event to complete event tracking for Algolia user journey from PCP to PDP to ATC.
    // Note: the quick-ATC on the PCP has out-of-the-box Algolia event tracking using sendEvent(),
    // here we use a custom instantiated client to 'manually' push events to Algolia.
    // @see https://www.algolia.com/doc/guides/sending-events/instantsearch/send-ecommerce-events/?client=React+InstantSearch#on-pages-without-instantsearch-widgets
    const base = {
      index: algoliaLastIndexName || indexName,
      eventName: algoliaLastQueryId ? 'PDP Add to Cart' : 'PDP Add to Cart Without Query',
      currency: currencyCode,
      objectIDs: [productHandle],
      objectData: [
        {
          quantity: 1,
          price: selectedVariant?.price || 0,
          ...(algoliaLastQueryId && { queryID: algoliaLastQueryId }),
        },
      ],
      ...(customUserId && { userToken: customUserId }),
      ...(authenticatedUserToken && { authenticatedUserToken }),
    }

    if (algoliaLastQueryId) {
      algoliaInsightsClient('addedToCartObjectIDsAfterSearch', { ...base, queryID: algoliaLastQueryId })
    } else {
      algoliaInsightsClient('addedToCartObjectIDs', base)
    }

    if (onAddToCartComplete) {
      onAddToCartComplete()
    }

    setLoadingAddToCart(false)
  }

  return {
    error: addToCartError ? (typeof addToCartError === 'string' ? addToCartError : translate('serverError', 'Something went wrong, please try again later.')) : undefined,
    loadingAddToCart,
    handleAddToCart,
    selectedVariant,
    setSelectedVariant,
  }
}

export default useProductForm
